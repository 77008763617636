import { useEffect, useState , useRef } from "react";
import SizesList from "../../ProductSizes/SizesList";
import Colors from "../ColorsList/Colors";
import ErrorValidation from "./ErrorValidation";

export default function ColorItemCreate({
  item,
  index,
  attribute,
  setAttribute,
  errorValidation,
  colors,
  sizes,
}) {
  const [showColors, setShowColors] = useState(0);
  const [showSizes, setShowSizes] = useState(0);
  const [price, setPrice] = useState(0);
  const [number, setNumber] = useState(null);
  const [selectColor, setSelectColor] = useState(item.color);
  const [selectSize, setSelectSize] = useState(item.size);

  function handleDelete(id) {
    let colors_filtered = attribute.filter((item) => item.id !== id);
    setAttribute(colors_filtered);
  }

  function handleShowColors() {
    if (showColors == 0) {
      setShowColors(1);
    } else {
      setShowColors(0);
    }
  }
  function handleShowSizes() {
    if (showSizes == 0) {
      setShowSizes(1);
    } else {
      setShowSizes(0);
    }
  }

  useEffect(() => {
    var atrribute_updated = attribute;
    atrribute_updated.forEach((element) => {
      if (element.id == item.id) {
        if (selectColor) {
          element.color_id = selectColor.id;
        }else{
          element.color_id = null;
        }
        if(selectSize){
          element.size_id = selectSize.id
        }else{
          element.size_id = null;
        }
      }
    });
    setAttribute(atrribute_updated);
  }, [selectColor , selectSize]);

  function changeProductColor(e, type, setState) {
    var value = null;
    if (e > 0) {
      value = parseInt(e);
    } else if (type == "price_increase") {
      value = 0;
    }
    setState(value);
    const attribute_updated = attribute;
    attribute_updated.forEach((element) => {
      if (element.id == item.id) {
        element[type] = value;
      }
    });
    setAttribute(attribute_updated);
  }

  return (
    <>
      <div className="item">
        <button className="delete" onClick={() => handleDelete(item.id)}>
          &#10006;
        </button>

        <div className="product_colors">
          {colors && (
            <div>
              {showColors == 1 && (
                <Colors
                  colors={colors}
                  handleShowColors={handleShowColors}
                  selectColor={selectColor}
                  setSelectColor={setSelectColor}
                />
              )}
              {showSizes == 1 && (
                <SizesList
                  sizes={sizes}
                  handleShowSizes={handleShowSizes}
                  selectSize={selectSize}
                  setSelectSize={setSelectSize}
                />
              )}

              <div className="show_colors">
                <button onClick={() => handleShowColors()}>انتخاب رنگ</button>
                <button onClick={() => handleShowSizes()}>انتخاب سایز</button>
              </div>
            </div>
          )}
        </div>

        <div className="attribute">
          {selectColor && (
            <div className="color_product_parent">
              <div
                className="color_product"
                style={{ background: selectColor.color }}
              ></div>
            </div>
          )}

          {selectSize && (
            <div className="size">
              <span>سایز : </span>
              <span className="amount">{selectSize.size}</span>
            </div>
          )}
        </div>

        <div className="name">{item.name}</div>
        <div className="number">
          <input
            type="tel"
            placeholder="تعداد"
            defaultValue={
              number && number > 0
                ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : null
            }
            onKeyUp={(e) =>
              changeProductColor(e.target.value, "number", setNumber)
            }
          />
        </div>
        <div className="price">
          <input
            type="tel"
            placeholder="قیمت افزایشی"
            defaultValue={
              price && price > 0
                ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : null
            }
            onKeyUp={(e) =>
              changeProductColor(e.target.value, "price_increase", setPrice)
            }
          />
        </div>
      </div>
      <ErrorValidation index={index} errorValidation={errorValidation} />
    </>
  );
}
