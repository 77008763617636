
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import SizeItem from "./SizeItem";

export default function Sizes({ sizes  ,types , setTypes}) {
  return (
    <div className="parent_size">
      {sizes &&
        sizes.map((item, index) => (
          <SizeItem
            key={item.id}
            item={item}
            types={types}
            setTypes={setTypes}
          />
        ))}

      {sizes && sizes.length == 0 && (
        <div className="empty_sizes">
          <div className="inner">
            <p className="empty">متأسفانه سایزی پیدا نشد !</p>
          </div>
        </div>
      )}
    </div>
  );
}
