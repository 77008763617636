import ReactDOM from "react-dom";
import { result } from "lodash";
import React, { useRef, useState, useEffect, useCallback , useContext } from "react";
import {BrowserRouter,Routes,Route,Link,useNavigate,Navigate,} from "react-router-dom";
import {useInfiniteQuery , QueryClient , QueryClientProvider , useQuery,} from "react-query";
import { get, post } from "../../../http/http";
import ProductDiscountItem from "./ProductDiscountItem";
import SpinnerLoading from "../../../Spinner/SpinnerLoading";
import ScrollManager from '../../../ScrollManager/ScrollManager'
import ErrorLoadData from "../../../Error/ErrorLoadData";
import Header from "../../../Header/Header";
import { Context } from '../../../Context/Context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

export default function ProductDiscountList() {

  const {successAlert , setSuccessAlert} = useContext(Context);

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollManager scrollKey="ProductDiscountList" />
      <div className="list_parent">
      <Header title={"لیست تخفیف محصولات"}/>
      <div className="list">
      <Records/>
      </div>
      </div>
    </QueryClientProvider>
  );
}

///////////////

const Records = ({}) => {
  const lastId = useRef(0);
  function handleRefresh(){
    queryClient.resetQueries("product_discount_list_repoData")
  }
   const request = { last_id: lastId.current };
  const {
    isLoading,
    fetchNextPage, 
    hasNextPage, 
    isFetching,
    isFetchingNextPage, 
    data,
    status,
    error,
    isError,
  } = useInfiniteQuery(
    ["product_discount_list_repoData"],
    ({ pageParam = 1 , signal}) =>
      post(`/api/admin/product-discount/index?page=${pageParam}`, request ,{signal:signal}),
    {

      getNextPageParam: (lastPage, allPages) => {
         if (lastPage && lastPage.length > 0) {
           lastId.current = lastPage[lastPage.length - 1].id;
         }
        return lastPage.length ? allPages.length + 1 : undefined;
      },
      
    }

  );

  const intObserver = useRef();
  const lastPostRef = useCallback(
    (post) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          console.log("We are near the last post!");
          fetchNextPage();
        }
      });

      if (post) intObserver.current.observe(post);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const content = data?.pages.map((pg) => {
    return pg.map((item, index) => {
      if (pg.length === index + 1) {
        return (
          <ProductDiscountItem
            ref={lastPostRef}
            key={item.id}
            index={index}
            item={item}
            queryClient={queryClient}
          />
        );
      }
      return (
        <ProductDiscountItem
          key={item.id}
          index={index}
          item={item}
          queryClient={queryClient}
        />
      );
    });
    
  });

  return (
    <div>

      {content}
      
      {isLoading &&
       <SpinnerLoading />
       }
      
      {isFetchingNextPage && (data.pages[data.pages.length-1]).length >= 100 &&
       <SpinnerLoading />
      }

      {isFetching && !isFetchingNextPage &&
       <SpinnerLoading/>
      } 
      
      {isLoading == false && data && Object.keys(data.pages[0]).length < 1 &&
      <div className='empty_container'>
      <div className='inner'>
      <p className='empty'>متأسفانه موردی  پیدا نشد !</p>
      </div>
      </div>  
      }
      
      {isError &&
      <ErrorLoadData func={handleRefresh}/> 
      }

    </div>
  );
};

export function useQueryClientDiscountProductsList(){
  return[queryClient]
}
