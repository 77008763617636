import { post } from "../../http/http"
import { useState, useEffect } from "react";
import Header from "../../Header/Header";
import TypeItem from "./TypeItem";
import Create from "./Create";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import ErrorLoadData from "../../Error/ErrorLoadData";
import ErrorMessgae from "../../Error/ErrorMessage";

export default function SizeTypes(){

    const [types , setTypes]= useState()
    const [showEdit , setShowEdit]= useState(0)
    const [showCreate, setShowCreate] = useState(0)
    const [retry , setRetry] = useState(0)
    const [load , setLoad] = useState(0)
    const [errorMessage , setErrorMessage] = useState(0)
    const [errorLoadData , setErrorLoadData] = useState(0)

    useEffect(()=>{
        index();
    } ,[retry])

    function index(){
        if(load == 0){
            setLoad(1)
        post("api/admin/size/")
        .then(response=>{
            setTypes(response)
            setLoad(0)
            setErrorLoadData(0)
        }).catch(error=>{
            setLoad(0)
            setErrorLoadData(1)
        })
    }
    }
    function handleRetry(){
        setRetry(retry+1);
    }
    function handleShowCreate(){
        if(showCreate==0){
            setShowCreate(1)
        }else{
            setShowCreate(0)
        }
    }
    return (
      <div className="list_parent">
        <div className="list">
          <div className="list_size_types">
            <Header title={"لیست انواع سایز"} />
            <button
              className="create_size_types"
              onClick={() => handleShowCreate()}
            >
              ثبت نوع جدیدی از سایز
            </button>
            {showCreate == 1 && (
              <Create
                types={types}
                setTypes={setTypes}
                setShowCreate={setShowCreate}
              />
            )}
            {types &&
              types.length > 0 &&
              types.map((item, index) => (
                <TypeItem
                  key={item.id}
                  item={item}
                  types={types}
                  setTypes={setTypes}
                  setErrorMessage={setErrorMessage}
                />
              ))}
          </div>
          {load == 1 && <SpinnerLoading />}
          {errorLoadData == 1 && <ErrorLoadData func={handleRetry} />}
          {errorMessage == 1 && <ErrorMessgae setError={setErrorMessage} />}
        </div>
      </div>
    );
}