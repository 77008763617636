import { clear } from "@testing-library/user-event/dist/clear";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";

export default function ErrorMessgae({setError}) {

   useEffect(() => {
     let timer1 = setTimeout(() => {
       setError(0);
     }, 8000);
   return () => {
       clearTimeout(timer1);
    }
   } ,[])

  return (
    <div className="parent_error_message">
      <div className="error_message">
      <span>خطا در برقراری ارتباط</span>
      </div>
    </div>
  );
}