import React, { useState, useEffect, useRef, useContext } from "react";
import Values from "./Values";
export default function Property({ properties, propertyProduct, item_property , totalChecked , setTotalChecked}) {
  const [showValues, setShowValues] = useState(0);
  const [values , setValues] = useState([])
  
  function handleShowValues() {
    if (showValues == 0) {
      setShowValues(1);
    } else {
      setShowValues(0);
    }
  }

  useEffect(()=>{
    handleValues()
  },[])

  function handleValues(){
    if (properties) {
      properties.map((el) => {
        if (el.property_id == item_property.id) {
          setValues((values) => [...values, el]);
        }
      });
    }
  }

  return (
    <div className="item_property_parent">
      <div>
        <div className="property_name">{item_property.name}</div>
        <button className="values_btn_show" onClick={() => handleShowValues()}>
          موارد ویژگی
        </button>
      </div>
      <div className={showValues == 0 ? "values" : "values visisble"}>
        <Values
          key={item_property.id}
          values={values}
          item_property={item_property}
          totalChecked={totalChecked}
          setTotalChecked={setTotalChecked}
          propertyProduct={propertyProduct}
        />
      </div>

    </div>
  );
}
