import React, { useState , useEffect , useRef , useContext } from "react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import ReactDOM from "react-dom";
import { get, post } from "../../../http/http";
import SpinnerLoading from "../../../Spinner/SpinnerLoading";
import {useNavigate , useParams} from "react-router-dom";
import ScrollManager from '../../../ScrollManager/ScrollManager'
import Input from "../../../Inputs/Input";
import Header from "../../../Header/Header";
import { Context } from '../../../Context/Context';
import ErrorMessgae from "../../../Error/ErrorMessage";
import ErrorLoadData from "../../../Error/ErrorLoadData";
import useProductDiscount from "./useProductDiscount";
import DatePickerComponent from "../../../Inputs/DatePickerComponent";
import ProductItem from "../../../Products/index/ProductItem";

export default function ProductDiscount() {

  const navigate = useNavigate()
  const [queryClient] = useProductDiscount()
  return (

  <div className="form_page">
    <ScrollManager scrollKey="product_discount" />
    <div className="form_inner">
    <Header title={"تخفیف محصول"}/>
    <QueryClientProvider client={queryClient}>
    <ReactQuery/>
    </QueryClientProvider>
   </div>
   </div>

   );
   }

function ReactQuery(){
  
  const percentInput   = useRef()
  const maximumInput   = useRef()
  const statusInput    = useRef()
  const params = useParams()

  const {successAlert , setSuccessAlert} = useContext(Context)
  var request = {"product_id" : params.id}
  const { isLoading, error ,isError, data , isFetching } = useQuery('product_discount_repodata'+params.id, () =>
   post('/api/admin/product-discount/show' ,request )
  )
  const [queryClient , load , setLoad , productName ,setProductName ,productPrice , setProductPrice, errorValidation , setErrorValidation ,errorConnection , setErrorConnection ,errorConnectionLoadData , setErrorConnectionLoadData ,status , setStatus ,handleCreateDiscount , handleStatus , handleRefresh , startDate, setStartDate , finishDate, setFinishDate] = useProductDiscount(data,percentInput , maximumInput , statusInput);


  return (
    <div>
      {data && data.discount && data.product && (
        <div>
          <div className="form">
            <div className="product_discount_parent">
              <span className="description_page">
                در این بخش شما می توانید برای محصول خود به صورت مستقل تخفیف
                ایجاد نمایید. با فعال کردن این بخش، تخفیف عمومی در نظر
                گرفته نمی شود.
              </span>
              <label>
                {" "}
                محصول : {productName ? productName : data.product.name}
              </label>
            </div>
            <div className="product_discount_parent">

            </div>
            <div className="input_parent">
              <div className="checkbox_parent">
                <div>
                  <label htmlFor="status">
                    {" "}
                    تخفیف برای این محصول اعمال شود
                  </label>
                </div>
                <div>
                  <div>
                    <input
                      className="checkbox_input"
                      defaultValue={
                        data.discount.status
                          ? data.discount.status == 0
                            ? 0
                            : 1
                          : 0
                      }
                      id="status"
                      type="checkbox"
                      ref={statusInput}
                      onChange={() => handleStatus()}
                    />
                    <span
                      className={
                        data.discount.status == 1
                          ? "checked"
                          : "checked_disabled"
                      }
                      onClick={() => handleStatus()}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                data.discount.status == 1
                  ? "parent_inputs_delivery_active"
                  : "parent_inputs_delivery_inactive"
              }
            >
              <Input
                id={"error_validation"}
                type={"tel"}
                disable={data.discount.status == 0 ? "false" : "true"}
                value={
                  Object.keys(data.discount).length > 0
                    ? data.discount.percent_discount
                    : null
                }
                label={"درصد تخفیف (درصد)"}
                name={"percent_discount"}
                placeholder={"مثال : 20"}
                ref={percentInput}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.percent_discount
                    ? errorValidation.percent_discount
                    : ""
                }
              />
              <Input
                id={"error_validation"}
                type={"tel"}
                disable={data.discount.status == 0 ? "false" : "true"}
                value={
                  Object.keys(data.discount).length > 0
                    ? data.discount.maximum_discount
                    : null
                }
                label={"حداکثر تخفیف (تومان)"}
                name={"price"}
                placeholder={"مثال : 100,000"}
                ref={maximumInput}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.maximum_discount
                    ? errorValidation.maximum_discount
                    : ""
                }
              />

              <div className="input_parent">
                <label>انتخاب تاریخ و زمان شروع تخفیف</label>
                <DatePickerComponent
                  data={data}
                  date={data.discount.start_discount}
                  datePicker={startDate}
                  setDatePicker={setStartDate}
                />
              </div>

              <div className="input_parent">
                <label>انتخاب تاریخ و زمان پایان تخفیف</label>
                <DatePickerComponent
                  data={data}
                  date={data.discount.finish_discount}
                  datePicker={finishDate}
                  setDatePicker={setFinishDate}
                />
                {errorValidation &&
                  errorValidation["datapicker-error"] &&
                  errorValidation["datapicker-error"].map((item, index) => (
                    <div id="error_validation" className="error" key={index}>
                      <span>{item}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="submit_parent">
              <button
                className={
                  data.discount.status == 1
                    ? "submit_form"
                    : "submit_form_inactive"
                }
                disabled={data.discount.status == 0 ? "disabled" : null}
                onClick={() => handleCreateDiscount()}
              >
                ثبت تغییرات
              </button>
            </div>
          </div>
        </div>
      )}
      {load == 1 && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {isLoading && <SpinnerLoading />}
      {isFetching && <SpinnerLoading />}
      {isError && <ErrorLoadData func={handleRefresh} />}
    </div>
  );
}

