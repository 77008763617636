import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import {
  useInfiniteQuery,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "react-query";
import ScrollManager from "../ScrollManager/ScrollManager";
import ErrorLoadData from "../Error/ErrorLoadData";
import Header from "../Header/Header";
import { Context } from "../Context/Context";
import ErrorMessgae from "../Error/ErrorMessage";
import { useQueryClientCategory } from "./CategoryIndex";
import FileUpload from "../FileUpload/FileUpload";

export default function CategoryCreate() {
  const [load, setLoad] = useState(0);
  const [retry, setRetry] = useState(0);
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [errorConnectionLoadData, setErrorConnectionLoadData] = useState(0);
  const [allCategory, setAllCategory] = useState();
  const [ImageNameResponse , setImageNameResponse] =useState()
  const { successAlert, setSuccessAlert } = useContext(Context);
  const name = useRef();
  const categoryId = useRef();

  const [queryClient] = useQueryClientCategory();

  useEffect(() => {
    setLoad(1);
    post("/api/admin/category/all-category")
      .then((response) => {
        if (response[0] == "success") {
          setLoad(0);
          setAllCategory(response.categories);
          setErrorConnectionLoadData(0);
          setErrorConnection(0);
        }
      })
      .catch((error) => {
        setLoad(0);
        setErrorConnectionLoadData(1);
        setErrorConnection(0);
      });

    return () => {
      //cleanup
    };
  }, [retry]);

  function handleLoadData() {
    setRetry(retry + 1);
  }

  function addToDataCachedOptimized(response) {
    const oldData = queryClient.getQueryData();
    if (oldData) {
      ///add to list
      oldData.pages[0] = [response.new_category, ...oldData.pages[0]];
      queryClient.setQueryData(oldData);
      ///update cache list category childs item
      var parent_category_id = response.new_category.category_id;
      console.log(oldData)
      if (parent_category_id > 0) {
         oldData.pages.forEach(page => {
          page.forEach((record)=>{
            if(record.id == parent_category_id){
            record.child = [...record.child , response.new_category];
            }
          })
        });
      }
    }
  }

  function handleCreateCategory() {
    if (load == 0) {
      setLoad(1);
      var name_category = name.current.value;
      var category_id = categoryId.current.value;
      var request = { name: name_category, category_id: category_id , image: ImageNameResponse};
      post("/api/admin/category/create", request)
        .then((response) => {
          if (response[0] == "success") {
            name.current.value = "";
            setAllCategory(response.categories);
            setSuccessAlert(1);
            setLoad(0);
            setErrorConnection(0);
            setErrorConnectionLoadData(0);
            setErrorValidation();
            addToDataCachedOptimized(response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            setErrorValidation(error.response.data.errors);
            setErrorConnection(0);
          } else {
            setErrorConnection(1);
          }
          setLoad(0);
          setSuccessAlert(0);
          setErrorConnectionLoadData(0);
        });
    }
  }

  return (
    <div className="form_page">
      <ScrollManager scrollKey="categoryCreate" />
      <div className="form_inner">
        <Header title={"ثبت دسته بندی"} />

        <p>در این صفحه شما می توانید دسته بندی مورد نظر خود را وارد نمایید.</p>

        <div className="form">
          
          <FileUpload
            id={"error_validation"}
            url={"/api/admin/category/upload"}
            keyRequest={"image"}
            keyResponse={"image"}
            ImageNameResponse={ImageNameResponse}
            setImageNameResponse={setImageNameResponse}
            placeholder={"انتخاب تصویر دسته بندی"}
            setErrorValidation={setErrorValidation}
            errorValidation={errorValidation}
            error={
              errorValidation && errorValidation.image
                ? errorValidation.image
                : null
            }
          />

          <div className="input_parent">
            <label htmlFor="name">نام دسته بندی</label>
            <input
              id="name"
              className="name"
              name="name"
              type="text"
              placeholder="نام دسته بندی"
              ref={name}
            />

            {errorValidation && errorValidation.name && (
              <div className="error" id="error_validation">
                <span>{errorValidation.name}</span>
              </div>
            )}
          </div>

          <div className="input_parent">
            <label htmlFor="category_id">انتخاب دسته بندی والد</label>
            <select
              ref={categoryId}
              className="select_box"
              name="category_id"
              id="category_id"
            >
              <option value={0}>دسته بندی اصلی</option>
              {allCategory &&
                allCategory.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>

            {errorValidation && errorValidation.category_id && (
              <div className="error" id="error_validation">
                <span>{errorValidation.category_id}</span>
              </div>
            )}
          </div>

          <div className="submit_parent">
            <button
              className="submit_form"
              onClick={() => handleCreateCategory()}
            >
              ثبت دسته بندی
            </button>
          </div>
        </div>
      </div>

      {load == 1 && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {errorConnectionLoadData == 1 && <ErrorLoadData func={handleLoadData} />}
    </div>
  );
}
