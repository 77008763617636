import { useEffect, useState, useContext } from "react";
export default function ColorItem({
  item,
  selectColor,
  setSelectColor,
}) {
  const [exist, setExsist] = useState(false);
  useEffect(() => {
    if(selectColor){
      if (item.id == selectColor.id) {
        setExsist(true);
      } else {
        setExsist(false);
      }
    }
  }, [selectColor]);

  function addToProductColors(item) {
    setSelectColor(item)
    setExsist(true);
  }
  function removeOfProductColors(){
    setSelectColor()
    setExsist(false);
  }

  return (
    <>
      <div className="item">
        <div className="color" style={{ background: item.color }}></div>
        <div className="name">{item.name}</div>
        <div className="add_parent">
          {exist == true ? (
            <button
              className="add added"
              onClick={() => removeOfProductColors(item)}
            >
              حذف
            </button>
          ) : (
            <button className="add" onClick={() => addToProductColors(item)}>
              افزودن
            </button>
          )}
        </div>
      </div>
    </>
  );
}
