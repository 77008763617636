import { Context } from "../Context/Context"
import React, { useState, useContext , useRef , useEffect } from "react";
import FileUpload from "../FileUpload/FileUpload";
import Header from "../Header/Header";
import { post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import Input from "../Inputs/Input";
import UrlBase from "../http/UrlHttp";

export default function Profile() {
  const { admin , setAdmin } = useContext(Context);
  const [ImageNameResponse , setImageNameResponse] = useState()
  const [errorValidation , setErrorValidation]= useState()
  const[load , setLoad]= useState(0)

  const name = useRef()

  useEffect(()=>{
     if (admin && admin.image) {
       setImageNameResponse(admin.image);
     }
  },[admin])

  function handleEdit(){
  if(load == 0){
    setLoad(1)
    var request = {name:name.current.value , image : ImageNameResponse}
    post("/api/admin/admin-info/edit" , request)
    .then(response=>{
      setLoad(0)
      setErrorValidation()
      setAdmin(response.user)
    }).catch(error=>{
      setLoad(0)
      if(error.response.status == 422){
        setErrorValidation(error.response.data.errors);
      }
    })
  }
  }
  return (
    <>
      <div className="form_page">
        <Header title={"مشخصات ادمین"} />
        <div className="profile">
          <div className="content">
            {admin && (
              <div className="info">
                <div className="name_mobile_parent">
                  <div className="name_parent">
                    <span className="title">نام کاربری: </span>
                    <span className="name">{admin.name}</span>
                  </div>
                  <div className="mobile_parent">
                    <span className="title">شماره همراه: </span>
                    <span className="mobile">{admin.mobile}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="file_upload">
              <FileUpload
                id={"error_validation"}
                url={"/api/admin/admin-info/upload-image"}
                keyRequest={"image"}
                keyResponse={"image"}
                updateFile={admin && admin.image && admin.image}
                ImageNameResponse={ImageNameResponse}
                setImageNameResponse={setImageNameResponse}
                placeholder={"تغییر تصویر پروفایل"}
                setErrorValidation={setErrorValidation}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.image
                    ? errorValidation.image
                    : null
                }
              />
            </div>
            <div className="name_input">
              <Input
                id={"error_validation"}
                value={admin && admin.name && admin.name}
                type={"text"}
                label={"نام کاربری"}
                name={"name"}
                placeholder={"نام کاربری"}
                ref={name}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.name
                    ? errorValidation.name
                    : ""
                }
              />
              <span className="des_input">می تواند شامل اعداد انگلیسی و اعداد و نقطه و آندرلاین باشد.</span>
            </div>
          </div>
          <div className="submit_parent">
            <button className="submit_form" onClick={() => handleEdit()}>
              ثبت تغییرات
            </button>
          </div>
        </div>
      </div>
      {load == 1 && <SpinnerLoading />}
    </>
  );
}