import React, { useState , useEffect , useRef , useContext } from "react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import {useNavigate} from "react-router-dom";
import ScrollManager from '../ScrollManager/ScrollManager'
import Input from "../Inputs/Input";
import Header from "../Header/Header";
import { Context } from '../Context/Context';
import ErrorMessgae from "../Error/ErrorMessage";
import ErrorLoadData from "../Error/ErrorLoadData";
import useDelivery from "./useDelivery";

export default function Delivery() {

  const navigate = useNavigate()
  const [queryClient] = useDelivery()
  return (

  <div className="form_page">
    <ScrollManager scrollKey="delivery_index" />
    <div className="form_inner">
    <Header title={"هزینه ارسال"}/>
    <p>در این صفحه می توانید هزینه ارسال سفارش را تعیین نمایید.</p>
    <QueryClientProvider client={queryClient}>
    <ReactQuery/>
    </QueryClientProvider>
   </div>
   </div>

   );
   }

function ReactQuery(){

  const sefareshiBase = useRef()
  const pishtazBase = useRef()
  const sefareshiWeight = useRef()
  const pishtazWeight = useRef()
  const statusInput = useRef()

  const {successAlert , setSuccessAlert} = useContext(Context)
  const [queryClient , load , setLoad ,errorValidation , setErrorValidation ,errorConnection , setErrorConnection ,errorConnectionLoadData , setErrorConnectionLoadData ,status , setStatus ,handleCreateDelivery , handleStatus , handleRefresh] = useDelivery(sefareshiBase , pishtazBase ,sefareshiWeight ,pishtazWeight ,statusInput);
  
  const { isLoading, error ,isError, data , isFetching } = useQuery('delivery_repodata', () =>
   post('/api/admin/delivery/index')
  )

  return(

  <div>
    <div className="form">

    <div className="input_parent">
      <div className="checkbox_parent">
        <div>
         <label htmlFor="status">هزینه ارسال اعمال شود</label>
        </div>
        <div>
         <input className="checkbox_input" defaultValue={data ? (data.status == 0 ? 0 : 1) : 0} id="status" type="checkbox" ref={statusInput} onChange={()=>handleStatus()}/>
         <span className={data && data.status == 1 ? "checked" : "checked_disabled"} onClick={()=>handleStatus()}></span>
        </div>
      </div>
    </div>

    <div className={data && data.status == 1 ? "parent_inputs_delivery_active" : "parent_inputs_delivery_inactive"}>
    <Input id={"error_validation"} type={"tel"} disable={data && data.status == 0 ? "false" : "true"} value={data && Object.keys(data).length > 0 ? data.sefareshiBase : null} label={"هزینه ارسال پست سفارشی (تومان)"} name={"price"} placeholder={"هزینه ارسال پست سفارشی"} ref={sefareshiBase}  errorValidation={errorValidation} error={(errorValidation && errorValidation.sefareshiBase) ? errorValidation.sefareshiBase : ""}/>
    <Input id={"error_validation"} type={"tel"} disable={data && data.status == 0 ? "false" : "true"} value={data && Object.keys(data).length > 0 ? data.pishtazBase : null} label={"افزایش قیمت پست سفارشی به ازای هر کیلو گرم (تومان)"} name={"price"} placeholder={"افزایش قیمت به ازای هر کیلو گرم"} ref={sefareshiWeight}  errorValidation={errorValidation} error={(errorValidation && errorValidation.pishtazBase) ? errorValidation.pishtazBase : ""}/>
    <div className="line_segment_delivery"></div>
    <Input id={"error_validation"} type={"tel"} disable={data && data.status == 0 ? "false" : "true"} value={data && Object.keys(data).length > 0 ? data.sefareshiWeight : null} label={"هزینه ارسال پست پیشتاز (تومان)"} name={"price"} placeholder={"هزینه ارسال پست پیشتاز"} ref={pishtazBase}  errorValidation={errorValidation} error={(errorValidation && errorValidation.sefareshiWeight) ? errorValidation.sefareshiWeight : ""}/>
    <Input id={"error_validation"} type={"tel"} disable={data && data.status == 0 ? "false" : "true"} value={data && Object.keys(data).length > 0 ? data.pishtazWeight : null} label={"افزایش قیمت پست پیشتاز به ازای هر کیلو گرم (تومان)"} name={"price"} placeholder={"افزایش قیمت به ازای هر کیلو گرم "} ref={pishtazWeight}  errorValidation={errorValidation} error={(errorValidation && errorValidation.pishtazWeight) ? errorValidation.pishtazWeight : ""}/>
    </div>
    <div className="submit_parent">
    <button className={data && data.status == 1 ? "submit_form" : "submit_form_inactive"} disabled={data && (data.status== 0 ? "disabled" : null)} onClick={()=>handleCreateDelivery()}>ثبت تغییرات</button>
    </div>
    </div>

    {load == 1 &&
    <SpinnerLoading/>
    }
    {errorConnection == 1 &&
     <ErrorMessgae setError={setErrorConnection}/>
    }
    {isLoading &&
     <SpinnerLoading />
    }
    {isFetching &&
     <SpinnerLoading/>
    } 
    {isError &&
      <ErrorLoadData func={handleRefresh}/> 
    }
  </div>
  );
}

