import ReactDOM from "react-dom";
import { result } from "lodash";
import React, { useRef, useState, useEffect, useCallback , useContext } from "react";
import {useNavigate} from "react-router-dom";
import {useInfiniteQuery,QueryClient,QueryClientProvider,useQuery} from "react-query";
import { Context } from '../Context/Context';
import { get, post } from "../http/http";
import CategoryItem from "./CategoryItem";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import ScrollManager from '../ScrollManager/ScrollManager'
import ErrorLoadData from "../Error/ErrorLoadData";
import Header from "../Header/Header";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

export default function CategoryIndex() {
  const { searchCategory, setSearchCategory } = useContext(Context);
  return (
    <QueryClientProvider client={queryClient}>
      <ScrollManager scrollKey="categoryIndex" />

      <div className="list_parent">
        <Header
          searchValue={searchCategory}
          setSearch={setSearchCategory}
          title={"لیست دسته بندی ها"}
          placeholderInputSearch={"جستجوی دسته بندی"}
          urlCreateBtn={"/category/create"}
          txtCreateBtn={"افزودن دسته بندی"}
        />
        <div className="list">
          <Records/>
        </div>
      </div>
    </QueryClientProvider>
  );
}

///////////////

const Records = () => {

  const { lastIdCategory , searchCategory} = useContext(Context);
  function handleRefresh(){
    queryClient.resetQueries("category_repoData")
  }

  const request = { name: searchCategory , last_id: lastIdCategory.current};
  const {
    isLoading,
    fetchNextPage, //function
    hasNextPage, // boolean
    isFetching,
    isFetchingNextPage, // boolean
    data,
    status,
    error,
    isError,
  } = useInfiniteQuery(
    ["category_repoData", searchCategory],
    ({ pageParam = 1 ,signal}) =>
      post(`/api/admin/category/index?page=${pageParam}`, request ,{signal:signal}),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage && lastPage.length > 0) {
          lastIdCategory.current = lastPage[lastPage.length - 1].id;
        }
        return lastPage.length ? allPages.length + 1 : undefined;
      },
    }
  );

  const intObserver = useRef();
  const lastPostRef = useCallback(
    (post) => {
      if (isFetchingNextPage) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          console.log("We are near the last post!");
          fetchNextPage();
        }
      });

      if (post) intObserver.current.observe(post);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  // if (status === 'error') return <p className='center'>Error: {error.message}</p>

  const content = data?.pages.map((pg) => {
    return pg.map((item, index) => {
      if (pg.length === index + 1) {
        return (
          <CategoryItem
            ref={lastPostRef}
            key={item.id}
            queryClient={queryClient}
            index={index}
            item={item}
            name={searchCategory}
          />
        );
      }
      return (
        <CategoryItem
          key={item.id}
          index={index}
          queryClient={queryClient}
          item={item}
          name={searchCategory}
        />
      );
    });
  });

  return (
    <div>

      {content}
      
      {isLoading &&
       <SpinnerLoading />
       }
      
      {isFetchingNextPage && (data.pages[data.pages.length-1]).length >= 20 &&
       <SpinnerLoading />
      }

      {isFetching && !isFetchingNextPage &&
       <SpinnerLoading/>
      } 
      
      {isLoading == false && data && Object.keys(data.pages[0]).length < 1 &&
      <div className='empty_container'>
      <div className='inner'>
      <p className='empty'>متأسفانه موردی  پیدا نشد !</p>
      </div>
      </div>  
      }
      
      {isError &&
        <ErrorLoadData func={handleRefresh}/> 
      }
    </div>
  );
};

export function useQueryClientCategory(){
  return [queryClient];
}