import React, { useState } from "react";
import LogOutModal from "./LogOutModal";

export default function LogOutBtn() {
  const [showModalLogOut, setShowmodalLogout] = useState(0);

  function handleShowLogOutModal(){
    if(showModalLogOut == 1){
        setShowmodalLogout(0)
    }else{
        setShowmodalLogout(1)
    }
  }
  return (
    <>
      <button
        className="btn_page logout"
        onClick={() => handleShowLogOutModal()}
      >
        <img src="/logout-menu.svg" />
        <span>خروج</span>
      </button>
      {showModalLogOut == 1 && (
        <LogOutModal handleShowLogOutModal={handleShowLogOutModal} />
      )}
    </>
  );
}
