import { useState, useEffect } from "react";
import Sizes from "../Sizes/Sizes";
import SizeCreate from "../Sizes/SizeCreate";
import Edit from "./Edit";

export default function TypeItem({ item, types, setTypes, setErrorMessage }) {
  const [showEdit, setShowEdit] = useState(0);
  const [typeSize, setTypeSize] = useState(item.size);
  const [showSizes, setShowSizes] = useState(0);
  const [showCreateSize, setShowCreateSize] = useState(0);
  const [sizes, setSizes] = useState();
  useEffect(() => {
    handleSizes();
  }, [JSON.stringify(types)]);
  function handleSizes() {
    setSizes(item.sizes);
  }
  function handleShowEdit() {
    if (showEdit == 0) {
      setShowEdit(1);
    } else {
      setShowEdit(0);
    }
  }
  function handleShowSizes() {
    if (showSizes == 0) {
      setShowSizes(1);
    } else {
      setShowSizes(0);
    }
  }
  function handleShowCreate() {
    if (showCreateSize == 0) {
      setShowCreateSize(1);
    } else {
      setShowCreateSize(0);
    }
  }
  return (
    <>
      <div className="item">
        <div className="info">
          <label className="title">نوع سایز : </label>
          <div className="size">{typeSize}</div>
        </div>
        <div className="size_btns">
          <button className="edit_btn" onClick={() => handleShowEdit()}>
            ویرایش
          </button>
          <button className="edit_btn" onClick={() => handleShowSizes()}>
            نمایش سایزها
          </button>
          <button className="edit_btn" onClick={() => handleShowCreate()}>
            ایجاد سایز جدید
          </button>
        </div>
        {item && item.sizes && showSizes == 1 && (
          <Sizes sizes={sizes} types={types} setTypes={setTypes} />
        )}
        {showCreateSize == 1 && (
          <SizeCreate
            sizeTypes={types}
            type={item}
            sizes={sizes}
            setSizes={setSizes}
            setShowCreateSize={setShowCreateSize}
          />
        )}
      </div>
      {showEdit == 1 && (
        <Edit
          item={item}
          setShowEdit={setShowEdit}
          typeSize={typeSize}
          setTypeSize={setTypeSize}
          setErrorMessage={setErrorMessage}
        />
      )}
    </>
  );
}
