import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessgae from "../Error/ErrorMessage";
import { post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import PropertyItemCreate from "./PropertyItemCreate";

export default function PropertyCreate({
  propertyId,
  setProperties,
  setShowCreate,
  typeCreate,
  propertyName,
}) {
  const [propertiesList, setPropertiesList] = useState([]);
  const [error, setError] = useState(0);
  const [load, setLoad] = useState(0);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setPropertiesList((propertiesList) => [
      ...propertiesList,
      { id: 1, name: null },
    ]);
  }, []);

  function handleChangeProperty(id, name) {
    var properties_data = propertiesList;
    properties_data.map((item) => {
      if (item.id == id) {
        item.name = name;
      }
    });
    setPropertiesList(properties_data);
  }

  const handleAddProperty = () => {
    var id = propertiesList[propertiesList.length - 1]["id"];
    setPropertiesList((propertiesList) => [
      ...propertiesList,
      { id: id + 1, name: null },
    ]);
  };

  function handleSubmit() {
    var properties_filtered = propertiesList.filter(
      (propery) => propery.name != null
    );
    if (load == 0) {
      setLoad(1);
      var request = {
        category_id: params.id,
        property_id: propertyId,
        properties: properties_filtered,
      };
      post("/api/admin/property/create", request)
        .then((response) => {
          navigate("/property/" + params.id);
          setProperties(response.properties);
          setShowCreate(0);
          setError(0);
          setLoad(0);
        })
        .catch((error) => {
          setError(1);
          setLoad(0);
        });
    }
  }

  function handleCloseModal() {
    setShowCreate(0);
  }
  return (
    <div className="create_properties_parent">
      <div className="create">
        <div className="title">
          {typeCreate == "values" ? "ایجاد مورد جدید برای : " : "ایجاد ویژگی جدید"}
        </div>
        {propertyName && (
          <div className="property_name">
            <span>{propertyName}</span>
          </div>
        )}
        {propertiesList &&
          propertiesList.map((item, index) => (
            <PropertyItemCreate
              id={item.id}
              handleChangeProperty={handleChangeProperty}
              propertiesList={propertiesList}
              setPropertiesList={setPropertiesList}
              typeCreate={typeCreate}
            />
          ))}
        <div className="add_property">
          <button onClick={() => handleAddProperty()}>
            <img src="/plus.svg" />
          </button>
        </div>
        <div className="btns">
          <button className="close" onClick={() => handleCloseModal()}>
            انصراف
          </button>
          <button className="submit" onClick={() => handleSubmit()}>
            تأیید
          </button>
        </div>
      </div>
      {load == 1 && <SpinnerLoading />}
      {error == 1 && <ErrorMessgae setError={setError} />}
    </div>
  );
}
