import { useContext, useRef, useState, useEffect } from "react";
import Header from "../Header/Header";
import { post } from "../http/http";
import Input from "../Inputs/Input";
import ScrollPositionManager from "../ScrollManager/ScrollManager";
import { Context } from "../Context/Context";
import ErrorMessgae from "../Error/ErrorMessage";
import { useQueryClientColors } from "./Colors";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import { useParams } from "react-router-dom";
import ErrorLoadData from "../Error/ErrorLoadData";
import { HexAlphaColorPicker, HexColorPicker, RgbaColorPicker } from "react-colorful";

export default function ColorEdit() {
  const [color, setColor] = useState();
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnectionLoadData, setErrorConnectionLoadData] = useState(0);
  const [errorConnection, setErrorConnection] = useState(0);
  const [loading, setLoading] = useState(0);
  const { successAlert, setSuccessAlert } = useContext(Context);
  const [queryClient] = useQueryClientColors();
  const [retry, setRetry] = useState(0);
  const [data, setData] = useState();
  const name = useRef();
  const params = useParams();

  useEffect(() => {
    edit();
  }, [retry]);

  function handleLoadData() {
    setRetry(retry + 1);
  }

  function edit() {
    setLoading(1);
    var request = { id: params.id };
    post("/api/admin/colors/edit", request)
      .then((response) => {
        setData(response);
        setColor(response.color);
        setLoading(0);
        setErrorConnectionLoadData(0);
        setErrorConnection(0);
      })
      .catch((error) => {
        setLoading(0);
        setErrorConnectionLoadData(1);
        setErrorConnection(0);
      });
  }
  function update() {
    if (loading == 0) {
      setLoading(1);
      var request = {
        id: params.id,
        name: name.current.value,
        color: color,
      };

      post("/api/admin/colors/update", request)
        .then((response) => {
          if (response == "success") {
            setSuccessAlert(1);
            setLoading(0);
            setErrorConnection(0);
            setErrorConnectionLoadData(0);
            setErrorValidation();
            updateColorOfColors();
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            setErrorValidation(error.response.data.errors);
            setErrorConnection(0);
          } else {
            setErrorConnection(1);
            setErrorValidation();
          }
          setLoading(0);
          setSuccessAlert(0);
        });
    }
  }

  function updateColorOfColors() {
    const colorsData = queryClient.getQueryData();
    if (colorsData) {
      colorsData.pages.forEach((page) => {
        page.forEach((item) => {
          if (item.id == params.id) {
            item.name = name.current.value;
            item.color = color;
          }
        });
      });
    }
  }
  return (
    <>
      {data && (
        <div className="form_page">
          <ScrollPositionManager scrollKey="color_edit" />
          <Header title={"ویرایش رنگ"} />
          <div className="input_parent">
            <label>انتخاب رنگ</label>
            <div className="color_picker">
              <HexAlphaColorPicker color={color} onChange={setColor} />
            </div>
          </div>

          <Input
            id={"error_validation"}
            type={"text"}
            value={data.name}
            label={"نام رنگ"}
            name={"name"}
            placeholder={"نام رنگ"}
            ref={name}
            errorValidation={errorValidation}
            error={
              errorValidation && errorValidation.name
                ? errorValidation.name
                : ""
            }
          />
          <div className="submit_parent">
            <button className="submit_form" onClick={() => update()}>
              ثبت تغییرات
            </button>
          </div>
          {errorConnection == 1 && (
            <ErrorMessgae setError={setErrorConnection} />
          )}
          {errorConnectionLoadData == 1 && (
            <ErrorLoadData func={handleLoadData} />
          )}
        </div>
      )}
      {loading == 1 && <SpinnerLoading />}
    </>
  );
}
