import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import SizeItem from "./SizeItem";
import SizeTypes from "./SizeTypes";
export default function SizesList({
  sizes,
  handleShowSizes,
  selectSize,
  setSelectSize,
}) {
  const navigate = useNavigate();
  const [sizesOfType , setSizeOfType] = useState([])
  const handleBackToTypes = ()=>{
    setSizeOfType([])
  }
  return (
    <>
      {sizes && (
        <div className="parent_colors">
          <div className="list">
            <div className="close">
              <button onClick={() => handleShowSizes()}>&#x2715;</button>
            </div>
            <div className="colors">
              <div
                className={
                  sizesOfType && sizesOfType.length == 0
                    ? "types_size_visible"
                    : "types_size_invisible"
                }
              >
                <SizeTypes
                  sizes={sizes}
                  selectSize={selectSize}
                  setSizeOfType={setSizeOfType}
                  sizesOfType={sizesOfType}
                />
              </div>
              {sizesOfType && sizesOfType.length > 0 && (
                <div className="back_to_types">
                  <button className="back" onClick={() => handleBackToTypes()}>
                    بازگشت
                  </button>
                </div>
              )}

              {sizesOfType &&
                sizesOfType.length > 0 &&
                sizesOfType.map((item, index) => (
                  <SizeItem
                    key={item.id}
                    item={item}
                    selectSize={selectSize}
                    setSelectSize={setSelectSize}
                  />
                ))}
              {sizes.length <= 0 && (
                <div className="alert_empty_colors">
                  <span>هیچ سایزی ثبت نشده است.</span>
                  <span>
                    برای تعیین سایز محصول ابتدا باید از طریق بخش سایزها، سایز
                    دلخواه خود را ایجاد نمایید.
                  </span>
                  <button onClick={() => navigate("/size")}>ایجاد سایز</button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
