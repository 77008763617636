import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import ErrorLoadData from "../Error/ErrorLoadData";
import Header from "../Header/Header";
import { post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import Category from "./Category";
import EmptyProperty from "./EmptyProperty";
import { Item } from "./Item";
import Property from "./Property";
import Submit from "./Submit";

export default function PropertiesProduct() {
  const [load, setLoad] = useState(0);
  const [error, setError] = useState(0);
  const [data, setData] = useState();
  const [propertyProduct , setPropertyProduct] = useState()
  const [retry , setRetry] = useState(0)
  const [totalChecked , setTotalChecked] = useState([])
  const [product , setProduct] = useState()
  const params = useParams();

  useEffect(() => {
    loadData();
  }, [retry]);

  function loadData(){
    if (load == 0) {
      setLoad(1);
      var request = { product_id: params.id };
      post("/api/admin/property-product/index", request)
        .then((response) => {
          setError(0);
          setLoad(0);
          setData(response.properties_categories);
          setPropertyProduct(response.property_product);
          setProduct(response.product)
        })
        .catch((error) => {
          setError(1);
          setLoad(0);
        });
    }
  }

  function handleRetry(){
    setRetry(retry+1)
  }
  return (
    <>
      <div className="list_parent">
        <Header title={"انتخاب ویژگی برای محصول"} />
        <div className="properties_product">
          {product && <div className="product_name">{product.name}</div>}
          <div></div>
          {data &&
            data.map((item, index) => (
              <div className="item" key={item.id}>
                <Category parents={item} />
                {item.property &&
                  item.property.map((item_property) => (
                    <div key={item_property.id}>
                      {item_property.property_id == null && (
                        <Property
                          properties={item.property}
                          item_property={item_property}
                          totalChecked={totalChecked}
                          setTotalChecked={setTotalChecked}
                          propertyProduct={propertyProduct}
                        />
                      )}
                    </div>
                  ))}
                {item.property.length == 0 && <EmptyProperty />}
                <Item
                  parents={item.parents}
                  property={item.property}
                  totalChecked={totalChecked}
                  setTotalChecked={setTotalChecked}
                  propertyProduct={propertyProduct}
                />
              </div>
            ))}
          {data && <Submit totalChecked={totalChecked} />}
          {load == 1 && <SpinnerLoading />}
          {error == 1 && <ErrorLoadData func={handleRetry} />}
        </div>
      </div>
    </>
  );
}

