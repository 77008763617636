
import React, { useState, useEffect } from "react";
import PropertyCreate from "../PropertyCreate";
import ValueItem from "./ValueItem";
export default function Values({
  item,
  properties,
  setProperties,
  handleUpdate,
  load,
  setLoad,
  setErrorMessage,
  setShowEditModal,
}) {
  const [showCreate, setShowCreate] = useState(0);

  function handleShowCreate() {
    if (showCreate == 0) {
      setShowCreate(1);
    } else {
      setShowCreate(0);
    }
  }

  return (
    <>
      <div className="values">
        <button className="add_value_btn" onClick={() => handleShowCreate()}>افزودن مورد جدید</button>
        {item &&
          item.values &&
          item.values.map((item) => (
            <ValueItem
              key={item.id}
              item={item}
              properties={properties}
              setProperties={setProperties}
              load={load}
              setLoad={setLoad}
              setErrorMessage={setErrorMessage}
            />
          ))}
        {showCreate == 1 && (
          <PropertyCreate
            propertyId={item.id}
            setProperties={setProperties}
            setShowCreate={setShowCreate}
            typeCreate={"values"}
            propertyName={item.name}
          />
        )}
      </div>
    </>
  );
}