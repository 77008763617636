import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import DeleteAlert from "../Alerts/DeleteAlert";
import UrlBase from "../http/UrlHttp";
import { get, post } from "../http/http";
import { useNavigate } from "react-router-dom";
import ErrorMessgae from "../Error/ErrorMessage";

const ColorItem = React.forwardRef(
  ({ item, page, index, queryClient, name, refresh, setRefresh }, ref) => {
    const [showDeleteModal, setShowDeleteModal] = useState(0);
    const [deleted, setDeleted] = useState(0);
    const navigate = useNavigate();
    const [error, setError] = useState(0);

    function handleDelete() {
      setShowDeleteModal(1);
    }
    const postBody = (
      <div className={item.deleted_at == null ? "item" : "item item_deleted"}>
        <div className="info_parent">
          <div className="color_parent">
            <div className="color" style={{ background: item.color}}></div>
          </div>

          <div className="info">
            <div className="name_parent">
              <label> نام رنگ : </label>
              <span className="name">{item.name}</span>
            </div>
          </div>
        </div>

        <div className="btn_parent">
          {item.deleted_at == null ? (
            <>
              <button
                className="btn_update"
                onClick={() => navigate("/colors/edit/" + item.id)}
              >
                ویرایش
              </button>
              {/* <button className="btn_delete" onClick={() => handleDelete()}>
                حذف
              </button> */}
            </>
          ) : (
            <button
              className={
                item.deleted_at == null
                  ? "btn_delete"
                  : "btn_delete undo_delete"
              }
              onClick={() => handleDelete()}
            >
              لغو حذف
            </button>
          )}
        </div>

        {showDeleteModal == 1 && (
          <DeleteAlert
            item={item}
            url={"/api/admin/colors/delete"}
            request={{ id: item.id }}
            typeDelete={"color"}
            title={item.deleted_at == null ? "حذف  رنگ" : "لغو حذف رنگ"}
            buttonTxt={item.deleted_at == null ? "حذف  رنگ" : "لغو حذف رنگ"}
            queryClient={queryClient}
            description={
              item.deleted_at == null
                ? "آیا شما از حذف این رنگ اطمینان دارید؟"
                : "آیا شما از لغو حذف این رنگ اطمینان دارید؟"
            }
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleted={deleted}
            setDeleted={setDeleted}
            refresh={refresh}
            setRefresh={setRefresh}
            setError={setError}
          />
        )}

        {error == 1 && <ErrorMessgae setError={setError} />}
      </div>
    );

    const content = ref ? (
      <article ref={ref}>{postBody}</article>
    ) : (
      <article>{postBody}</article>
    );

    return content;
  }
);

export default ColorItem;
