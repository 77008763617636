import React, { useState, useEffect } from "react";

export default function({id , handleChangeProperty , typeCreate , propertiesList , setPropertiesList}){

  function handleRemoveProperty(){
    if(propertiesList.length > 1){
    var properties_filtered = propertiesList.filter(
      (property) => property.id != id
    );
    setPropertiesList(properties_filtered)
    }
  }
    return (
      <>
        <div className="input">
          <input
            placeholder={typeCreate == "values" ? "مورد جدید " : "ویژگی جدید"}
            type="text"
            onChange={(e) => handleChangeProperty(id, e.target.value)}
          />
          {propertiesList && propertiesList.length > 1 &&
          <button className="delete" onClick={() => handleRemoveProperty()}>
            &#x2715;
          </button>
          }
        </div>
      </>
    );
}