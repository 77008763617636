
import React, { useState , useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import {Navigate, useNavigate  ,useParams} from "react-router-dom";
import ScrollManager from '../ScrollManager/ScrollManager'
import ImageGalleryUpload from "./ImageGalleryUpload";
import OldImageGallery, { useQueryClientGallery } from "./OldImageGallery";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import Header from "../Header/Header";
import { Context } from '../Context/Context';
import ErrorMessgae from "../Error/ErrorMessage";

export default function Gallery({}) {

  const [fileSelect , setFileSelect] = useState([])
  const [errorValidationUploadFile , setErrorValidationUploadFile] = useState()
  const [imagesNameUploaded , setImagesNameUploaded] = useState([])
  const [loadSubmit , setLoadSubmit] = useState(0)
  const [error , setError] = useState(0)
  const {successAlert , setSuccessAlert} = useContext(Context);

  const params = useParams()
  const navigate = useNavigate()
  const [queryClient] = useQueryClientGallery()

  useEffect(() => {
    setFileSelect([]) 
  } ,[])

  function handleSubmitGallery(){
    if(loadSubmit == 0){
    setLoadSubmit(1)
    var images = imagesNameUploaded
    var request = {"images":images , "product_id":params.id}
    post("/api/admin/gallery/create" , request)
    .then(response=>{
        setLoadSubmit(0)
        setSuccessAlert(1)
        setFileSelect([])
        queryClient.setQueryData("gallery"+params.id , response)
        navigate("/products")
     })
    .catch(error=>{
      setLoadSubmit(0)
      setError(1)
    })
  }
  }

  function handleSelectFielUpload(file){
    if(file.length >= 1){
    for(var i=0; i < file.length; i++ ){
      const index =i
      setFileSelect((fileSelect)=>[...fileSelect ,file[index]])
    }
    }
  }
  
  return (
        <div className='gallery_parent'>
          <ScrollManager scrollKey="gallery" />

          <Header title={"تصاویر محصول"}/>

           <p>در این صفحه شما می توانید تصاویر محصول خود را وارد نمایید.</p>
          <div>
            <div className="input_file_parent">
            <label htmlFor="input_file" className="btn_upload_image_gallery">افزودن تصویر	&#43;</label>
            <input multiple accept="image/jpeg,image/png,image/jpeg" id="input_file" type="file" className="input_file" onChange={(e)=>handleSelectFielUpload(e.target.files)}/>
            </div>

           {Object.keys(imagesNameUploaded).length <=0 &&
            <div className="empty_gallery_product">هیچ تصویری برای این محصول پیدا نشد.</div>
           }
          </div>

          <OldImageGallery imagesNameUploaded={imagesNameUploaded} setImagesNameUploaded={setImagesNameUploaded}/>
  
           {fileSelect &&
           fileSelect.map((item , index)=>(
           <div className='image_gallery_parent' key={index}>
            <ImageGalleryUpload file={item} errorValidationUploadFile ={errorValidationUploadFile} setErrorValidationUploadFile={setErrorValidationUploadFile} imagesNameUploaded={imagesNameUploaded} setImagesNameUploaded={setImagesNameUploaded}/>
           </div>
           ))
           }
       

           <div className="btn_submit_parent">
            <button onClick={()=>handleSubmitGallery()}>ثبت تغییرات</button>
           </div>

          {errorValidationUploadFile && errorValidationUploadFile.image &&
            <div className='error'>
            <span>{errorValidationUploadFile.image}</span>
            </div>
           }

           {loadSubmit == 1 &&
            <SpinnerLoading/>
           }
           {error == 1 &&
           <ErrorMessgae setError={setError}/>
           }
        </div>
  );
}
