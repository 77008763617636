import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../../http/http";
import UrlBase from "../../http/UrlHttp";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import { useNavigate, useParams } from "react-router-dom";
import ScrollManager from "../../ScrollManager/ScrollManager";
import ErrorLoadData from "../../Error/ErrorLoadData";
import Input from "../../Inputs/Input";
import FileUpload from "../../FileUpload/FileUpload";
import Header from "../../Header/Header";
import { Context } from "../../Context/Context";
import ErrorMessgae from "../../Error/ErrorMessage";
import { useQueryClientProduct } from "../index/Products";
import { useQueryClientDiscountProductsList } from "../../Discount/ProductDiscount/DiscountList/ProductDiscuntList";
import CkEditor from "../../Inputs/CkEditor";
import TextArea from "../../Inputs/TextArea";

export default function ProductEdit() {
  const [load, setLoad] = useState(0);
  const [retry, setRetry] = useState(0);
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [errorConnectionLoadData, setErrorConnectionLoadData] = useState(0);
  const [product, setProduct] = useState();
  const [allCategory, setAllCategory] = useState();
  const [brands, setBrands] = useState();
  const [description, setDescription] = useState();
  const [ImageNameResponse, setImageNameResponse] = useState();
  const { successAlert, setSuccessAlert } = useContext(Context);
  const name = useRef();
  const role = useRef();
  const entesharat = useRef(); 
  const categoryId = useRef();
  const brandId = useRef();
  const weight = useRef();
  const price = useRef();
  const metaDescription = useRef();

  const navigate = useNavigate();
  const params = useParams();
  const [queryClient] = useQueryClientProduct();
  const [queryClientDiscountProductsList] =
    useQueryClientDiscountProductsList();

  useEffect(() => {
    setLoad(1);
    var request = { id: params.id };
    post("/api/admin/product/edit", request)
      .then((response) => {
        setLoad(0);
        setAllCategory(response.categories);
        setBrands(response.brands)
        setProduct(response.product);
        setDescription(response.product.description);
        setImageNameResponse(response.product.image);
        setErrorConnectionLoadData(0);
        setErrorConnection(0);
      })
      .catch((error) => {
        setLoad(0);
        setErrorConnectionLoadData(1);
        setErrorConnection(0);
      });

    return () => {
      //cleanup
    };
  }, [retry]);

  function handleLoadData() {
    setRetry(retry + 1);
  }

  function updateCacheOptimize(response) {
    // update cache data react query infinite
    var previousData = queryClient.getQueryData();
    if (previousData) {
      previousData.pages.forEach((page) => {
        page.forEach((record) => {
          if (record.id == params.id) {
            var updated = response.updated;
            record = Object.assign(record, updated);
          }
        });
      });
      queryClient.setQueryData(previousData);
    }
  }

  function handleUpdateProduct() {
    if (load == 0) {
      setLoad(1);
      var _name = name.current.value;
      var _role = role.current.value;
      var _entesharat = entesharat.current.value;
      var _category_id = categoryId.current.value;
      var _brandId = brandId.current.value;
      var _weight = weight.current.value;
      var _price = price.current.value;
      var _meta_description = metaDescription.current.value;
      var _description = description;

      var request = {
        id: product.id,
        name: _name,
        role:_role,
        entesharat:_entesharat,
        image: ImageNameResponse,
        category_id: _category_id,
        brand_id: _brandId,
        weight: _weight,
        price: _price,
        description: _description,
        meta_description
        
        
        :_meta_description
      };

      post("/api/admin/product/update", request)
        .then((response) => {
          if (response[0] == "success") {
            setSuccessAlert(1);
            setLoad(0);
            setErrorConnection(0);
            setErrorConnectionLoadData(0);
            setErrorValidation();
            updateCacheOptimize(response);

            const discountProductsList =
              queryClientDiscountProductsList.getQueryData();
            if (discountProductsList) {
              discountProductsList["pages"].forEach((page) => {
                page.forEach((record) => {
                  if (record.product && record.product.id == response.updated.id) {
                    record.product.name = response.updated.name;
                    record.product.price = response.updated.price;
                    record.product.image = response.updated.image;
                  }
                });
              });
              queryClientDiscountProductsList.setQueryData(
                discountProductsList
              );
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            setErrorValidation(error.response.data.errors);
            setErrorConnection(0);
          } else {
            setErrorConnection(1);
          }
          setLoad(0);
          setSuccessAlert(0);
          setErrorConnectionLoadData(0);
        });
    }
  }

  /// scroll to first error validation
  useEffect(() => {
    if (errorValidation) {
      var error_validation_element =
        document.getElementById("error_validation");
      error_validation_element.scrollIntoView();
      window.scrollBy({ top: -180, behavior: "smooth" });
    }
  }, [errorValidation]);

  return (
    <div className="form_page">
      <ScrollManager scrollKey={"productEdit" + params.id} />
      <div className="form_inner">
        <Header title={"ویرایش محصول"} />

        {product && allCategory && Object.keys(allCategory).length > 0 && (
          <div>
            <p>در این صفحه شما می توانید محصول خود را ویرایش نمایید.</p>

            <div className="form">
              <Input
                id={"error_validation"}
                value={product.name}
                type={"text"}
                label={"نام محصول"}
                name={"name"}
                placeholder={"نام محصول"}
                ref={name}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.name
                    ? errorValidation.name
                    : ""
                }
              />
              <Input
                id={"error_validation"}
                value={product.role}
                type={"text"}
                label={"نقش نویسنده"}
                name={"role"}
                placeholder={"نقش نویسنده"}
                ref={role}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.role
                    ? errorValidation.role
                    : ""
                }
              />
              <TextArea
                id={"error_validation"}
                value={product.meta_description}
                type={"text"}
                label={"تگ meta description (حد اکثر 300 حرف)"}
                name={"meta_description"}
                placeholder={"تگ meta description"}
                ref={metaDescription}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.meta_description
                    ? errorValidation.meta_description
                    : ""
                }
              />
              <Input
                id={"error_validation"}
                value={product.entesharat}
                type={"text"}
                label={"نام انتشارات"}
                name={"entesharat"}
                placeholder={"نام انتشارات"}
                ref={entesharat}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.entesharat
                    ? errorValidation.entesharat
                    : ""
                }
              />

              <FileUpload
                id={"error_validation"}
                url={"/api/admin/product/upload-image"}
                keyRequest={"image"}
                keyResponse={"image"}
                updateFile={product.image}
                ImageNameResponse={ImageNameResponse}
                setImageNameResponse={setImageNameResponse}
                placeholder={"تغییر تصویر اصلی محصول"}
                setErrorValidation={setErrorValidation}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.image
                    ? errorValidation.image
                    : null
                }
              />

              <div className="input_parent">
                <label htmlFor="category_id">انتخاب دسته بندی محصول</label>
                <select
                  ref={categoryId}
                  className="select_box"
                  name="category_id"
                  id="category_id"
                >
                  <option value="" selected disabled>
                    انتخاب دسته بندی
                  </option>
                  {allCategory &&
                    allCategory.map((item, index) => (
                      <option
                        key={index}
                        value={item.id}
                        selected={
                          product &&
                          item.id == product.category_id &&
                          "selected"
                        }
                      >
                        {item.name}
                      </option>
                    ))}
                </select>

                {errorValidation &&
                  errorValidation.category_id &&
                  errorValidation.category_id.map((item, index) => (
                    <div className="error" id="error_validation" key={index}>
                      <span>{item}</span>
                    </div>
                  ))}
              </div>

              <div className="input_parent">
                <label htmlFor="brand_id">انتخاب برند محصول</label>
                <select
                  ref={brandId}
                  className="select_box"
                  name="brand_id"
                  id="brand_id"
                >
                  <option value="" selected disabled>
                    انتخاب برند
                  </option>
                  {brands &&
                    brands.map((item, index) => (
                      <option
                        key={index}
                        value={item.id}
                        selected={
                          product && item.id == product.brand_id && "selected"
                        }
                      >
                        {item.original_name}
                      </option>
                    ))}
                </select>
              </div>

              <Input
                id={"error_validation"}
                value={product.weight}
                type={"tel"}
                label={"وزن محصول (گرم) "}
                name={"weight"}
                placeholder={"مثال: 1000"}
                ref={weight}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.weight
                    ? errorValidation.weight
                    : ""
                }
              />

              <Input
                id={"error_validation"}
                value={product.price}
                type={"tel"}
                label={"قیمت محصول (تومان) "}
                name={"price"}
                placeholder={"مثال: 500,000"}
                ref={price}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.price
                    ? errorValidation.price
                    : ""
                }
              />

              <div className="input_parent">
                <div className="description">
                  <label>توضیحات</label>

              <div className="ckeditor">
                  <CkEditor
                    data={product.description}
                    onChange={setDescription}
                    rote_upload_image={'api/admin/product/ckeditor'}
                    />
                </div>

                  {errorValidation &&
                    errorValidation.description &&
                    errorValidation.description.map((item, index) => (
                      <div className="error" id="error_validation" key={index}>
                        <span>{item}</span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="submit_parent">
                <button
                  className="submit_form"
                  onClick={() => handleUpdateProduct()}
                >
                  ثبت تغییرات
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {allCategory && Object.keys(allCategory).length <= 0 && (
        <div className="alert_empty_category">
          <span>هیچ دسته بندی ثبت نشده است.</span>
          <span>برای اضافه نمودن محصول، حداقل به یک دسته بندی نیاز است.</span>
          <button onClick={() => navigate("/category/create")}>
            ثبت دسته بندی
          </button>
        </div>
      )}

      {load == 1 && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {errorConnectionLoadData == 1 && <ErrorLoadData func={handleLoadData} />}
    </div>
  );
}
