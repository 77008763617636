import React, { useState, useEffect } from "react";
import { post } from "../http/http";

export default function PropertyEdit({
  item,
  properties,
  setProperties,
  handleUpdate,
  load,
  setLoad,
  setErrorMessage,
  setShowEditModal,
}) {
  const [name, setName] = useState(item.name);

  function handleChangeInput(e) {
    setName(e.target.value);
  }
  function handleUpdate() {
    if (load == 0) {
      setLoad(1);
      var request = { id: item.id, name: name };
      post("/api/admin/property/update", request)
        .then((response) => {
          propertiesUpdated();
          setErrorMessage(0);
          setLoad(0);
          setShowEditModal(0);

        })
        .catch((error) => {
          setErrorMessage(1);
          setLoad(0);
        });
    }
  }

  function propertiesUpdated(){
     var properties_updated = properties;
     properties_updated.map((property) => {
       if (property.id == item.id) {
         property.name = name;
       }else if(property.values){
        property.values.map((item_value)=>{
            if(item_value.id == item.id){
              item_value.name = name
            }
        })

       }
     });
     setProperties(properties_updated);
  }

  function handleCloseEditModal(){

    setShowEditModal(0)
  }

  return (
    <div className="edit_parent">
      <div className="edit">
        <div className="title">ویرایش</div>
        <input
          defaultValue={item.name}
          onChange={(e) => handleChangeInput(e)}
        />
        <div className="edit_btns">
          <button className="close_btn" onClick={() => handleCloseEditModal()}>
            انصراف
          </button>
          <button className="update_btn" onClick={() => handleUpdate()}>
            ثبت تغییرات
          </button>
        </div>
      </div>
    </div>
  );
}
