import React, { useState, useEffect, useRef, useContext } from "react";
import { isCompositeComponentWithType } from "react-dom/test-utils";
import { QueryClient } from "react-query";
import { Context } from "../../../Context/Context";
import { get, post } from "../../../http/http";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClientDiscountProductsList } from "../DiscountList/ProductDiscuntList";
import { useQueryClientProduct } from "../../../Products/index/Products";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000,
    },
  },
});

export default function useProductDiscount(
  data,
  percentInput,
  maximumInput,
  statusInput
) {
  const [load, setLoad] = useState(0);
  const [productName, setProductName] = useState();
  const [productPrice, setProductPrice] = useState();
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [errorConnectionLoadData, setErrorConnectionLoadData] = useState(0);
  const [status, setStatus] = useState(0);
  const { successAlert, setSuccessAlert } = useContext(Context);
  const [startDate, setStartDate] = useState();
  const [finishDate, setFinishDate] = useState();
  const [startDatetRequest, setStartDateRequest] = useState();
  const [finishDateRequest, setFinishDateRequest] = useState();

  const params = useParams();
  const [queryClientDiscountProductsList] =
    useQueryClientDiscountProductsList();
  const [queryClientProduct] = useQueryClientProduct();

  useEffect(() => {
    var productDataCached = queryClientProduct.getQueryData();
    if (productDataCached) {
      productDataCached.pages.forEach((page) => {
        page.forEach((record) => {
          if (record.id == params.id) {
            setProductName(record.name);
            setProductPrice(record.price);
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    if (data && data.discount) {
      if (startDate && startDate.unix) {
        setStartDateRequest(startDate.unix);
      } else if (data.discount.start_discount == null) {
        setStartDateRequest(Math.floor(Date.now() / 1000));
      } else {
        setStartDateRequest(data.discount.start_discount);
      }
      if (finishDate && finishDate.unix) {
        setFinishDateRequest(finishDate.unix);
      } else if (data.discount.finish_discount == null) {
        setFinishDateRequest(Math.floor(Date.now() / 1000));
      } else {
        setFinishDateRequest(data.discount.finish_discount);
      }
    }
  }, [data, startDate, finishDate]);

  const handelCompareDate = () => {
    if (startDatetRequest < finishDateRequest) {
      return "true";
    } else {
      return "false";
    }
  };

  const handleCreateDiscount = () => {
    var dateCompare = handelCompareDate();
    if (dateCompare == "true") {
      if (load == 0) {
        setLoad(1);
        var percent_discount = percentInput.current.value;
        var maximum_discount = maximumInput.current.value;
        var product_id = params.id;
        var request = {
          product_id: product_id,
          maximum_discount: maximum_discount,
          percent_discount: percent_discount,
          start_discount: startDatetRequest,
          finish_discount: finishDateRequest,
        };

        post("/api/admin/product-discount/create", request)
          .then((response) => {
            setSuccessAlert(1);
            setLoad(0);
            setErrorConnection(0);
            setErrorConnectionLoadData(0);
            setErrorValidation();

            queryClient.setQueryData("product_discount_repodata" + params.id, {
              ...response,
              data: {
                ...response,
                "@status": "true",
              },
            });

            var discountProduct = queryClient.getQueryData(
              "product_discount_repodata" + params.id
            );
            
            ///change product list
            var productsCached = queryClientProduct.getQueryData();
            if (productsCached) {
              productsCached["pages"].forEach((page) => {
                page.forEach((record) => {
                  if (record.id == discountProduct.discount.product_id) {
                    var discount_updated = discountProduct.discount;
                      record.discount = discount_updated;
                  }
                });
              });
              queryClientProduct.setQueryData(productsCached);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status == 422) {
              setErrorValidation(error.response.data.errors);
              setErrorConnection(0);
            } else {
              setErrorConnection(1);
            }
            setLoad(0);
            setSuccessAlert(0);
            setErrorConnectionLoadData(0);
          });
      }
    } else {
      setErrorValidation({
        "datapicker-error": [
          "تاریخ شروع تخفیف باید از تاریخ پایان تخفیف کم تر باشد.",
        ],
      });
    }
  };

  const handleStatus = () => {
    if (load == 0) {
      setLoad(1);
      var request = { product_id: params.id };
      post("/api/admin/product-discount/status", request)
        .then((response) => {
          setLoad(0);
          setErrorConnection(0);
          queryClient.setQueryData("product_discount_repodata" + params.id, {
            ...response,
            data: {
              ...response,
              "@status": "true",
            },
          });

          ///optimize update list products after change status
          const productList = queryClientProduct.getQueryData();
          if (productList) {
            productList["pages"].forEach((page) => {
              page.forEach((record) => {
                if (record.id == response.product.id) {
                  record.discount = response.discount;
                }
              });
            });
            // queryClientProduct.setQueryData(productList);
          }

          ///
          const discountProductList =queryClientDiscountProductsList.getQueryData();
          var record_exsist = false;
          if (discountProductList && response.discount.percent_discount > 0) {
            discountProductList["pages"].forEach((page) => {
              page.forEach((record) => {
                if (record.id == response.discount.id) {
                  record_exsist = true;
                  if (response.discount.status == 0) {
                    record.deleted_at = "deleted";
                  } else {
                    record.deleted_at = null;
                  }
                }
              });
            });

            if (record_exsist == false) {
              var discount = response.discount;
              discount = { product: response.product, ...discount };
              discountProductList["pages"][0] = [
                discount,
                ...discountProductList["pages"][0],
              ];
            }
            queryClientDiscountProductsList.setQueryData(discountProductList);
          }
        })
        .catch((error) => {
          setLoad(0);
          setErrorConnection(1);
        });
    }
  };

  useEffect(() => {
    if (errorValidation) {
      var error_validation_element =
        document.getElementById("error_validation");
      error_validation_element.scrollIntoView();
      window.scrollBy({ top: -180, behavior: "smooth" });
      console.log(errorValidation);
    }
  }, [errorValidation]);

  const handleRefresh = () => {
    queryClient.resetQueries("product_discount_repodata" + params.id);
  };

  return [
    queryClient,
    load,
    setLoad,
    productName,
    setProductName,
    productPrice,
    setProductPrice,
    errorValidation,
    setErrorValidation,
    errorConnection,
    setErrorConnection,
    errorConnectionLoadData,
    setErrorConnectionLoadData,
    status,
    setStatus,
    handleCreateDiscount,
    handleStatus,
    handleRefresh,
    startDate,
    setStartDate,
    finishDate,
    setFinishDate,
  ];
}
