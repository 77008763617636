import { useContext, useRef, useState, useEffect } from "react";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import { Context } from "../../Context/Context";
import { post } from "../../http/http";
import ErrorMessgae from "../../Error/ErrorMessage";

export default function SizeEdit({ size, setSize, types, setTypes , setShowEdit}) {
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [loading, setLoading] = useState(0);
  const { setSuccessAlert } = useContext(Context);
  const [data, setData] = useState(size);
  const sizeInput = useRef();
  const type = useRef();

  function update() {
    if (loading == 0) {
      setLoading(1);
      var _id = size.id;
      var _size = sizeInput.current.value;
      var _type = type.current.value;
      var request = {
        id: _id,
        size: _size,
        type: _type,
      };
      post("/api/admin/size/update", request)
        .then((response) => {
          setLoading(0);
          setSuccessAlert(1);
          setErrorConnection(0);
          setErrorValidation();
          updateSizes(size, types, _id, _size, _type);
          setShowEdit(0)
        })
        .catch((error) => {
          setLoading(0);
          if (error.response && error.response.status != 422) {
            setErrorConnection(1);
          } else {
            setErrorValidation(error.response.data.errors);
          }
        });
    }
  }
  function updateSizes(size, types, _id, _size, _type) {
    if (size.size_id == _type) {
      setSize({ id: _id, size: _size, size_id: _type });
    } else {
      var types_update = types;
      types_update.forEach((el) => {
        if (el.id == _type) {
          el.sizes = [{ id: _id, size: _size, size_id: _type }, ...el.sizes];
        }
      });
      setSize();
      setTypes(types_update);
    }
  }
  function handleClose(){
     setShowEdit(0)
  }
  return (
    <div className="parent_modal">
      <div className="modal">
        <div className="input_parent">
          <label>نوع سایز</label>
          <select ref={type} className="select_type">
            {types &&
              types.length > 0 &&
              types.map((item, index) => (
                <option
                  value={item.id}
                  selected={data && data.size_id == item.id && "selected"}
                >
                  {item.size}
                </option>
              ))}
          </select>
          {errorValidation && errorValidation.type && (
            <div className="error">
              <span>{errorValidation.type[0]}</span>
            </div>
          )}
        </div>
        <div className="input_parent">
          <label> سایز</label>
          <input
            type="text"
            defaultValue={data && data.size}
            placeHolder="به عنوان مثال XL"
            ref={sizeInput}
          />
          {errorValidation && errorValidation.size && (
            <div className="error">
              <span>{errorValidation.size[0]}</span>
            </div>
          )}
        </div>
        <div className="submit_parent">
          <button className="submit" onClick={() => update()}>
            ویرایش سایز
          </button>
          <button className="close" onClick={() => handleClose()}>
            انصراف
          </button>
        </div>
        {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
        {loading == 1 && <SpinnerLoading />}
      </div>
    </div>
  );
}
