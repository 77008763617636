import { post } from "../../http/http";
import { useState, useEffect, useRef } from "react";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import ErrorMessgae from "../../Error/ErrorMessage";
export default function Create({
  setShowCreate,
  types,
  setTypes,
}) {
  const type = useRef();
  const [load, setLoad] = useState(0);
  const [errorMessage , setErrorMessage] = useState(0)
  const handleStore = () => {
    if (load == 0) {
      setLoad(1);
      var request = { size: type.current.value };
      post("/api/admin/size/create-type-size", request)
        .then((response) => {
          var new_type = response.new_type_size;
          new_type.sizes = [];
          var types_after_create = [new_type, ...types];
          setTypes(types_after_create);
          setShowCreate(0);
          setLoad(0);
          setErrorMessage(0)
        })
        .catch((error) => {
          setLoad(0);
          setErrorMessage(1);
        });
    }
  };
  function handleClose(){
    setShowCreate(0)
  }
  return (
    <>
      <div className="parent_modal">
        <div className="modal">
          <div className="input_parent">
            <label htmlFor="input">نوع سایز </label>
            <input
              id="input"
              type="text"
              placeholder="به عنوان مثال متری"
              ref={type}
            />
          </div>
          <button className="submit" onClick={() => handleStore()}>
            ثبت
          </button>
          <button className="close" onClick={() => handleClose()}>
            انصراف
          </button>
        </div>
      </div>
      {load == 1 && <SpinnerLoading />}
      {errorMessage == 1 && <ErrorMessgae setError={setErrorMessage} />}
    </>
  );
}
