import Input from "../../Inputs/Input";
import React, { useState } from "react";
import CreateProductColor from "../../ProductColor/Create/CreateProductColor";

export default function Number({
  showInputNumber,
  setShowInputNumber,
  number,
  errorValidation,
  colors,
  sizes,
  attribute,
  setAttribute
}) {

  function handleShowInput() {
    if (showInputNumber == 0) {
      setShowInputNumber(1);
    } else {
      setShowInputNumber(0);
    }
  }

  return (
    <div className="parent_number">
      <div className="labels_parent">
        <label onClick={() => handleShowInput()}>
          آیا محصول، رنگ بندی و یا سایز بندی دارد؟
        </label>

        <input
          className="checkbox_input"
          defaultValue={showInputNumber}
          id="status"
          type="checkbox"
          onChange={() => handleShowInput()}
        />
        <span
          className={showInputNumber == 1 ? "checked" : "checked_disabled"}
          onClick={() => handleShowInput()}
        ></span>
      </div>
      {showInputNumber == 0 ? (
        <>
          <Input
            id={"error_validation"}
            type={"tel"}
            label={"تعداد محصول"}
            name={"number"}
            placeholder={"مثال: 100"}
            ref={number}
            errorValidation={errorValidation}
            error={
              errorValidation && errorValidation.number
                ? errorValidation.number
                : ""
            }
          />
        </>
      ) : (
        colors && (
          <div className="color">
            <CreateProductColor
              colors={colors}
              sizes={sizes}
              errorValidation={errorValidation}
              attribute={attribute}
              setAttribute={setAttribute}
            />
            {errorValidation && errorValidation.colors && (
              <div className="error" id="error_validation">
                <span>فیلد رنگ خالی است</span>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}