import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Colors from "../ColorsList/Colors";
import Header from "../../Header/Header";
import ScrollManager from "../../ScrollManager/ScrollManager";
import ColorItemCreate from "./ColorItemCreate";

export default function CreateProductColor({ colors,sizes , errorValidation ,attribute , setAttribute}) {
  const params = useParams();

  function addAttribute(){
     var random_number = Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
     var attribute_updated = [...attribute , {"id":random_number,"color_id":null , "size_id":null, "number":null ,  "price_increase":0}]
     setAttribute(attribute_updated);
  }

  return (
    <>
      <div className="product_colors">
        <div className="content">
          {attribute &&
            attribute.map((item, index) => (
              <ColorItemCreate
                key={item.id}
                item={item}
                index={index}
                attribute={attribute}
                setAttribute={setAttribute}
                errorValidation={errorValidation}
                colors={colors}
                sizes={sizes}
              />
            ))}
        </div>

        <button className="add_attribute" onClick={() => addAttribute()}>
          افزودن
        </button>
      </div>
    </>
  );
}
