import React, { useState, useEffect, useRef, useContext } from "react";
import ValueItem from "./ValueItem";

export default function Values({ values, propertyProduct, item_property  , totalChecked , setTotalChecked}) {

  return (
    <div className="values_parent">
      {values &&
        values.map((item) => (
          <div key={item.id}>
            <ValueItem
              item={item}
              item_property={item_property}
              totalChecked={totalChecked}
              setTotalChecked={setTotalChecked}
              propertyProduct={propertyProduct}
            />
          </div>
        ))}

      {values.length == 0 && (
        <div className="empty_values">
          متأسفانه برای این ویژگی موردی وجود ندارد.
        </div>
      )}
    </div>
  );
}
