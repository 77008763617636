import React, { useState , useEffect , useRef , useContext } from "react";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import "react-multi-date-picker/styles/layouts/mobile.css"
import TimePicker from "react-multi-date-picker/plugins/time_picker";

export default function DatePickerComponent({data , date , typeDataPicker ,datePicker, setDatePicker}){

    useEffect(()=>{
        if(data){
        if(date == null){
          setDatePicker(new Date())
        }else{
          setDatePicker(new Date(date * 1000))
        }
        }
     },[data])

    return(
        <div>
        <DatePicker 
           className="rmdp-mobile"
           format="YYYY/MM/DD HH:mm:ss"
           value={datePicker}
           onChange={setDatePicker}
           calendar={persian}
           locale={persian_fa}
           plugins={[
              <TimePicker position="bottom" />,
          ]}
        />
        </div>
    )
}