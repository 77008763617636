import BrandItem from "./BrandItem";


export default function Brands({brands , categoryBrands , handleSelectBrand , handleShowBrands}){


    return (
      <div className="brands_list_parent">
        <div className="brands_list">
          <div className="brands_item">
            {brands.map((item, index) => (
              <BrandItem
                key={item.id}
                item={item}
                handleSelectBrand={handleSelectBrand}
                categoryBrands={categoryBrands}
              />
            ))}
          </div>
          <div className="btns">
            <button className="close" onClick={() => handleShowBrands()}>
              بستن
            </button>
          </div>
        </div>
      </div>
    );
}