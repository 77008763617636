import ReactDOM from "react-dom";
import { result } from "lodash";
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import {
  useInfiniteQuery,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "react-query";
import { get, post } from "../../http/http";
import ProductItem from "./ProductItem";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import ScrollManager from "../../ScrollManager/ScrollManager";
import ErrorLoadData from "../../Error/ErrorLoadData";
import Header from "../../Header/Header";
import GeneralDiscount, { useGeneralDiscount } from "./GeneralDiscount";
import { Context } from "../../Context/Context";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

export default function Products() {

  const [GeneralDiscountLoaded , setGeneralDiscountLoaded] =useState(0)
  const {searchProducts ,setSearchProducts} = useContext(Context)
  return (
    <>
      <GeneralDiscount setGeneralDiscountLoaded={setGeneralDiscountLoaded} />
      {GeneralDiscountLoaded == 1 && (
        <QueryClientProvider client={queryClient}>
          <div className="list_parent">
            <Header
              setSearch={setSearchProducts}
              searchValue ={searchProducts}
              title={"لیست محصولات"}
              placeholderInputSearch={"جستجوی محصول با نام محصول"}
              urlCreateBtn={"/product/create"}
              txtCreateBtn={"افزودن محصول"}
            />
            <div className="list">
              <ScrollManager scrollKey="productsIndex" />
                <Records/>
            </div>
          </div>
        </QueryClientProvider>
      )}
    </>
  );
}

///////////////

const Records = () => {
  const {lastIdProducts , searchProducts} = useContext(Context)
  function handleRefresh() {
    queryClient.resetQueries("products_repoData");
  }
  const request = { name: searchProducts, last_id: lastIdProducts.current};
  const {
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    data,
    status,
    error,
    isError,
  } = useInfiniteQuery(
    ["products_repoData", searchProducts],
    ({ pageParam = 1, signal }) =>
      post(`/api/admin/product/index?page=${pageParam}`, request, {
        signal: signal,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if(lastPage && lastPage.length > 0){
        lastIdProducts.current = lastPage[lastPage.length - 1].id
        }
        return lastPage.length ? allPages.length + 1 : undefined;
      },
    }
  );

  const intObserver = useRef();
  const lastPostRef = useCallback(
    (post) => {
      if (isFetchingNextPage) return;
      if (intObserver.current) intObserver.current.disconnect();
      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          console.log("We are near the last post!");
          fetchNextPage();
        }
      });

      if (post) intObserver.current.observe(post);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  useEffect(()=>{
    if(data){
    console.log(data)
    }
  },[data])
   
  const content = data?.pages.map((pg) => {
    return pg.map((item, index) => {
      if (pg.length === index + 1) {
        return (
          <ProductItem
            ref={lastPostRef}
            key={item.id}
            index={index}
            item={item}
            name={searchProducts}
            queryClient={queryClient}
          />
        );
      }
      return (
        <ProductItem
          key={item.id}
          index={index}
          item={item}
          name={searchProducts}
          queryClient={queryClient}
        />
      );
    });
  });

  return (
    <div>
      {content}

      {isLoading && <SpinnerLoading />}
      {isFetchingNextPage &&
        data.pages[data.pages.length - 1].length >= 20 && (
          <SpinnerLoading />
        )}

      {isFetching && !isFetchingNextPage && <SpinnerLoading />}

      {isLoading == false &&
        data &&
        Object.keys(data.pages[0]).length < 1 && (
          <div className="empty_container">
            <div className="inner">
              <p className="empty">متأسفانه موردی پیدا نشد !</p>
            </div>
          </div>
        )}
      {isError && <ErrorLoadData func={handleRefresh} />}
    </div>
  );
};

export function useQueryClientProduct() {
  return [queryClient];
}
