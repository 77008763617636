import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect, useRef, useMemo } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { get, post } from "../http/http";
import { useNavigate, useParams } from "react-router-dom";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import OldSlideItem from "./OldSlideItem";
import ErrorLoadData from "../Error/ErrorLoadData";

///////////////////

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

export default function OldSlides({ imagesNameUploaded, setImagesNameUploaded }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Records
        imagesNameUploaded={imagesNameUploaded}
        setImagesNameUploaded={setImagesNameUploaded}
      />
    </QueryClientProvider>
  );
}
function Records({ imagesNameUploaded, setImagesNameUploaded }) {
  const [run, setRun] = useState(0);
  const params = useParams();
  var request = { group: params.id };

  function handleRetry() {
    queryClient.resetQueries("slides" + id);
  }

  var id = params.id;
  const { isLoading, isFetching, isFetched, error, data, isError } = useQuery(
    ["slides" + id],
    () => post("/api/admin/slide/index", request)
  );

  useEffect(() => {
    if (run == 0 && data) {
      if (data.slides) {
        data.slides.forEach((el) => {
          setImagesNameUploaded((imagesNameUploaded) => [
            ...imagesNameUploaded,
            {id :el.id , image: el.image , url:el.url },
          ]);
        });
      }
      setRun(1);
    }
  }, [data]);

  return (
    <div className="parent_old_gallery">
      {isLoading && <SpinnerLoading />}
      {isFetching && <SpinnerLoading />}
      {!isFetching &&
        isFetched &&
        data &&
        data.slides &&
        data.slides.map((item, index) => (
          <div className="image_gallery_parent slide" key={item.image}>
            <OldSlideItem
              data={data}
              item={item}
              imagesNameUploaded={imagesNameUploaded}
              setImagesNameUploaded={setImagesNameUploaded}
            />
          </div>
        ))}

      {error && <ErrorLoadData func={handleRetry} />}
    </div>
  );
}

export function useQueryClientGallery() {
  return [queryClient];
}
