import React, { useState , useEffect , useRef , useContext } from "react";
import { isCompositeComponentWithType } from "react-dom/test-utils";
import { QueryClient } from 'react-query'
import { Context } from '../../Context/Context';
import { get, post } from "../../http/http";
import { useGeneralDiscount } from "../../Products/index/GeneralDiscount";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});
export default function useDiscount(data ,percentInput ,maximumInput , nameInput ,statusInput){

  const [load , setLoad] = useState(0)
  const [errorValidation , setErrorValidation] = useState()
  const [errorConnection , setErrorConnection] = useState(0)
  const [errorConnectionLoadData , setErrorConnectionLoadData] = useState(0)
  const [status , setStatus] = useState(0)
  const {successAlert , setSuccessAlert} = useContext(Context)
  const [startDate, setStartDate] = useState();
  const [finishDate, setFinishDate] = useState();
  const [startDatetRequest, setStartDateRequest] = useState();
  const [finishDateRequest, setFinishDateRequest] = useState();
  const [queryClientGeneralDiscount] = useGeneralDiscount();

  useEffect(()=>{
    if(data){
    if(startDate && startDate.unix){
      setStartDateRequest(startDate.unix)
    }else if(data.start_discount == null){
      setStartDateRequest(Math.floor(Date.now() / 1000))
    }else{
      setStartDateRequest(data.start_discount)
    }
    if(finishDate && finishDate.unix){
      setFinishDateRequest(finishDate.unix)
    }else if(data.finish_discount == null){
      setFinishDateRequest(Math.floor(Date.now() / 1000))
    }
    else{
      setFinishDateRequest(data.finish_discount)
    }
  }
  },[data , startDate , finishDate])

  const handelCompareDate =()=>{
    if(startDatetRequest < finishDateRequest){
        return "true"
      }
      else{
        return "false"
      }
    }


 const handleCreateDiscount=()=>{

    var dateCompare = handelCompareDate()
    if(dateCompare == "true"){
    if(load == 0){
    setLoad(1)
    var percent_discount   =  percentInput.current.value
    var maximum_discount   =  maximumInput.current.value
    var name_discount      =  nameInput.current.value

    var request = {"maximum_discount":maximum_discount , "percent_discount":percent_discount , "name_discount":name_discount , "start_discount":startDatetRequest ,"finish_discount":finishDateRequest} 

    post("/api/admin/general-discount/create" , request)
    .then(response=>{
         setSuccessAlert(1)
         setLoad(0)
         setErrorConnection(0)
         setErrorConnectionLoadData(0)
         setErrorValidation()
         queryClient.setQueryData('discount_repodata', {
          ...response,
          data: {
          ...response,
          '@status': 'true',
          },
          })
    
          const generalDiscount = queryClientGeneralDiscount.getQueryData();
          if (generalDiscount) {
            generalDiscount.general_discount = response;
          }
        
    })
    .catch(error=>{
        if(error.response && error.response.status == 422){
            setErrorValidation(error.response.data.errors)
            setErrorConnection(0)
        }
        else{
            setErrorConnection(1)
        }
        setLoad(0)
        setSuccessAlert(0)
        setErrorConnectionLoadData(0)
    })
  }
    }
    else{
       setErrorValidation({"datapicker-error":["تاریخ شروع تخفیف باید از تاریخ پایان تخفیف کم تر باشد."]})
    }
  }

  const handleStatus = ()=>{

   if(load == 0){
    setLoad(1)
    post("/api/admin/general-discount/status")
    .then(response=>{
          setLoad(0)
          setErrorConnection(0)
          queryClient.setQueryData('discount_repodata', {
          ...response,
          data: {
          ...response,
          '@status': 'true',
          },
          })

          const generalDiscount = queryClientGeneralDiscount.getQueryData();
          if (generalDiscount) {
             generalDiscount.general_discount = response
          }
    })
    .catch(error=>{
         setLoad(0)
         setErrorConnection(1)
    })
    }
  }

    useEffect(() => {
    if(errorValidation){
    var error_validation_element= document.getElementById('error_validation')
    error_validation_element.scrollIntoView()
    window.scrollBy({top:-180 , behavior: 'smooth'});
    console.log(errorValidation)
    }
   } , [errorValidation])

  const handleRefresh = ()=>{
    queryClient.resetQueries("delivery_repodata")
  }

  return(
     [queryClient,load , setLoad ,errorValidation , setErrorValidation ,errorConnection , setErrorConnection ,errorConnectionLoadData , setErrorConnectionLoadData ,status , setStatus  , handleCreateDiscount , handleStatus , handleRefresh, startDate, setStartDate ,finishDate, setFinishDate]
  );
}