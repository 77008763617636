import React, { useState , useEffect , useRef , useContext } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import UrlBase from "../http/UrlHttp";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import {useNavigate , useParams} from "react-router-dom";
import ScrollManager from '../ScrollManager/ScrollManager'
import ErrorLoadData from '../Error/ErrorLoadData'
import Input from "../Inputs/Input";
import FileUpload from "../FileUpload/FileUpload";
import Header from "../Header/Header";
import { Context } from '../Context/Context';
import ErrorMessgae from "../Error/ErrorMessage";
import {useQueryClientBrands} from "./Brands";

export default function ProductEdit() {

  const [load , setLoad] = useState(0)
  const [retry , setRetry] = useState(0)
  const [errorValidation , setErrorValidation] = useState()
  const [errorConnection , setErrorConnection] = useState(0)
  const [errorConnectionLoadData , setErrorConnectionLoadData] = useState(0)
  const [brand , setBrand] = useState()
  const [ImageNameResponse ,setImageNameResponse] = useState()
  const {successAlert , setSuccessAlert} = useContext(Context);
  const original_name = useRef()
  const persian_name = useRef()
  
  const navigate = useNavigate()
  const params = useParams()
  const [queryClient] = useQueryClientBrands()

      useEffect(() => {

        setLoad(1)
        var request = {"id":params.id}
        post("/api/admin/brand/edit" , request)
        .then(response=>{
          setLoad(0)
          setBrand(response.brand)
          setImageNameResponse(response.brand.logo)
          setErrorConnectionLoadData(0)
          setErrorConnection(0)
        })
        .catch(error=>{
          setLoad(0)
          setErrorConnectionLoadData(1)
          setErrorConnection(0)
          
        })

       return () => {

      //cleanup
     }
    
      } ,[retry])

   function handleLoadData(){
    setRetry(retry + 1)
   }

  function updateCacheOptimize(response){
    // update cache data react query infinite
     var previousData = queryClient.getQueryData()
     if(previousData){
     previousData.pages.forEach(page => {
      page.forEach(record => {
        if(record.id == params.id){
          var updated = response.updated
          record = Object.assign(record, updated);
        }
      });
     });
     queryClient.setQueryData(previousData)
    }
  }

  function handleUpdateBrand(){
    if(load == 0){
    setLoad(1)
    var _original_name = original_name.current.value
    var _persian_name = persian_name.current.value
    var request = {"id":params.id ,"original_name":_original_name , "persian_name":_persian_name ,"logo": ImageNameResponse} 
    post("/api/admin/brand/update" , request)
    .then(response=>{
        if(response[0] == "success"){
         setSuccessAlert(1)
         setLoad(0)
         setErrorConnection(0)
         setErrorConnectionLoadData(0)
         setErrorValidation()
         updateCacheOptimize(response)
        }
        
    })
    .catch(error=>{
        if(error.response && error.response.status == 422){
            setErrorValidation(error.response.data.errors)
            setErrorConnection(0)
        }
        else{
            setErrorConnection(1)
        }
        setLoad(0)
        setSuccessAlert(0)
        setErrorConnectionLoadData(0)
    })
  }
  }

  /// scroll to first error validation
   useEffect(() => {
    if(errorValidation){
    var error_validation_element= document.getElementById('error_validation')
    error_validation_element.scrollIntoView()
    window.scrollBy({top:-180 , behavior: 'smooth'});
    }
   } , [errorValidation])

  return (
    
  <div className="form_page">
      
    <ScrollManager scrollKey="brand_edit" />
    <div className="form_inner">

    <Header title={"ویرایش برند"}/>
    {brand &&
    <div>
    <p>در این صفحه شما می توانید برند خود را ویرایش نمایید.</p>

    <div className="form">

    <FileUpload id={"error_validation"} url={"/api/admin/brand/upload-image"} keyRequest={"logo"} keyResponse={"logo"} updateFile={brand.logo} ImageNameResponse={ImageNameResponse} setImageNameResponse={setImageNameResponse} placeholder={"لوگوی برند"} setErrorValidation={setErrorValidation} errorValidation={errorValidation} error={(errorValidation && errorValidation.logo) ? errorValidation.logo : null}/>
    <Input id={"error_validation"} value={brand.persian_name} type={"text"} label={"نام فارسی برند"} name={"persian_name"} placeholder={"نام فارسی برند"} ref={persian_name}  errorValidation={errorValidation} error={(errorValidation && errorValidation.persian_name) ? errorValidation.persian_name : ""}/>
    <Input id={"error_validation"} value={brand.original_name} type={"text"} label={"نام اصلی برند"} name={"original_name"} placeholder={"نام اصلی برند"} ref={original_name}  errorValidation={errorValidation} error={(errorValidation && errorValidation.original_name) ? errorValidation.original_name : ""}/>

    <div className="submit_parent">
    <button className="submit_form" onClick={()=>handleUpdateBrand()}>ثبت تغییرات</button>
    </div>

    </div>
    </div>
     }

    </div>

    {load == 1 &&
    <SpinnerLoading/>
    }
    {errorConnection == 1 &&
     <ErrorMessgae setError={setErrorConnection}/>
    }
    {errorConnectionLoadData == 1 &&
     <ErrorLoadData func={handleLoadData}/>
    }

    </div>
    
  );
}

