import { post } from "../http/http";
import React, { useState, useEffect } from "react";
export default function PropertyDelete({
  item,
  properties,
  setProperties,
  load,
  setLoad,
  setErrorMessage,
  typeDelete,
}
) {
  function handleDelete() {
    if (load == 0) {
      setLoad(1);
      var request = { id: item.id };
      post("/api/admin/property/delete", request)
        .then((response) => {
          setErrorMessage(0);
          setLoad(0);
          if(typeDelete == "key"){
            var properties_filtered = properties.filter(
              (property) => property.id != item.id
            );
            setProperties(properties_filtered);
          }else if(typeDelete == "value"){

            var property_id = item.property_id;
            var id = item.id;
            var propertiesList = properties
            propertiesList.map((el) => {
              if (el.id == property_id) {
                if (el.values) {
                var values = el.values.filter((el_child) => el_child.id != id);
                el.values = values;
                }
              }
            });
            setProperties(propertiesList)
          }
        })
        .catch((error) => {
          setErrorMessage(1);
          setLoad(0);
        });
    }
  }
  return (
    <>
      <button className="btn_item" onClick={() => handleDelete()}>
        حذف
      </button>
    </>
  );
}