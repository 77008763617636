import { useEffect, useState, useRef, useContext } from "react";
import ErrorMessgae from "../../Error/ErrorMessage";
import { get, post } from "../../http/http";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import ProductColorItem from "./ProductColorItem";
import Colors from "../ColorsList/Colors";
import ErrorLoadData from "../../Error/ErrorLoadData";
import { useParams } from "react-router-dom";
import { Context } from "../../Context/Context";
import Input from "../../Inputs/Input";
import Header from "../../Header/Header";
import ScrollManager from "../../ScrollManager/ScrollManager";
import { useQueryClientProduct } from "../../Products/index/Products";

export default function ProductColor() {
  const [errorConnection, setErrorConnection] = useState(0);
  const [errorConnectionLoadData, setErrorConnectionLoadData] = useState(0);
  const [errorValidation, setErrorValidation] = useState();
  const [load, setLoad] = useState(0);
  const [product, setProduct] = useState();
  const [colors, setColors] = useState();
  const [sizes , setSizes] = useState()
  const [attributes, setAttributes] = useState([]);
  const [showInputNumber, setShowInputNumber] = useState(0);
  const { successAlert, setSuccessAlert } = useContext(Context);
  const [retry, setRetry] = useState(0);
  const [queryClientProducts] = useQueryClientProduct();
  const [priceProduct, setPriceProduct] = useState();
  const [numberProduct, setNumberProduct] = useState();
  const params = useParams();
  const number = useRef();

  useEffect(() => {
    setLoad(1);
    var request = { product_id: params.id };
    post("/api/admin/color-product/index", request)
      .then((response) => {
        setLoad(0);
        setProduct(response.product);
        setColors(response.colors);
        setSizes(response.sizes)
        handleAttributes(response.product_attributes)
        setErrorConnectionLoadData(0);
        setErrorConnection(0);
        selectShowInputNumber(response);
        handlePriceAndNumberInput(response)
      })
      .catch((error) => {
        setLoad(0);
        setErrorConnectionLoadData(1);
        setErrorConnection(0);
      });

    return () => {
      //cleanup
    };
  }, [retry]);

  function selectShowInputNumber(response){
    const productAttributes = response.product_attributes;
    if (productAttributes) {
      if (productAttributes.length > 0) {
        productAttributes.forEach((element) => {
          if ((element.status == 1) && (element.color || element.size)) {
            setShowInputNumber(1);
          }
        });
      }
    }
  }

  function handleAttributes(product_attributes){
    var attributes_updated = [];
    product_attributes.forEach((element) => {
      if(element.color_id != null || element.size_id != null){
        attributes_updated = [...attributes_updated ,element]
        setAttributes(attributes_updated);
      }
    });
  }

  function handlePriceAndNumberInput(response){
     if (response.product_attributes && response.product_attributes.length > 0) {
       response.product_attributes.forEach((element) => {
         if (element.color_id == null && element.size_id == null && element.status == 1) {
           setPriceProduct(element.price);
           setNumberProduct(element.number);
         }
       });
     }
  }

  function handleUpdate() {
    if (load == 0) {
      setLoad(1);
      var request = { product_id: params.id };
      if (showInputNumber == 0) {
        var _number = number.current.value;
        request = { ...request, number: _number };
      } else {
        request = { ...request, colors: attributes };
      }
      post("/api/admin/color-product/update", request)
        .then((response) => {
          setLoad(0);
          setSuccessAlert(1);
          setErrorConnection(0);
          setErrorConnectionLoadData(0);
          setErrorValidation();
          updateCacheProducts(response.product_colors);
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            setErrorValidation(error.response.data.errors);
            setErrorConnection(0);
          } else {
            setErrorConnection(1);
          }
          setLoad(0);
          setSuccessAlert(0);
          setErrorConnectionLoadData(0);
        });
    }
  }

  function updateCacheProducts(product_colors) {
    var products = queryClientProducts.getQueryData();
    if (products) {
      products.pages.forEach((page) => {
        page.forEach((item) => {
          if (item.id == params.id) {
              item.attribute = product_colors;
          }
        });
      });
    }
  }

  function handleShowInput() {
    if (showInputNumber == 0) {
      setShowInputNumber(1);
    } else {
      setShowInputNumber(0);
    }
  }
  
  function handleLoadData(){
    setRetry(retry+1)
  }

    function addAttribute() {
      var random_number = Math.random()*10000;
      var attribute_updated = [
        ...attributes,
        {
          id: random_number,
          color:null,
          size:null,
          color_id: null,
          size_id: null,
          number: 0,
          price_increase: 0,
          new_attr:true,
        },
      ];
      setAttributes(attribute_updated);
    }

  return (
    <>
      {product && (
        <div className="form_page">
          <ScrollManager scrollKey={"product_color" + params.id} />
          <Header title={"ویرایش تعداد و رنگ محصول"} />
          <div className="product_colors">
            <p>{product.name}</p>
            <div className="labels_parent">
              <label onClick={() => handleShowInput()}>
                آیا محصول رنگ بندی دارد؟
              </label>

              <input
                className="checkbox_input"
                defaultValue={showInputNumber}
                id="showInputNumber"
                type="checkbox"
                onChange={() => handleShowInput()}
              />
              <span
                className={
                  showInputNumber == 1 ? "checked" : "checked_disabled"
                }
                onClick={() => handleShowInput()}
              ></span>
            </div>
            {colors && (
              <div>
                {errorValidation == 1 && (
                  <p className="error_validation">
                    قیمت افزایشی رنگ محصول باید عددی باشد
                  </p>
                )}

                {showInputNumber == 0 && (
                  <>
                    <Input
                      id={"error_validation"}
                      value={numberProduct}
                      type={"tel"}
                      label={"تعداد محصول"}
                      name={"number"}
                      placeholder={"مثال: 100"}
                      ref={number}
                      errorValidation={errorValidation}
                      error={
                        errorValidation && errorValidation.number
                          ? errorValidation.number
                          : ""
                      }
                    />
                  </>
                )}
                {showInputNumber == 1 && (
                  <div className="content">
                    <div className="product_colors">
                      {attributes &&
                        attributes.length > 0 &&
                        attributes.map((item, index) => (
                          <>
                            {((item.color) || (item.size) || (item.new_attr)) &&
                            <ProductColorItem
                              key={item.id}
                              item={item}
                              index={index}
                              attributes={attributes}
                              setAttributes={setAttributes}
                              product={product}
                              errorValidation={errorValidation}
                              colors={colors}
                              sizes={sizes}
                            />
                            }
                          </>
                        ))}
                      {errorValidation && errorValidation["colors"] && (
                        <div className="error" id="error_validation">
                          <span>هیچ رنگی انتخاب نشده است.</span>
                        </div>
                      )}
                    </div>
                    <button className="add_attribute" onClick={() => addAttribute()}>افزودن</button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="submit_parent">
            <button className="submit_form" onClick={() => handleUpdate()}>
              ثبت تغییرات
            </button>
          </div>
        </div>
      )}
      {load == 1 && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {errorConnectionLoadData == 1 && <ErrorLoadData func={handleLoadData} />}
    </>
  );
}
