
import { useState, useEffect, useRef } from "react";
export default function SizeTypes({ sizes,selectSize, sizesOfType, setSizeOfType }) {
  const [types, setTypes] = useState([]);
  useEffect(() => {
    if (sizes) {
      sizes.forEach((size) => {
        if (size.size_id == null) {
          setTypes((types) => [...types, size]);
        }
      });
    }
  }, [sizes]);

  useEffect(()=>{
   if(selectSize){
     handleSizeOfType(selectSize.size_id);
   }
  },[])

  function handleSizeOfType(size_id) {
    setSizeOfType([]);
    sizes.forEach((size) => {
      if (size.size_id != null && size.size_id == size_id) {
        setSizeOfType((sizesOfType) => [...sizesOfType, size]);
      }
    });
  }

  return (
    <>
      {types &&
        types.length > 0 &&
        types.map((item, index) => (
          <div key={item.id} className="type">
            <button onClick={() => handleSizeOfType(item.id)}>{item.size}</button>
          </div>
        ))}
    </>
  );
}