import React from 'react';
import ReactDOM from 'react-dom';
import { useState, useEffect , useRef , useMemo  } from "react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { get, post } from "../http/http";
import {useNavigate  ,useParams} from "react-router-dom";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import OldImageItem from "./OldImageItem";
import ErrorLoadData from '../Error/ErrorLoadData';

///////////////////

 const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

 export default function OldImageGallery({imagesNameUploaded , setImagesNameUploaded}) {

   return (
     <QueryClientProvider client={queryClient}>
       <Records imagesNameUploaded={imagesNameUploaded} setImagesNameUploaded={setImagesNameUploaded} />
     </QueryClientProvider >
   )
 
   }
  function Records({imagesNameUploaded , setImagesNameUploaded}) {

    const [run , setRun]= useState(0)
    const params = useParams()
    var request = {"product_id":params.id}

    function handleRetry(){
      queryClient.resetQueries("gallery"+id)
    }

    var id=params.id
    const { isLoading  ,isFetching ,isFetched, error, data, isError } = useQuery(["gallery"+id,] ,() =>
     post('/api/admin/gallery/index' , request)

    );

   useEffect(() => {
    if(run == 0 && data){
      if(data.images){
      data.images.forEach(el => {
      setImagesNameUploaded((imagesNameUploaded)=>[...imagesNameUploaded ,{"image":el.image}])
      });
    }
      setRun(1)
    }
    } ,[data])
 
   return (

     <div className='parent_old_gallery'> 

        {isLoading &&
          <SpinnerLoading/>
        }
        {isFetching &&
          <SpinnerLoading/>
        }
        {!isFetching && isFetched && data && data.images && data.images.map((item , index)=>(
         <div className='image_gallery_parent' key={index+item.image}>
         <OldImageItem data={data} item={item.image} imagesNameUploaded={imagesNameUploaded} setImagesNameUploaded={setImagesNameUploaded}/>
         </div>
         )) 
        }

        {error &&
        <ErrorLoadData func={handleRetry}/>
        }
    
     </div>
     
   )

}

export function useQueryClientGallery(){
  return[queryClient]
}
