import React, { useState , useEffect } from "react";
import ReactDOM from "react-dom";
import UrlBase from "../http/UrlHttp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function OldImageItem({item , data, imagesNameUploaded , setImagesNameUploaded}) {

    const [previewImage , setPreviewImage] = useState(1)

    function handleDeleteImage(){
      setImagesNameUploaded(current =>
      current.filter(element => {
      return element.image !== item;
      }),
      );
     setPreviewImage(0)
  }

  return (
        <div>
        {previewImage == 1 &&
        <div>
        <div className="image_parent">
          <LazyLoadImage
        //    alt={item.name}
           effect="blur"
           src={UrlBase+item} 
           className="image_product_uploaded"/>
         <div className="delete_image_btn">
         <img src="/trash_svg.svg" className="delete_icon_image" onClick={()=>handleDeleteImage()}/>
         </div>
         </div>
        </div>
         }
         
        </div>
  );
}
