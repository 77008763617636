import React, { useState , useEffect , useRef , useContext } from "react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import ReactDOM from "react-dom";
import { get, post } from "../../http/http";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import {useNavigate} from "react-router-dom";
import ScrollManager from '../../ScrollManager/ScrollManager'
import Input from "../../Inputs/Input";
import Header from "../../Header/Header";
import { Context } from '../../Context/Context';
import ErrorMessgae from "../../Error/ErrorMessage";
import ErrorLoadData from "../../Error/ErrorLoadData";
import useDiscount from "./useDiscount";
import DatePickerComponent from "../../Inputs/DatePickerComponent";

export default function GeneralDiscount() {

  const navigate = useNavigate()
  const [queryClient] = useDiscount()
  return (
    <div className="form_page">
      <ScrollManager scrollKey="generalDiscount" />
      <div className="form_inner">
        <Header title={"تخفیف عمومی"} />
        <span className="description_page">
          در این بخش شما می توانید برای محصولات خود تخفیف عمومی در نظر بگیرید.
          با فعال کردن تخفیف عمومی، تخفیف روی همه محصولات به غیر از محصولاتی که به صورت مستقل تخفیف دارند اعمال می شود.
        </span>
        <QueryClientProvider client={queryClient}>
          <ReactQuery />
        </QueryClientProvider>
      </div>
    </div>
  );
   }

function ReactQuery(){

  const percentInput   = useRef()
  const maximumInput   = useRef()
  const nameInput      = useRef()
  const statusInput    = useRef()

  const {successAlert , setSuccessAlert} = useContext(Context)
  const { isLoading, error ,isError, data , isFetching } = useQuery('discount_repodata', () =>
   post('/api/admin/general-discount/index')
  )
  const [queryClient , load , setLoad ,errorValidation , setErrorValidation ,errorConnection , setErrorConnection ,errorConnectionLoadData , setErrorConnectionLoadData ,status , setStatus ,handleCreateDiscount , handleStatus , handleRefresh , startDate, setStartDate , finishDate, setFinishDate] = useDiscount(data,percentInput , maximumInput , nameInput , statusInput);

  return(

  <div>
    <div className="form">

    <div className="input_parent">
      <div className="checkbox_parent">
        <div>
         <label htmlFor="status"> تخفیف عمومی اعمال شود</label>
        </div>
        <div>
         <input className="checkbox_input" defaultValue={data ? (data.status == 0 ? 0 : 1) : 0} id="status" type="checkbox" ref={statusInput} onChange={()=>handleStatus()}/>
         <span className={data && data.status == 1 ? "checked" : "checked_disabled"} onClick={()=>handleStatus()}></span>
        </div>
      </div>
    </div>

    <div className={data && data.status == 1 ? "parent_inputs_delivery_active" : "parent_inputs_delivery_inactive"}>
      <Input id={"error_validation"} type={"text"} disable={data && data.status == 0 ? "false" : "true"} value={data && Object.keys(data).length > 0 ? data.name_discount : null}    label={"عنوان تخفیف"}          name={"name_discount"}    placeholder={"عنوان تخفیف"}    ref={nameInput}     errorValidation={errorValidation} error={(errorValidation && errorValidation.name_discount) ?    errorValidation.name_discount : ""}/>
      <Input id={"error_validation"} type={"tel"}  disable={data && data.status == 0 ? "false" : "true"} value={data && Object.keys(data).length > 0 ? data.percent_discount : null} label={"درصد تخفیف (درصد)"}    name={"percent_discount"} placeholder={"مثال : 20"}      ref={percentInput}  errorValidation={errorValidation} error={(errorValidation && errorValidation.percent_discount) ? errorValidation.percent_discount : ""}/>
      <Input id={"error_validation"} type={"tel"}  disable={data && data.status == 0 ? "false" : "true"} value={data && Object.keys(data).length > 0 ? data.maximum_discount : null} label={"حداکثر تخفیف (تومان)"} name={"price"}            placeholder={"مثال : 100,000"} ref={maximumInput}  errorValidation={errorValidation} error={(errorValidation && errorValidation.maximum_discount) ? errorValidation.maximum_discount : ""}/>

      <div className="input_parent">
      <label>انتخاب تاریخ و زمان شروع تخفیف</label>
      <DatePickerComponent data={data && data} date={data && data.start_discount} datePicker={startDate} setDatePicker={setStartDate}/>
      </div>

      <div className="input_parent">
      <label>انتخاب تاریخ و زمان پایان تخفیف</label>
      <DatePickerComponent data={data && data} date={data && data.finish_discount} datePicker={finishDate} setDatePicker={setFinishDate}/>
          {errorValidation && errorValidation["datapicker-error"] &&
            errorValidation["datapicker-error"].map((item , index)=>(
            <div id="error_validation" className='error' key={index}>
            <span>{item}</span>
            </div>
            ))
          }
      </div>

    </div>
    <div className="submit_parent">
    <button className={data && data.status == 1 ? "submit_form" : "submit_form_inactive"} disabled={data && (data.status== 0 ? "disabled" : null)} onClick={()=>handleCreateDiscount()}>ثبت تغییرات</button>
    </div>
    </div>

    {load == 1 &&
    <SpinnerLoading/>
    }
    {errorConnection == 1 &&
     <ErrorMessgae setError={setErrorConnection}/>
    }
    {isLoading &&
     <SpinnerLoading />
    }
    {isFetching &&
     <SpinnerLoading/>
    } 
    {isError &&
      <ErrorLoadData func={handleRefresh}/> 
    }
  </div>
  );
}

