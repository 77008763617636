import PropertyDelete from "../PropertyDelete";
import PropertyEdit from "../PropertyEdit";
import React, { useState, useEffect } from "react";

export default function ValueItem({
  item,
  properties,
  setProperties,
  load,
  setLoad,
  setErrorMessage,
}) {
  const [showEdit, setShowEdit] = useState(0);
  const handleShowEditModal = () => {
    if (showEdit == 1) {
      setShowEdit(0);
    } else {
      setShowEdit(1);
    }
  };
  return (
    <>
      <div className="item_value">
        <span>{item.name}</span>
        <button className="btn_item" onClick={() => handleShowEditModal()}>ویرایش</button>
        <PropertyDelete
          item={item}
          properties={properties}
          setProperties={setProperties}
          load={load}
          setLoad={setLoad}
          setErrorMessage={setErrorMessage}
          typeDelete={"value"}
        />
      </div>
      {showEdit == 1 && (
        <PropertyEdit
          item={item}
          properties={properties}
          setProperties={setProperties}
          load={load}
          setLoad={setLoad}
          setErrorMessage={setErrorMessage}
          setShowEditModal={setShowEdit}
        />
      )}
    </>
  );
}
