
import React, { useState , useEffect , useRef , useContext } from "react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import ReactDOM from "react-dom";
import { get, post } from "../../http/http";
import {useNavigate , useParams} from "react-router-dom";
import { Context } from '../../Context/Context';
import { useQueryClientComments } from "../List/Comments";

   const queryClient = new QueryClient({
    defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      // keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

export function useQueryClientCommentShow(){
    return ([queryClient])
}

export function CommentLogic(data , comment ,setComment){

    const [load , setLoad] = useState(0)
    const [isAdmin , setIsAdmin] = useState()
    const [errorValidation , setErrorValidation] = useState()
    const [errorConnection , setErrorConnection] = useState(0)
    const {successAlert , setSuccessAlert} = useContext(Context)
    const [queryClientComments] = useQueryClientComments()
    const params = useParams()

    useEffect(()=>{
      if(data){
       if(data.user == null || data.user.is_admin == 0){
         setIsAdmin(0)
        }else{
         setIsAdmin(1)
       }
       }
     },[data])

    function sendComment(){
        if(load == 0){
        setLoad(1)
        var request = {"comment_id": params.id, "body" : comment}
        post("/api/admin/comment/create" , request)
        .then(response=>{
            if(response){
              setSuccessAlert(1)
              setLoad(0)
              setErrorConnection(0)
              setErrorValidation()
              updateDataAfterSend(response)
            }
        })
        .catch(error =>{
          if(error.response && error.response.status == 422){
               setErrorValidation()
               setErrorConnection(0)
           }
           else{
               setErrorConnection(1)
           }
           setLoad(0)
           setSuccessAlert(0)
           })
          }
    }

    function updateDataAfterSend(response){
      const comment = queryClient.getQueryData('comment_repodata'+response.parent.id)
      const comments = queryClientComments.getQueryData('comments_repoData')
      comment.child = [...comment.child , response]
      queryClient.setQueryData('comment_repodata'+response.parent.id, comment)
      //
      if(comments){
      comments.pages[0] = [response , ...comments.pages[0]]
      queryClientComments.setQueryData('comments_repoData', comments)
      }
    }

    ///

    function handleChangeStatus(id){
      if(load == 0){
       setLoad(1)
       var request = {"id" : id}
       post("/api/admin/comment/status" , request)
       .then(response=>{
             setLoad(0)
             setErrorConnection(0)
             updateCommentsCached(response)
       })
       .catch(error=>{
            setLoad(0)
            setErrorConnection(1)
       })
       }
     

     function updateCommentsCached(response){
      
        const comments = queryClientComments.getQueryData()
        const comment = queryClient.getQueryData('comment_repodata'+params.id)
        const comment_reply_page = queryClient.getQueryData('comment_repodata'+response.id)
        const comment_reply_parent = queryClient.getQueryData('comment_repodata'+response.parent.id)
        //// update comments cached
        if(comments){
        comments["pages"].forEach(page => {
             page.forEach(record => {
                 if(record.id == response.id){
                    record.status = response.status
                 }
             });
        });
        queryClientComments.setQueryData('comment_repodata', comments)
         }

         //update reply in parent page
         if(comment_reply_parent){
          comment_reply_parent.child.forEach(element => {
            if(element.id == response.id){
              element.status = response.status
            }
          });
          queryClient.setQueryData('comment_repodata'+response.parent.id , comment_reply_parent)
         }

         ////update comment page
         if(comment.id == response.id){
          queryClient.setQueryData('comment_repodata'+response.id , response)
         }
         else{
          ///update comment reply page
          comment.child.forEach(element => {
               if(comment_reply_page){
                 comment_reply_page.status = response.status
                 queryClient.setQueryData(['comment_repodata'+response.id, comment_reply_page])
               }   
          });
         }
          queryClient.setQueryData(['comment_repodata'+response.id , comment])

        } 
      }

    /////

    function handleRefresh(){
        queryClient.resetQueries('comment_repodata'+params.id)
    }

    return(
        [queryClient ,handleChangeStatus , handleRefresh ,isAdmin, sendComment , load , setLoad,
         errorConnection , setErrorConnection ,
         successAlert , setSuccessAlert , errorValidation , setErrorValidation
        ])

}

