import React, { useState , useEffect , useRef , useContext } from "react";
import {useNavigate  ,useParams} from "react-router-dom";
import { Context } from "../Context/Context";

export default function Header({setSearch , searchValue ,title , placeholderInputSearch , urlCreateBtn , txtCreateBtn}){
 const inputSearch = useRef();
 const navigate = useNavigate()
 const {menu , setMenu} = useContext(Context)
function handleSearch() {

    if (inputSearch.current.value == "") {
      setSearch(null);
    } else {
      setSearch(inputSearch.current.value);
    }
    window.scrollTo(0 ,0);
}

function handleMenu(){
   if(menu == 0){
     setMenu(1)
   }
}

  return (
    <div className="header">
    <div className="inner_header">

      <div className="title">
        <div className="menu_icon">
          <button onClick={()=>handleMenu()}>
            <img src="/menu.svg"/>
          </button>
        </div>
        {title &&
        <span>{title}</span>
        }
      </div>

    {placeholderInputSearch &&
      <div className="search_parent"> 
      <img src="../search.svg"/>   
        <input
          type="text"
          placeholder={placeholderInputSearch}
          defaultValue={searchValue}
          onKeyUp={() => handleSearch()}
          ref={inputSearch}
        />
      </div>
    }
    {urlCreateBtn &&
      <div className="create_parent">
      <button className="create" onClick={()=>navigate(urlCreateBtn)}>{txtCreateBtn}</button>
      </div>
    }
    </div>
    </div>
  );
}


