import { useContext, useRef, useState } from "react";
import Header from "../Header/Header";
import { post } from "../http/http";
import Input from "../Inputs/Input";
import ScrollPositionManager from "../ScrollManager/ScrollManager";
import { Context } from "../Context/Context";
import ErrorMessgae from "../Error/ErrorMessage";
import { useQueryClientColors } from "./Colors";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import { HexAlphaColorPicker } from "react-colorful";

export default function ColorCreate() {
  const [color, setColor] = useState("#000000");
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [loading, setLoading] = useState(0);
  const { successAlert, setSuccessAlert } = useContext(Context);
  const [queryClient] = useQueryClientColors()
  const name = useRef();

  function create() {
    if (loading == 0) {
      setLoading(1);
      var request = { name: name.current.value, color: color };
      post("/api/admin/colors/create", request)
        .then((response) => {
          setLoading(0);
          setSuccessAlert(1);
          setErrorConnection(0);
          setErrorValidation()
          name.current.value = ""
          addColorToListColors(response)
        })
        .catch((error) => {
          setLoading(0);
          if (error.response && error.response.status != 422) {
            setErrorConnection(1);
          } else {
            setErrorValidation(error.response.data.errors);
          }
        });
    }
  }

  function addColorToListColors(response){
     const colorsData = queryClient.getQueryData();
     if(colorsData){
     colorsData.pages[0] = [response,...colorsData.pages[0]]
     queryClient.setQueryData('colors_repoData', colorsData);
     }
  }

  return (
    <>
      <div className="form_page">
        <ScrollPositionManager scrollKey="color_create" />
        <Header title={"ثبت رنگ"} />
        <div className="input_parent">
          <label>انتخاب رنگ</label>
          <div className="color_picker">
            <HexAlphaColorPicker color={color} onChange={setColor} />
          </div>
        </div>

        <Input
          id={"error_validation"}
          type={"text"}
          label={"نام رنگ"}
          name={"name"}
          placeholder={"نام رنگ"}
          ref={name}
          errorValidation={errorValidation}
          error={
            errorValidation && errorValidation.name ? errorValidation.name : ""
          }
        />
        <div className="submit_parent">
          <button className="submit_form" onClick={() => create()}>
            ثبت رنگ
          </button>
        </div>
        {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
        {loading == 1 && <SpinnerLoading />}
      </div>
    </>
  );
}
