import { useEffect , useState } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../Header/Header"
import {get , post} from "../http/http"
export default function Slides(){
 
    const navigate = useNavigate()
    const [countSliders , setCountSliders] = useState([{"id":1 , "message":"اسلایدر اول"} , {"id":2 ,"message":"اسلایدر دوم"}])

    return (
      <div>
        <Header title={"اسلایدر ها"} />
        <div className="list_slides">
          {countSliders &&
            countSliders.map((item, index) => (
              <div className="row" key={index}>
                <span className="id">{item.id}</span>
                <span>{item.message}</span>
                <button onClick={() => navigate("/slides/" + item.id)}>
                  تغییر اسلاید
                </button>
              </div>
            ))}
        </div>
      </div>
    );
}