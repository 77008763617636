import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import DeleteAlert from "../../../Alerts/DeleteAlert";
import UrlBase from "../../../http/UrlHttp";
import { get, post } from "../../../http/http";
import {useNavigate} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ErrorMessgae from "../../../Error/ErrorMessage";

const ProductDiscountItem = React.forwardRef(({item ,index , queryClient}, ref) => {

  const navigate = useNavigate()
  const [error, setError] = useState(0)
  const [priceFinal, setPriceFinal] = useState(0)
  const [percentFinal , setPercentFinal] = useState()

  useEffect(()=>{
    if(item.product){
   var price            =   item.product.price
   var discount         =   item.percent_discount
   var maximum_discount = item.maximum_discount
   var percent_value    =   price * ( (discount) / 100 )
   var price_final = 0
   var percent_finaly = 0

   if((maximum_discount == null) || (percent_value < maximum_discount)){
     price_final  =   price * ( (100 - discount) / 100 )
     percent_finaly = ((price - price_final.toFixed()) / price) * 100;
    setPriceFinal(price_final.toFixed())
    setPercentFinal(percent_finaly.toFixed());
   }else{
    price_final   =   price - maximum_discount
    percent_finaly = ((price - price_final.toFixed()) / price) * 100;
   setPriceFinal(price_final.toFixed())
   setPercentFinal(percent_finaly.toFixed());
   }
  }
  } , [])

  const postBody = (
    <>
      {item.deleted_at == null && item.product && (
        <div className="item">
          <div className="info_parent">
            {item.product && (
              <div className="info">
                <div className="name_parent">
                  <label>نام محصول : </label>
                  <span className="name">{item.product.name}</span>
                </div>

                <div className="price_parent">
                  <label>قیمت اصلی محصول : </label>
                  <span className="price">
                    {item.product.price && item.product.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    تومان{" "}
                  </span>
                </div>

                <div className="price_parent">
                  <label> درصد تخفیف : </label>
                  <span className="price">
                    {item.percent_discount == null ? 0 : item.percent_discount}{" "}
                    درصد{" "}
                  </span>
                </div>

                <div className="price_parent">
                  <label> حداکثر تخفیف : </label>
                  {item.maximum_discount == null ? (
                    <span className="price"> - </span>
                  ) : (
                    <span className="price">
                      {item.maximum_discount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      تومان{" "}
                    </span>
                  )}
                </div>

                <div className="price_parent">
                  <label>قیمت با تخفیف : </label>
                  <span className="price">
                    {priceFinal
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    تومان{" "}
                  </span>
                </div>
                {percentFinal > 0 && (
                  <div className="price_parent">
                    <label> درصد نهایی تخفیف : </label>
                    <span className="price">
                      {percentFinal == null ? 0 : percentFinal} درصد{" "}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="btn_parent">
            <button
              className="btn_discount_datail"
              onClick={() => navigate("/product/discount/" + item.product_id)}
            >
              جزئیات تخفیف
            </button>
          </div>

          {error == 1 && <ErrorMessgae setError={setError} />}
        </div>
      )}
    </>
  );

  const content = ref ? (
    <article ref={ref}>{postBody}</article>
  ) : (
    <article>{postBody}</article>
  );

  return content;
});

export default ProductDiscountItem;
