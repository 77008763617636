import { isEmpty } from "lodash";
import React, { useState } from "react";
import DeleteAlert from "../Alerts/DeleteAlert";
import UrlBase from "../http/UrlHttp";
import { get, post } from "../http/http";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import ErrorMessgae from "../Error/ErrorMessage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SpinnerLoading from "../Spinner/SpinnerLoading";

const CategoryItem = React.forwardRef(
  ({ item, index, name, queryClient }, ref) => {
    const [showDeleteModal, setShowDeleteModal] = useState(0);
    const [deleted, setDeleted] = useState(0);
    const [load, setLoad] = useState(0);
    const navigate = useNavigate();
    const [error, setError] = useState(0);

    function handleDelete() {
      setShowDeleteModal(1);
    }

    function handleStatus() {
      if (load == 0) {
        setLoad(1);
        var request ={id:item.id}
        post("/api/admin/category/status" , request)
          .then((response) => {
            if(response){
            const categories = queryClient.getQueryData();
            categories.pages.forEach((page) => {
              page.forEach((element) => {
                if (element.id == item.id) {
                  if (response.status == 0) {
                    element.status = 0;
                  } else {
                    element.status = 1;
                  }
                }
              });
            });
            queryClient.setQueryData("category_repoData" , categories);
          }
            setError(0);
            setLoad(0);
          })
          .catch((error) => {
            setLoad(0);
            setError(1);
          });
      }
    }

    const postBody = (
      <>
        {item.deleted_at == null && (
          <div className="item">
            <div className="info_parent">
              <div className="image_parent">
                {item.image && item.image != null && (
                  <LazyLoadImage
                    alt={item.name}
                    effect="blur"
                    src={UrlBase + item.image}
                  />
                )}
              </div>
              <div className="info">
                <div className="name_parent">
                  <label>نام دسته بندی : </label>
                  <span className="name">{item.name}</span>
                </div>
                <div className="category_parent">
                  <label> دسته اصلی : </label>
                  {!isEmpty(item.parent) ? (
                    <div className="parent">
                      <span>{item.parent.name}</span>
                    </div>
                  ) : (
                    <div className="parent">
                      <span>ندارد</span>
                    </div>
                  )}
                </div>
                <div className="category_child">
                  <div>
                    <label>زیر دسته ها : </label>
                    {item.child && item.child.length > 0 ? (
                      item.child.map((item_child, i) => (
                        <div className="child" key={i}>
                          <span>{item_child.name}</span>
                        </div>
                      ))
                    ) : (
                      <div className="child">
                        <span>ندارد</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="btn_parent">
              <button
                className={item.status == 0 ? "btn_fire" : "btn_fire fire"}
                onClick={() => handleStatus()}
              >
                <img src="./fire.svg" />
              </button>
              <button
                className="btn_update"
                onClick={() => navigate("/category/edit/" + item.id)}
              >
                ویرایش
              </button>

              <button
                className="btn_update"
                onClick={() => navigate("/property/" + item.id)}
              >
                ویژگی ها
              </button>

              <button className="btn_delete" onClick={() => handleDelete()}>
                حذف
              </button>
              
              <button className="btn_update" onClick={() => navigate("/category/brands/"+item.id)}>
                برندها
              </button>
            </div>

            {showDeleteModal == 1 && (
              <DeleteAlert
                item={item}
                url={"/api/admin/category/delete"}
                request={{ id: item.id }}
                title={
                  item.deleted_at == null
                    ? "حذف  دسته بندی"
                    : "لغو حذف دسته بندی"
                }
                buttonTxt={
                  item.deleted_at == null
                    ? "حذف  دسته بندی"
                    : "لغو حذف دسته بندی"
                }
                queryClient={queryClient}
                description={
                  item.deleted_at == null
                    ? "آیا شما از حذف این دسته بندی اطمینان دارید؟"
                    : "آیا شما از لغو حذف این دسته بندی اطمینان دارید؟"
                }
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                deleted={deleted}
                setDeleted={setDeleted}
                setError={setError}
              />
            )}

            {load == 1 && <SpinnerLoading />}
            {error == 1 && <ErrorMessgae setError={setError} />}
          </div>
        )}
      </>
    );

    const content = ref ? (
      <article ref={ref}>{postBody}</article>
    ) : (
      <article>{postBody}</article>
    );

    return content;
  }
);

export default CategoryItem;
