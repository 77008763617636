import { isEmpty } from "lodash";
import React, { useState } from "react";
import DeleteAlert from "../../Alerts/DeleteAlert";
import UrlBase from "../../http/UrlHttp";
import { get, post } from "../../http/http";
import {useNavigate} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ErrorMessgae from "../../Error/ErrorMessage";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import Logic from "./Logic";

const CommentItem = React.forwardRef(({ item, index,queryClient, name, refresh , setRefresh}, ref) => {

  const navigate = useNavigate()
  const [load ,isAdmin,errorConnection ,setErrorConnection , useVerification] = Logic(item , queryClient)
  function createMarkup(){
     return { __html: item.body };
   }
  const postBody = (
      
      <div className={isAdmin == 1 ? "item item_admin" : "item"}>
        <div className="info_parent">
        <div className="info">

        <div className="name_parent">
          {isAdmin == 1?
          <label className="admin_mark">مدیر سایت</label>
          : 
          <label className="user_mark">کاربر سایت</label>
          }
        </div>

        <div className="name_parent">
          <label> تاریخ ارسال نظر : </label>
          <span className="name">{new Date(item.created_at).toLocaleDateString('fa-IR')}</span>
        </div>

        <div className="name_parent">
          <span dangerouslySetInnerHTML={createMarkup()} className="name"></span>
        </div>

        </div>
        </div>

        <div className="btn_parent">
        <button className="btn_update" onClick={()=>navigate("/comment/"+item.id)}>پاسخ</button>
        {item.status == 0 ?
        <button className="btn_no_verify" onClick={useVerification}>در انتظار تأیید</button>
        :
        <button className="btn_verify" onClick={useVerification}>تأیید شده</button>
        }
        </div>

      {errorConnection == 1 &&
       <ErrorMessgae setError={setErrorConnection}/>
      }
      
      {load == 1 &&
        <SpinnerLoading/>
      }

      </div>

  );

  const content = ref ? (
    <article ref={ref}>{postBody}</article>
  ) : (
    <article>{postBody}</article>
  );

  return content;
});

export default CommentItem;
