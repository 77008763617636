
import React, { useState ,useRef ,useContext } from "react";
import { Context } from "../Context/Context";
import ErrorMessgae from "../Error/ErrorMessage";
import { post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";

export default function LogOutModal({ handleShowLogOutModal }) {
  const [load, setLoad] = useState(0);
  const [error, setError] = useState(0);
  const modalLogout = useRef()
  const {setIsAdmin} = useContext(Context)

  function handleLogout() {
    if (load == 0) {
      setLoad(1);
      post("/api/market/login-register/logout")
        .then((response) => {
          setLoad(0);
          setError(0);
          setIsAdmin(false)
        })
        .catch((error) => {
          setError(1);
          setLoad(0);
        });
    }
  }

  return (
    <>
      <div className="logout_modal">
        {error == 1 && (
          <div className="parent_error">
            <span>اتصال اینترنت وجود ندارد.</span>
          </div>
        )}

        <div ref={modalLogout} className="inner_logout_modal">
          <div className="logout_close_modal">
            <span onClick={() => handleShowLogOutModal()}>&#215;</span>
          </div>
          <span className="title">خروج از حساب کاربری</span>
          <span className="text">
            آیا با اطمینان قصد خروج از حساب کاربری خود را دارید؟
          </span>
          <div className="btns">
            <div className="cansel">
              <div onClick={() => handleShowLogOutModal()}>انصراف</div>
            </div>
            <div className="ok">
              <div onClick={() => handleLogout()}>خروج</div>
            </div>

            {load == 1 && <div className="lds-dual-ring"></div>}
          </div>
        </div>
      </div>
      {error == 1 && <ErrorMessgae setError={setError} />}
      {load == 1 && <SpinnerLoading />}
    </>
  );
}