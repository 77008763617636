
import React, { useState , useContext } from "react";
import ReactDOM from "react-dom";
import { Context } from '../Context/Context';

export default function SuccessAlert({title , description}) {
  const {successAlert , setSuccessAlert} = useContext(Context);
  return (
    <>
    {successAlert == 1 &&
      <div className="parent_success_container">
        <div className="parent_success">
          <div className="modal">
            <div className="sa">
              <div className="sa-success">
                <div className="sa-success-tip"></div>
                <div className="sa-success-long"></div>
                <div className="sa-success-placeholder"></div>
                <div className="sa-success-fix"></div>
              </div>
            </div>

            <span className="title">عملیات با موفقیت انجام شد.</span>
            <div className="parent_btn">
              <span className="btn" onClick={() => setSuccessAlert(0)}>
                باشه
              </span>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
}
