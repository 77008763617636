import React, { useState , useEffect , useRef } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import UrlBase from "../http/UrlHttp";
import ErrorLoadData from '../Error/ErrorLoadData'
import {useNavigate  ,useParams} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function ImageGalleryUpload({file ,id , updateFile ,errorValidationUploadFile , setErrorValidationUploadFile ,imagesNameUploaded , setImagesNameUploaded}) {


  const [ImageNameResponse ,setImageNameResponse] = useState()  
  const [previewImage , setPreviewImage]=useState()
  const [imageLoader , setImageLoader]=useState(0)  //0 => no upload , 1=> uploaded
  const [progressUpload , setProgressUpload] = useState(null)
  const [retryUpload , setRetryUpload] = useState(0)
  const [fileSave , setFileSave] = useState()
  const [retry , setRetry] = useState(0)

  const params = useParams()
  const abortController = useRef(null)

     useEffect(() => {

      abortController.current = new AbortController();  //for cancel request 
      var imageUpload= file
      setFileSave(file)
      setProgressUpload(null)
      setPreviewImage(URL.createObjectURL(imageUpload))
      const formData = new FormData()
      formData.append("image" , imageUpload)
      formData.append("product_id" , params.id)
      setImageLoader(1)
      setRetryUpload(0)
      post("/api/admin/gallery/upload-image" , formData ,
       {signal:abortController.current.signal ,
        onUploadProgress : (data)=>{
        setProgressUpload(Math.round((data.loaded / data.total) * 100))
        }
        
      })
      .then(response=>{
        setImageLoader(0)
        setImageNameResponse(response.image)
        setImagesNameUploaded((imagesNameUploaded)=>[...imagesNameUploaded ,{"image":response.image}])
      })
      .catch(error=>{
        if(error.response && error.response.status == 422){
          setErrorValidationUploadFile(error.response.data.errors)
        }else{
         setRetryUpload(1)
        }
      })
     } ,[retry])

  function handleDeleteImage(){

     abortController.current && abortController.current.abort();
  
      setImagesNameUploaded(current =>
      current.filter(element => {
      return element.image !== ImageNameResponse;
      }),
      );
     setPreviewImage()
     setErrorValidationUploadFile()
      if(updateFile){
        setImageNameResponse(updateFile.image)            
      }
      else{
        setImageNameResponse()
      }
  }


  return (

     <div className="image_gallery">

      {previewImage &&
      <div>
      <div className="image_parent">
       {imageLoader == 0 && 
       <div className="success_upload_image_messgae">
        <img src="/tick.svg" className="icon_success_image_upload"/>
       </div>
       }
       {retryUpload != 0 &&
       <div className="retry_upload_image">
        <button onClick={()=>setRetry(retry + 1)}>تلاش دوباره</button>
       </div>
       }
      <img src={previewImage} className={imageLoader == 0 ? "image_product_uploaded" : "image_product_uploading"}/>
      {progressUpload && imageLoader == 1 && retryUpload == 0 && !errorValidationUploadFile &&
      <div className="progress_upload_image_parent">
      <div className="progress_upload_image" style={{width:`${progressUpload}%`}}></div>
      </div>
       } 
      <div className="delete_image_btn">
      <img src="/trash_svg.svg" className="delete_icon_image" onClick={()=>handleDeleteImage()}/>
      </div>
      </div>
      </div>

      }
     </div>
  );
}
