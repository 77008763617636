import React, { useState, useEffect, useRef, useContext } from "react";
import { get, post } from "../http/http";
import UrlBase from "../http/UrlHttp";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import { useNavigate, useParams } from "react-router-dom";
import ScrollManager from "../ScrollManager/ScrollManager";
import ErrorLoadData from "../Error/ErrorLoadData";
import Input from "../Inputs/Input";
import Header from "../Header/Header";
import { Context } from "../Context/Context";
import ErrorMessgae from "../Error/ErrorMessage";
import CkEditor from "../Inputs/CkEditor";
import FileUpload from "../FileUpload/FileUpload";

export default function Biography() {
  const [load, setLoad] = useState(0);
  const [retry, setRetry] = useState(0);
  const [biography, setBiography] = useState([]);
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [errorConnectionLoadData, setErrorConnectionLoadData] = useState(0);
  const [description, setDescription] = useState();
  const [ImageNameResponse, setImageNameResponse] = useState();
  const { successAlert, setSuccessAlert } = useContext(Context);
  const title = useRef();
  const params = useParams();


  useEffect(() => {
    setLoad(1);
    var request = { id: params.id };
    post("/api/admin/biography/edit", request)
      .then((response) => {
        setLoad(0);
        setBiography(response);
        setDescription(response.description);
        setErrorConnectionLoadData(0);
        setErrorConnection(0);
      })
      .catch((error) => {
        setLoad(0);
        setErrorConnectionLoadData(1);
        setErrorConnection(0);
      });

    return () => {
      //cleanup
    };
  }, [retry]);

  function handleLoadData() {
    setRetry(retry + 1);
  }

  function handleUpdateProduct() {
    if (load == 0) {
      setLoad(1);
      var _title = title.current.value;
      var _image = ImageNameResponse;
      var _description = description;

      var request = {
        title: _title,
        image : _image,
        description: _description,
      };

      post("/api/admin/biography/update", request)
        .then((response) => {
          if (response == "success") {
            setSuccessAlert(1);
            setLoad(0);
            setErrorConnection(0);
            setErrorConnectionLoadData(0);
            setErrorValidation();
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            setErrorValidation(error.response.data.errors);
            setErrorConnection(0);
          } else {
            setErrorConnection(1);
          }
          setLoad(0);
          setSuccessAlert(0);
          setErrorConnectionLoadData(0);
        });
    }
  }

  /// scroll to first error validation
  useEffect(() => {
    if (errorValidation) {
      var error_validation_element =
        document.getElementById("error_validation");
      error_validation_element.scrollIntoView();
      window.scrollBy({ top: -180, behavior: "smooth" });
    }
  }, [errorValidation]);
  ///

  return (
    <div className="form_page">
      <ScrollManager scrollKey={"biography" + params.id} />
      <div className="form_inner">
        <Header title={"زندگی نامه"} />
        {biography && (
          <div>
            <div className="form">
              <Input
                id={"error_validation"}
                value={biography.title}
                type={"text"}
                label={"عنوان زندگی نامه"}
                name={"name"}
                placeholder={"زندگی نامه"}
                ref={title}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.name
                    ? errorValidation.name
                    : ""
                }
              />

              <FileUpload
                id={"error_validation"}
                url={"/api/admin/biography/upload-image"}
                keyRequest={"image"}
                keyResponse={"image"}
                updateFile={biography.image}
                ImageNameResponse={ImageNameResponse}
                setImageNameResponse={setImageNameResponse}
                placeholder={"تغییر تصویر اصلی معرفی"}
                setErrorValidation={setErrorValidation}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.image
                    ? errorValidation.image
                    : null
                }
              />

              <div className="input_parent">
                <div className="description">
                  <label>توضیحات</label>

                  <div className="ckeditor">
                    <CkEditor
                      data={biography.description}
                      onChange={setDescription}
                      rote_upload_image={"api/admin/product/ckeditor"}
                    />
                  </div>

                  {errorValidation &&
                    errorValidation.description &&
                    errorValidation.description.map((item, index) => (
                      <div className="error" id="error_validation" key={index}>
                        <span>{item}</span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="submit_parent">
                <button
                  className="submit_form"
                  onClick={() => handleUpdateProduct()}
                >
                  ثبت تغییرات
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {load == 1 && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {errorConnectionLoadData == 1 && <ErrorLoadData func={handleLoadData} />}
    </div>
  );
}
