import Header from "../Header/Header";
import React, { useState, useEffect } from "react";
import { post } from "../http/http";
import { Router, useParams } from "react-router-dom";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import ErrorMessgae from "../Error/ErrorMessage";
import ErrorLoadData from "../Error/ErrorLoadData";
import BrandItem from "./BrandItem";
import Brands from "./Brands";

export default function CategoryBrands() {
  const [load, setLoad] = useState(0);
  const [error, setError] = useState(0);
  const [category, setCategory] = useState();
  const [categoryBrands, setCategorybrands] = useState([]);
  const [brands, setBrands] = useState();
  const [retry, setRetry] = useState(0);
  const [showBrands , setShowBrands] = useState(0)
  const [errorMessage , setErrorMessage] = useState(0)
  const params = useParams();
  const index = () => {
    setLoad(1);
    var request = { category_id: params.id };
    post("api/admin/category-brands/index", request)
      .then((response) => {
        setBrands(response.brands);
        setCategory(response.category)
        handleCategoryBrands(response.category_brands);
        setError(0);
        setLoad(0);
      })
      .catch((error) => {
        setLoad(0);
        setError(1);
      });
  };
  useEffect(() => {
    index();
  }, [retry]);

  const handleRetry = () => {
    setRetry(retry + 1);
  };

  const handleCategoryBrands = (c_brands) => {
     if(c_brands && c_brands.length > 0){
      c_brands.map((item)=>{
         setCategorybrands((categoryBrands)=>[...categoryBrands ,item.brand])
      })
     }
  };

  const handleSelectBrand = (item) => {
    var item = item;
    var last_id = 0;
    if (categoryBrands && categoryBrands.length > 0) {
      var index = categoryBrands.length - 1;
      last_id = categoryBrands[index].id;
    }
    var find = categoryBrands.find((element) => {
      return element.id === item.id;
    });
    if (find) {
     var filter =  categoryBrands.filter((e) => e.id != find.id);
      setCategorybrands(filter)
    } else {
      setCategorybrands((categoryBrands) => [
        ...categoryBrands, item
      ]);
    }
  };

  const update =()=>{
    if(load == 0){
    setLoad(1)
    var request = {"category_id": params.id ,"category_brands":categoryBrands}
    post("/api/admin/category-brands/update" , request)
    .then(response=>{
      setLoad(0)
      setErrorMessage(0);
      setShowBrands(0)
    }).catch(error=>{
       setErrorMessage(1)
       setLoad(0)
    })
    }
  }

  useEffect(() => {
    console.log(categoryBrands);
  }, [categoryBrands]);

  const handleShowBrands = ()=>{
     if(showBrands == 0){
       setShowBrands(1)
     }else{
      setShowBrands(0)
     }
  }

  return (
    <>
      <div className="list_parent">
        <Header title={"برندهای دسته بندی"} />
        <div className="category_brands_parent">
          <div className="select_brands">
            {category && (
              <div className="title">
                <span>انتخاب برند برای دسته بندی {category.name}</span>
              </div>
            )}
            <button onClick={() => handleShowBrands()}>انتخاب برند</button>
          </div>
          {categoryBrands && categoryBrands.length > 0
            ? categoryBrands.map((item, index) => (
                <div className="item" key={item.id}>
                  <div className="name">{item.persian_name}</div>
                  <button
                    className="cancel"
                    onClick={() => handleSelectBrand(item)}
                  >
                    &#10006;
                  </button>
                </div>
              ))
            : categoryBrands &&
              categoryBrands.length == 0 && (
                <div className="empty_category_brands">
                  هیچ برندی برای این دسته بندی انتخاب نشده است.
                </div>
              )}
          <button className="submit" onClick={() => update()}>
            ثبت تغییرات
          </button>
        </div>
        {showBrands == 1 && brands && brands.length > 0 && (
          <Brands
            brands={brands}
            categoryBrands={categoryBrands}
            handleSelectBrand={handleSelectBrand}
            handleShowBrands={handleShowBrands}
          />
        )}
      </div>
      {load == 1 && <SpinnerLoading />}
      {error == 1 && <ErrorLoadData func={handleRetry} />}
      {errorMessage == 1 && <ErrorMessgae setError={setError} />}
    </>
  );
}
