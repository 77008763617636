import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import { useNavigate, useParams } from "react-router-dom";
import ScrollManager from "../ScrollManager/ScrollManager";
import ErrorLoadData from "../Error/ErrorLoadData";
import Header from "../Header/Header";
import { Context } from "../Context/Context";
import ErrorMessgae from "../Error/ErrorMessage";
import { useQueryClientCategory } from "./CategoryIndex";
import FileUpload from "../FileUpload/FileUpload";

export default function CategoryEdit({}) {
  const [load, setLoad] = useState(0);
  const [retry, setRetry] = useState(0);
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [errorConnectionLoadData, setErrorConnectionLoadData] = useState(0);
  const [category, setCategory] = useState();
  const [allCategory, setAllCategory] = useState();
  const [ImageNameResponse , setImageNameResponse] = useState()
  const { successAlert, setSuccessAlert } = useContext(Context);

  const name = useRef();
  const categoryId = useRef();
  const params = useParams();
  const [queryClient] = useQueryClientCategory();

  useEffect(() => {
    setLoad(1);
    var request = { id: params.id };
    post("/api/admin/category/edit", request)
      .then((response) => {
        setLoad(0);
        setCategory(response.category);
        setAllCategory(response.all_category);
        setErrorConnectionLoadData(0);
        setErrorConnection(0);
      })
      .catch((error) => {
        setLoad(0);
        setErrorConnectionLoadData(1);
        setErrorConnection(0);
      });

    return () => {
      //cleanup
    };
  }, [retry]);

  function handleLoadData() {
    setRetry(retry + 1);
  }

  function updateCacheOptimize(response) {
    // update cache data react query infinite
    var previousData = queryClient.getQueryData();
    if (previousData) {
      previousData.pages.forEach((page) => {
        page.forEach((record) => {
          if (record.id == params.id) {
            var updated = response.updated;
            record = Object.assign(record, updated);
          }
        });
      });
      queryClient.setQueryData(previousData);
    }
  }

  function handleEditCategory() {
    if (load == 0) {
      setLoad(1);
      var name_category = name.current.value;
      var category_id = categoryId.current.value;
      var request = {
        id: params.id,
        name: name_category,
        category_id: category_id,
        image : ImageNameResponse,
      };
      post("/api/admin/category/update", request)
        .then((response) => {
          if (response[0] == "success") {
            setSuccessAlert(1);
            setLoad(0);
            setErrorConnection(0);
            setErrorValidation();
            updateCacheOptimize(response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            setErrorValidation(error.response.data.errors);
            setErrorConnection(0);
          } else {
            setErrorConnection(1);
          }
          setLoad(0);
          setSuccessAlert(0);
          setErrorConnectionLoadData(0);
        });
    }
  }

  return (
    <div className="form_page">
      <ScrollManager scrollKey="categoryEdit" />
      <div className="form_inner">
        <Header title={"ویرایش دسته بندی"} />

        <p>
          در این صفحه شما می توانید دسته بندی مورد نظر خود را ویرایش نمایید.
        </p>

        <div className="form">

          
          <FileUpload
            id={"error_validation"}
            url={"/api/admin/category/upload"}
            keyRequest={"image"}
            keyResponse={"image"}
            updateFile={category && category.image}
            ImageNameResponse={ImageNameResponse}
            setImageNameResponse={setImageNameResponse}
            placeholder={"تغییر تصویر دسته بندی"}
            setErrorValidation={setErrorValidation}
            errorValidation={errorValidation}
            error={
              errorValidation && errorValidation.image
                ? errorValidation.image
                : null
            }
          />

          <div className="input_parent">
            <label htmlFor="name">نام دسته بندی</label>
            <input
              id="name"
              className="name"
              name="name"
              type="text"
              placeholder="نام دسته بندی"
              ref={name}
              defaultValue={category && category.name}
            />

            {errorValidation && errorValidation.name && (
              <div className="error" id="error_validation">
                <span>{errorValidation.name}</span>
              </div>
            )}
          </div>

          <div className="input_parent">
            <label htmlFor="category_id">انتخاب دسته بندی والد</label>
            <select
              ref={categoryId}
              className="select_box"
              name="category_id"
              id="category_id"
            >
              <option value={0}>دسته بندی اصلی</option>
              {allCategory &&
                allCategory.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    selected={
                      category && item.id == category.category_id && "selected"
                    }
                  >
                    {item.name}
                  </option>
                ))}
            </select>

            {errorValidation && errorValidation.category_id && (
              <div className="error" id="error_validation">
                <span>{errorValidation.category_id}</span>
              </div>
            )}
          </div>

          <div className="submit_parent">
            <button
              className="submit_form"
              onClick={() => handleEditCategory()}
            >
              ویرایش دسته بندی
            </button>
          </div>
        </div>
      </div>

      {load == 1 && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {errorConnectionLoadData == 1 && <ErrorLoadData func={handleLoadData} />}
    </div>
  );
}
