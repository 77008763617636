import { Context } from "../Context/Context"
import React, {
  useState,
  useEffect,
  useContext,
} from "react";
import { post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import ErrorLoadData from "../Error/ErrorLoadData";

export default function AdminInfo(){

    const {isAdmin , setIsAdmin , setAdmin}=useContext(Context)
    const [load , setLoad] = useState(0)
    const [error, setError] = useState(0);
    const [retry  , setRetry] = useState(0)

    useEffect(()=>{
     handleIsAdmin()
    },[retry])

    function handleIsAdmin(){
     if (load == 0 && isAdmin == null) {
       setLoad(1);
       post("/api/admin/admin-info/index")
         .then((response) => {
           setLoad(0);
           setError(0);
           if (response.user && response.user.is_admin == 1) {
             setIsAdmin(true);
             setAdmin(response.user)
           } else{
             setIsAdmin(false);
           }
         })
         .catch((error) => {
           setLoad(0);
           setError(1);
         });
     }
    }

    function handleRetry(){
        setRetry(retry+1)
    }

    return (
      <>
        <div>
          {load == 1 && <SpinnerLoading />}
          {error == 1 && <ErrorLoadData func={handleRetry} />}
        </div>
      </>
    );
}