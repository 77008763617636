

export default function({index , errorValidation}){

    return (
      <>
        {errorValidation && errorValidation["colors." + index + ".number"] && (
          <div className="error" id="error_validation">
            <span>تعداد محصول باید از نوع عدد باشد.</span>
          </div>
        )}
        {errorValidation &&
          errorValidation["colors." + index + ".price_increase"] && (
            <div className="error" id="error_validation">
              <span>افزایش قیمت باید از نوع عدد باشد.</span>
            </div>
          )}
        {errorValidation &&
          (errorValidation["colors." + index + ".color_id"] ||
            errorValidation["colors." + index + ".color_id"]) && (
            <div className="error" id="error_validation">
              <span>حداقل باید یک ویژگی انتخاب شود .</span>
            </div>
          )}
      </>
    );
}