import { useNavigate } from "react-router-dom";
import ColorItem from "./ColorItem";


export default function Colors({
  colors,
  productColors,
  setProductColors,
  handleShowColors,
  selectColor,
  setSelectColor,
}) {
  const navigate = useNavigate();

  return (
    <>
      {colors && (
        <div className="parent_colors">
          <div className="list">
            <div className="close">
              <button onClick={() => handleShowColors()}>&#x2715;</button>
            </div>
            <div className="colors">
              {colors.length > 0 &&
                colors.map((item, index) => (
                  <ColorItem
                    key={index}
                    item={item}
                    productColors={productColors}
                    setProductColors={setProductColors}
                    selectColor={selectColor}
                    setSelectColor={setSelectColor}
                  />
                ))}
              {colors.length <= 0 && (
                <div className="alert_empty_colors">
                  <span>هیچ رنگی ثبت نشده است.</span>
                  <span>
                    برای تعیین رنگ های محصول ابتدا باید از طریق بخش رنگ ها، رنگ
                    ایجاد نمایید.
                  </span>
                  <button onClick={() => navigate("/colors")}>ایجاد رنگ</button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}