import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../../http/http";
import UrlBase from "../../http/UrlHttp";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import { useNavigate } from "react-router-dom";
import ScrollManager from "../../ScrollManager/ScrollManager";
import ErrorLoadData from "../../Error/ErrorLoadData";
import Input from "../../Inputs/Input";
import FileUpload from "../../FileUpload/FileUpload";
import Header from "../../Header/Header";
import { Context } from "../../Context/Context";
import ErrorMessgae from "../../Error/ErrorMessage";
import { useQueryClientProduct } from "../index/Products";
import Number from "./Number";
import CkEditor from "../../Inputs/CkEditor";
import TextArea from "../../Inputs/TextArea";


export default function ProductCreate() {
  const [load, setLoad] = useState(0);
  const [retry, setRetry] = useState(0);
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [errorConnectionLoadData, setErrorConnectionLoadData] = useState(0);
  const [allCategory, setAllCategory] = useState();
  const [brands, setBrands] = useState();
  const [attribute , setAttribute]= useState([{"id":1 ,"color_id":null , "size_id":null, "number":null , "price_increase":0}])
  const [colors , setColors] = useState()
  const [sizes , setSizes] = useState()
  const [description, setDescription] = useState();
  const [ImageNameResponse, setImageNameResponse] = useState();
  const [showInputNumber, setShowInputNumber] = useState(0);
  const { successAlert, setSuccessAlert } = useContext(Context);
  const name = useRef();
  const role = useRef();
  const entesharat = useRef();
  const categoryId = useRef();
  const BrandId = useRef();
  const number = useRef();
  const price = useRef();
  const weight = useRef();
  const metaDescription = useRef();

  const navigate = useNavigate();
  const [queryClient] = useQueryClientProduct();

  useEffect(() => {
    setLoad(1);
    post("/api/admin/product/create")
      .then((response) => {
        if (response[0] == "success") {
          setLoad(0);
          setAllCategory(response.categories);
          setBrands(response.brands);
          setColors(response.colors);
          setSizes(response.sizes);
          setErrorConnectionLoadData(0);
          setErrorConnection(0);
        }
      })
      .catch((error) => {
        setLoad(0);
        setErrorConnectionLoadData(1);
        setErrorConnection(0);
      });

    return () => {
      //cleanup
    };
  }, [retry]);

  function handleLoadData() {
    setRetry(retry + 1);
  }

  function addToDataCachedOptimized(response) {
    const oldData = queryClient.getQueryData();
    if (oldData) {
      oldData.pages[0] = [response.new_product, ...oldData.pages[0]];
      queryClient.setQueryData(oldData);
    }
  }

  function handleCreateProduct() {
    if (load == 0) {
      setLoad(1);
      var _name = name.current.value;
      var _role = role.current.value;
      var _entesharat = entesharat.current.value;
      var _category_id = categoryId.current.value;
      var _brand_id = BrandId.current.value;
      var _weight = weight.current.value;
      var _price = price.current.value;
      var _description = description;
      var _meta_description = metaDescription.current.value;

      var request = {
        name: _name,
        role: _role,
        entesharat:_entesharat,
        image: ImageNameResponse,
        category_id: _category_id,
        brand_id: _brand_id,
        weight: _weight,
        price: _price,
        description: _description,
        meta_description: _meta_description
      };

      if(showInputNumber == 0){
        var _number = number.current.value;
        var _price = price.current.value;
        request = {...request , "number":_number}
        request = { ...request, "price": _price};
      }else{
        request = {...request , "colors": attribute };
      }

      post("/api/admin/product/store", request)
        .then((response) => {
          if (response[0] == "success") {
            setSuccessAlert(1);
            setLoad(0);
            setErrorConnection(0);
            setErrorConnectionLoadData(0);
            setErrorValidation();
            addToDataCachedOptimized(response);
            navigate("/products");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            setErrorValidation(error.response.data.errors);
            setErrorConnection(0);
          } else {
            setErrorConnection(1);
          }
          setLoad(0);
          setSuccessAlert(0);
          setErrorConnectionLoadData(0);
        });
    }
  }

  useEffect(() => {
    if (errorValidation) {
      var error_validation_element =
        document.getElementById("error_validation");
      error_validation_element.scrollIntoView();
      window.scrollBy({ top: -180, behavior: "smooth" });
    }
  }, [errorValidation]);

  return (
    <div className="form_page">
      <ScrollManager scrollKey="productCreate" />
      <div className="form_inner">
        <Header title={"ثبت محصول"} />

        {allCategory && Object.keys(allCategory).length > 0 && (
          <div>
            <p>در این صفحه شما می توانید محصول خود را وارد نمایید.</p>

            <div className="form">
              <Input
                id={"error_validation"}
                type={"text"}
                label={"نام محصول"}
                name={"name"}
                placeholder={"نام محصول"}
                ref={name}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.name
                    ? errorValidation.name
                    : ""
                }
              />
              <Input
                id={"error_validation"}
                type={"text"}
                label={"نقش نویسنده"}
                name={"role"}
                placeholder={"نقش نویسنده"}
                ref={role}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.role
                    ? errorValidation.role
                    : ""
                }
              />
              <TextArea
                id={"error_validation"}
                type={"text"}
                label={"تگ meta description (حد اکثر 300 حرف)"}
                name={"meta_description"}
                placeholder={"تگ meta description"}
                ref={metaDescription}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.meta_description
                    ? errorValidation.meta_description
                    : ""
                }
              />
              <Input
                id={"error_validation"}
                type={"text"}
                label={"نام انتشارات"}
                name={"entesharat"}
                placeholder={"نام انتشارات"}
                ref={entesharat}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.entesharat
                    ? errorValidation.entesharat
                    : ""
                }
              />
              <FileUpload
                id={"error_validation"}
                url={"/api/admin/product/upload-image"}
                keyRequest={"image"}
                keyResponse={"image"}
                ImageNameResponse={ImageNameResponse}
                setImageNameResponse={setImageNameResponse}
                placeholder={"انتخاب تصویر اصلی محصول"}
                setErrorValidation={setErrorValidation}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.image
                    ? errorValidation.image
                    : null
                }
              />

              <div className="input_parent">
                <label htmlFor="category_id">انتخاب دسته بندی محصول</label>
                <select
                  ref={categoryId}
                  className="select_box"
                  name="category_id"
                  id="category_id"
                >
                  <option value="" selected disabled>
                    انتخاب دسته بندی
                  </option>
                  {allCategory &&
                    allCategory.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>

                {errorValidation &&
                  errorValidation.category_id &&
                  errorValidation.category_id.map((item, index) => (
                    <div className="error" id="error_validation" key={index}>
                      <span>{item}</span>
                    </div>
                  ))}
              </div>

              <div className="input_parent">
                <label htmlFor="brand_id">انتخاب برند محصول</label>
                <select
                  ref={BrandId}
                  className="select_box"
                  name="brand_id"
                  id="brand_id"
                >
                  <option value="" selected disabled>
                    انتخاب برند
                  </option>
                  {brands &&
                    brands.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.original_name}
                      </option>
                    ))}
                </select>
              </div>

              <Input
                id={"error_validation"}
                type={"tel"}
                label={"وزن محصول (گرم) "}
                name={"weight"}
                placeholder={"مثال: 1000"}
                ref={weight}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.weight
                    ? errorValidation.weight
                    : ""
                }
              />
              <Input
                id={"error_validation"}
                type={"tel"}
                label={"قیمت محصول (تومان) "}
                name={"price"}
                placeholder={"مثال: 300000"}
                ref={price}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.price
                    ? errorValidation.price
                    : ""
                }
              />

              <Number
                showInputNumber={showInputNumber}
                setShowInputNumber={setShowInputNumber}
                number={number}
                errorValidation={errorValidation}
                colors={colors}
                sizes = {sizes}
                attribute={attribute}
                setAttribute={setAttribute}
              />

              <div className="input_parent">
                <div className="description">
                  <label>توضیحات</label>

                  <div className="ckeditor">
                   <CkEditor
                    onChange={setDescription}
                    rote_upload_image={'api/admin/product/ckeditor'}
                    />
                  </div>

                  {errorValidation &&
                    errorValidation.description &&
                    errorValidation.description.map((item, index) => (
                      <div className="error" id="error_validation" key={index}>
                        <span>{item}</span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="submit_parent">
                <button
                  className="submit_form"
                  onClick={() => handleCreateProduct()}
                >
                  ثبت محصول
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {allCategory && Object.keys(allCategory).length <= 0 && (
        <div className="alert_empty_category">
          <span>هیچ دسته بندی ثبت نشده است.</span>
          <span>برای اضافه نمودن محصول، حداقل به یک دسته بندی نیاز است.</span>
          <button onClick={() => navigate("/category/create")}>
            ثبت دسته بندی
          </button>
        </div>
      )}

      {load == 1 && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {errorConnectionLoadData == 1 && <ErrorLoadData func={handleLoadData} />}
    </div>
  );
}
