import { useEffect, useState, useContext } from "react";
export default function SizeItem({ item, selectSize, setSelectSize }) {
  const [exist, setExsist] = useState(false);
  useEffect(() => {
    if (selectSize) {
      if (item.id == selectSize.id) {
        setExsist(true);
      } else {
        setExsist(false);
      }
    }
  }, [selectSize]);

  function addToProductSizes(item) {
    setSelectSize(item);
    setExsist(true);
  }
  function removeOfProductSizes(){
    setSelectSize();
    setExsist(false);
  }

  return (
    <>
      <div className="item">
        <div className="size">{item.size}</div>
        <div className="add_parent">
          {exist == true ? (
            <button
              className="add added"
              onClick={() => removeOfProductSizes(item)}
            >
              حذف
            </button>
          ) : (
            <button className="add" onClick={() => addToProductSizes(item)}>
              افزودن
            </button>
          )}
        </div>
      </div>
    </>
  );
}
