

export default function Category({parents}){


    return (
      <div className="category_name">
        <span>{parents.name}</span>
      </div>
    );
}