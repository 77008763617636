import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import { post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import ErrorMessgae from "../Error/ErrorMessage";
import OrderDetail from "./OrderDetail";

const OrderItem = React.forwardRef(
  ({ item, index, mobile, queryClient }, ref) => {
     const [load , setLoad]= useState(0)
     const [error, setError] = useState(0);
     const [showDetail , setShowDetail] = useState(0)

     function handleShowDetail(){
       if(showDetail == 0){
         setShowDetail(1)
       }else{
        setShowDetail(0)
       }
    }

    function handleChecked(){
        if(load == 0){
            setLoad(1)
        var request = {"order_id" : item.id}
        post("/api/admin/orders/checked" , request)
        .then(response=>{
              setError(0)
              setLoad(0)
              item.checked = 1;
        }).catch(error=>{
              setError(1);
              setLoad(0);
        })
    }
    }
    const postBody = (
      <>
      <div className="user_item">
        <div className="info">
        <div className="payment_state">
            {item.payment == 1 ? (
              <span className="ok">پرداخت شده</span>
            ) : (
              <span className="no">پرداخت نشده</span>
            )}
          </div>
          <div className="mobile_parent">
            <span className="title">شماره همراه احراز هویت شده: </span>
            <div className="mobile">{item.user.mobile}</div>
          </div>
          <div className="date">
            <span className="title">تاریخ ثبت سفارش: </span>
            <span>{new Date(item.updated_at).toLocaleDateString("fa-IR")}</span>
          </div>
          <div className="price_order">
            <span className="title">مبلغ سفارش: </span>
            <span className="price">
              {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
            <span className="toman">تومان</span>
          </div>
    
          <div className="address_info_parent">
            <div className="address">
              <span className="title">نشانی: </span>
              <span>{item.address.address}</span>
            </div>
            <div className="name_parent">
              <span className="title">نام سفارش دهنده: </span>
              <div className="name">
                <div>{item.address.first_name}</div>
                <div>{item.address.last_name}</div>
              </div>
            </div>
            <div className="name_parent">
              <span className="title">شماره همراه سفارش دهنده: </span>
              <div className="name">
                <div>{item.address.mobile}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="btns">
          {item.checked == 0 ? (
            <button className="no" onClick={() => handleChecked()}>
              بررسی نشده
            </button>
          ) : (
            <button className="ok">بررسی شده</button>
          )}
          <button className="detail_btn" onClick={() => handleShowDetail()}>
            جزییات بیشتر
          </button>
        </div>
        {load == 1 && <SpinnerLoading />}
        {error == 1 && <ErrorMessgae setError={setError} />}
      </div>
              {showDetail == 1 && <OrderDetail item ={item} handleShowDetail={handleShowDetail} />}
      </>
    );

    const content = ref ? (
      <article ref={ref}>{postBody}</article>
    ) : (
      <article>{postBody}</article>
    );

    return content;
  }
);

export default OrderItem;
