import React, { useState , useEffect , useRef , useContext } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import {useNavigate} from "react-router-dom";
import ScrollManager from '../ScrollManager/ScrollManager'
import Input from "../Inputs/Input";
import FileUpload from "../FileUpload/FileUpload";
import Header from "../Header/Header";
import { Context } from '../Context/Context';
import ErrorMessgae from "../Error/ErrorMessage";
import {useQueryClientBrands} from "./Brands";

export default function BrandCreate() {

  const [load , setLoad] = useState(0)
  const [errorValidation , setErrorValidation] = useState()
  const [errorConnection , setErrorConnection] = useState(0)
  const [errorConnectionLoadData , setErrorConnectionLoadData] = useState(0)
  const [ImageNameResponse ,setImageNameResponse] = useState()
  const {successAlert , setSuccessAlert} = useContext(Context);
  const persian_name = useRef()
  const origin_name = useRef()
  
  const navigate = useNavigate()
  const [queryClient] = useQueryClientBrands()

  function addToDataCachedOptimized(response){
     const oldData = queryClient.getQueryData()
     if(oldData){
     oldData.pages[0] = [response.new_brand , ...oldData.pages[0]]
     queryClient.setQueryData(oldData)
     }
   }
  function handleCreateBrand(){
    if(load == 0){
    setLoad(1)
    var _persian_name = persian_name.current.value
    var _original_name = origin_name.current.value
    var request = {"persian_name":_persian_name ,"original_name": _original_name, "logo":ImageNameResponse} 

    post("/api/admin/brand/create" , request)
    .then(response=>{

        if(response[0] == "success"){
         setSuccessAlert(1)
         setLoad(0)
         setErrorConnection(0)
         setErrorConnectionLoadData(0)
         setErrorValidation()
         addToDataCachedOptimized(response)
         navigate("/brands")
        }
        
    })
    .catch(error=>{
        if(error.response && error.response.status == 422){
            setErrorValidation(error.response.data.errors)
            setErrorConnection(0)
        }
        else{
            setErrorConnection(1)
        }
        setLoad(0)
        setSuccessAlert(0)
        setErrorConnectionLoadData(0)
    })
  }
  }

  useEffect(() => {
    if(errorValidation){
    var error_validation_element= document.getElementById('error_validation')
    error_validation_element.scrollIntoView()
    window.scrollBy({top:-180 , behavior: 'smooth'});
    }
   } , [errorValidation])


  return (
    
  <div className="form_page">
    <ScrollManager scrollKey="brand_create" />
    <div className="form_inner">
    <Header title={"ثبت برند"}/>
    <div>
    <p>در این صفحه شما می توانید برند خود را وارد نمایید.</p>
    <div className="form">

    <FileUpload id={"error_validation"} url={"/api/admin/brand/upload-image"} keyRequest={"logo"} keyResponse={"logo"} ImageNameResponse={ImageNameResponse} setImageNameResponse={setImageNameResponse} placeholder={"لوگوی برند"} setErrorValidation={setErrorValidation} errorValidation={errorValidation} error={(errorValidation && errorValidation.logo) ? errorValidation.logo : null}/> 
    <Input id={"error_validation"} type={"text"} label={"نام فارسی برند"} name={"persian_name"} placeholder={"نام فارسی برند"} ref={persian_name}  errorValidation={errorValidation} error={(errorValidation && errorValidation.persian_name) ? errorValidation.persian_name : ""}/>
    <Input id={"error_validation"} type={"text"} label={"نام اصلی برند"} name={"original_name"} placeholder={"نام اصلی برند"} ref={origin_name}  errorValidation={errorValidation} error={(errorValidation && errorValidation.original_name) ? errorValidation.original_name : ""}/>

    <div className="submit_parent">
    <button className="submit_form" onClick={()=>handleCreateBrand()}>ثبت برند</button>
    </div>
    </div>
    </div>
    </div>

    {load == 1 &&
    <SpinnerLoading/>
    }
    {errorConnection == 1 &&
     <ErrorMessgae setError={setErrorConnection}/>
    }

</div>
    
);
}

