import React, { useRef, useState  } from "react";
import ReactDOM from "react-dom";

const TextArea = React.forwardRef(({id , value , type, disable , label ,placeholder , name , errorValidation , error} , ref) => {

  const [changePrice , setChangePrice] = useState(0)
  const [price , setPrice] = useState(value)

  function handleChangeInput(){

    var input = ref.current.value
    
    //just number for input
    if(type == "tel" ){
     var inputConvertToInt = parseInt(input)
     if(inputConvertToInt > 0){
     ref.current.value = inputConvertToInt
     }
    }

    //show price
    if(name == "price"){
      setChangePrice(changePrice+1)
      if(parseInt(input) > 0){
        setPrice(parseInt(input))
      }
      else{
        setPrice(0)
        setChangePrice(0)
      }
    }
    else{
      setChangePrice(0)
    }
  }

  return (
    <div className="input_parent">
    <label htmlFor={name}>{label}</label>
    <textarea defaultValue={value} disabled={disable && (disable == "false" ? "disabled" : null)} id={name} className={name} name={name} type={type} placeholder={placeholder} ref={ref} onChange={()=>handleChangeInput()}/>

    {((changePrice) > 0 || (price > 0) && (name == "price")) &&
    <div className="price_value">
    <span>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
    <span> تومان </span>
    </div>
    }
    

    {errorValidation && error &&
      error.map((item , index)=>(
     <div id={id} className='error' key={index}>
      <span>{item}</span>
      </div>
      ))
    }
    </div>
  )

});

export default TextArea;