import { Context } from "../Context/Context";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UrlBase from "../http/UrlHttp";
export default function ProfileBtn(){

    const {admin}= useContext(Context)
    const navigate = useNavigate()

    return (
      <>
        <button
          className="btn_page profile"
          onClick={() => navigate("/admin-profile")}
        >
          <div className="admin_image">
            {admin && admin.image ? (
              <img src={UrlBase+admin.image} />
            ) : (
              <img src="/account.svg" />
            )}
          </div>
          {admin && admin.name && (
            <div className="admin_name">{admin.name}</div>
          )}
        </button>
      </>
    );
}