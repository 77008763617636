import { useContext, useRef, useState , useEffect } from "react";
import { Context } from "../../Context/Context";
import { post } from "../../http/http";
import ErrorMessgae from "../../Error/ErrorMessage";
import SpinnerLoading from "../../Spinner/SpinnerLoading";

export default function SizeCreate({ sizeTypes, type, setSizes, setShowCreateSize }) {
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [loading, setLoading] = useState(0);
  const size = useRef();
  const { setSuccessAlert } = useContext(Context);

  function handleStore() {
    if (loading == 0) {
      setLoading(1);
      var request = { size: size.current.value, type: type.id };
      post("/api/admin/size/create", request)
        .then((response) => {
          setLoading(0);
          setErrorConnection(0);
          setErrorValidation();
          setSuccessAlert(1);
          setSizes((sizes) => [response, ...sizes]);
          setShowCreateSize(0)
          size.current.value = "";
        })
        .catch((error) => {
          setLoading(0);
          if (error.response && error.response.status != 422) {
            setErrorConnection(1);
          } else {
            setErrorValidation(error.response.data.errors);
            console.log(error.response.data.errors);
          }
        });
    }
  }
  function handleClose() {
    setShowCreateSize(0);
  }
  return (
    <div className="parent_modal">
      <div className="modal">
        {sizeTypes && sizeTypes.length > 0 && (
          <>
            <div className="input_parent">
              {errorValidation && errorValidation.type && (
                <div className="error">
                  <span>{errorValidation.type[0]}</span>
                </div>
              )}
            </div>
            <div className="input_parent">
              <label>سایز جدید برای {type.size}</label>
              <input type="text" placeholder="سایز" ref={size} />
              {errorValidation && errorValidation.size && (
                <div className="error">
                  <span>{errorValidation.size[0]}</span>
                </div>
              )}
            </div>
            <div className="submit_parent">
              <button className="submit" onClick={() => handleStore()}>
                ثبت
              </button>
              <button className="close" onClick={() => handleClose()}>
                انصراف
              </button>
            </div>
            {errorConnection == 1 && (
              <ErrorMessgae setError={setErrorConnection} />
            )}
            {loading == 1 && <SpinnerLoading />}
          </>
        )}
      </div>
    </div>
  );
}
