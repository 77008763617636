import React, { useState , useEffect , useRef , useContext } from "react";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { Context } from '../Context/Context';
import { get, post } from "../http/http";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});
export default function useDelivery(sefareshiBase , pishtazBase ,sefareshiWeight ,pishtazWeight ,statusInput){

  const [load , setLoad] = useState(0)
  const [errorValidation , setErrorValidation] = useState()
  const [errorConnection , setErrorConnection] = useState(0)
  const [errorConnectionLoadData , setErrorConnectionLoadData] = useState(0)
  const [status , setStatus] = useState(0)
  const {successAlert , setSuccessAlert} = useContext(Context)

 const handleCreateDelivery=()=>{

    if(load == 0){
    setLoad(1)
    var _sefareshiBase   =  sefareshiBase.current.value
    var _pishtazBase     =  pishtazBase.current.value
    var _sefareshiWeight =  sefareshiWeight.current.value
    var _pishtazWeight   =  pishtazWeight.current.value

    var request = {"sefareshiBase":_sefareshiBase ,"pishtazBase": _pishtazBase, "sefareshiWeight":_sefareshiWeight , "pishtazWeight":_pishtazWeight} 

    post("/api/admin/delivery/create" , request)
    .then(response=>{
         setSuccessAlert(1)
         setLoad(0)
         setErrorConnection(0)
         setErrorConnectionLoadData(0)
         setErrorValidation()
         queryClient.setQueryData('delivery_repodata', {
          ...response,
          data: {
          ...response,
          '@status': 'true',
          },
          })
        
    })
    .catch(error=>{
        if(error.response && error.response.status == 422){
            setErrorValidation(error.response.data.errors)
            setErrorConnection(0)
        }
        else{
            setErrorConnection(1)
        }
        setLoad(0)
        setSuccessAlert(0)
        setErrorConnectionLoadData(0)
    })
  }
  }

  const handleStatus = ()=>{

   if(load == 0){
    setLoad(1)
    post("/api/admin/delivery/status")
    .then(response=>{
          setLoad(0)
          setErrorConnection(0)
          queryClient.setQueryData('delivery_repodata', {
          ...response,
          data: {
          ...response,
          '@status': 'true',
          },
          })
    })
    .catch(error=>{
         setLoad(0)
         setErrorConnection(1)
    })
    }
  }

    useEffect(() => {
    if(errorValidation){
    var error_validation_element= document.getElementById('error_validation')
    error_validation_element.scrollIntoView()
    window.scrollBy({top:-180 , behavior: 'smooth'});
    console.log(errorValidation)
    }
   } , [errorValidation])

  const handleRefresh = ()=>{
    queryClient.resetQueries("delivery_repodata")
  }

  return [queryClient,load , setLoad ,errorValidation , setErrorValidation ,errorConnection , setErrorConnection ,errorConnectionLoadData , setErrorConnectionLoadData ,status , setStatus  , handleCreateDelivery , handleStatus , handleRefresh];
}