import axios from "axios";
import UrlBase from "./UrlHttp";

axios.defaults.baseURL = UrlBase; //url
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
//axios.defaults.headers.common["Accept"] = "application/json";

export function get(url, config) {
  return axios.get(url, config).then((resposne) => resposne.data);
}

export function post(url, data, config) {
  return axios.post(url, data, config).then((resposne) => resposne.data);
}

