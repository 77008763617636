import { useState } from "react";
import SizeEdit from "./SizeEdit";

export default function SizeItem({ item, types, setTypes }) {
  const [showEdit, setShowEdit] = useState(0);
  const [size, setSize] = useState(item);
  function handleShowEdit() {
    if (showEdit == 0) {
      setShowEdit(1);
    } else {
      setShowEdit(0);
    }
  }
  return (
    <>
      {size && (
        <div className="item_size">
          <div className="size_name_parent">
            <label> سایز : </label>
            <span className="name">{size.size}</span>
          </div>
          <div className="btn_parent">
            <button className="btn_update" onClick={() => handleShowEdit()}>
              ویرایش
            </button>
          </div>
          {showEdit == 1 && (
            <SizeEdit
              size={size}
              setSize={setSize}
              types={types}
              setTypes={setTypes}
              setShowEdit={setShowEdit}
            />
          )}
        </div>
      )}
    </>
  );
}
