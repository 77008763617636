
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";


export default function DeleteAlert({item ,typeDelete ,url, request ,queryClient,queryClientDiscountProductsList, title ,buttonTxt, description, showDeleteModal, setShowDeleteModal , deleted, setDeleted , setError}) {

  const [load, setLoad] = useState(0);

  function updateDataOptimize(response){
     // update cache data react query infinite
      var previousData = queryClient.getQueryData()
      previousData.pages.forEach(page => {
       page.forEach(record => {
         if(record.id == item.id){
          if(response == "delete"){
             record.deleted_at = "delete"
          }else{
             record.deleted_at = null
          }    
         }
       });
      });
      queryClient.setQueryData(previousData)
  }

  function updateListDiscountProduct(response){
       if(queryClientDiscountProductsList){
       const discountProductList = queryClientDiscountProductsList.getQueryData()
       if(discountProductList){
       discountProductList["pages"].forEach(page => {
         page.forEach(record => {
           if(item.discount && record.id == item.discount.id){
             if(response == "delete"){
                record.deleted_at = "deleted"
             }else{
                record.deleted_at = null
             }
           }
         });
       });
       queryClientDiscountProductsList.setQueryData(discountProductList)
     }
    }
  }

  function handleDelete() {
    if (load == 0) {
      setLoad(1);
      var req = request;
      var url_api = url
      post(url_api, req)
        .then((response) => {
          if (response) {
            setDeleted(1);
            updateDataOptimize(response)
            updateListDiscountProduct(response)
          }
          setLoad(0);
          setError(0);
          setShowDeleteModal(0);
        })
        .catch((error) => {
          setLoad(0);
          setError(1);
          setShowDeleteModal(0);
        });
    }
  }

  return (
    <div>
      <div className="delete_modal">
        <div className="inner_delete_modal">
          <div className="delete_close_modal">
            <span onClick={() => setShowDeleteModal(0)}>&#215;</span>
          </div>
          <span className="title">{title}</span>
          <span className="text">{description}</span>
          <div className="btns">
            <div className="cansel">
              <div onClick={() => setShowDeleteModal(0)}>انصراف</div>
            </div>
            <div className="ok">
              <div onClick={() => handleDelete()}>{buttonTxt}</div>
            </div>
          </div>
        </div>
      </div>

      {load == 1 &&
        <SpinnerLoading/>
      }
    </div>
  );
}
