import React, { useState, useEffect, useRef } from "react";

export default function ValueItem({
  item,
  totalChecked,
  setTotalChecked,
  propertyProduct,
}) {

  const [checked, setChecked] = useState();
  const [showCustomValue, setShowCustomValue] = useState(0);
  const [custom , setCustom] = useState()
  const customRef = useRef(null);

  useEffect(() => {
    if (propertyProduct) {
      var property = propertyProduct.find((element) => {
        return element.property_id === item.id;
      });
      if (property) {
        setCustom(property.custom);
        setChecked(item);
        addToTotalChecked(property.custom);
      }
    }
  }, []);

  function addToTotalChecked(cusom_value) {
    setTotalChecked((totalChecked) => [
      ...totalChecked,
      { id: item.id, property_id: item.property_id, custom: cusom_value },
    ]);
  }
  function removeOfTotalChecked() {
    var total_checked_filtered = totalChecked.filter(
      (el) => el.id != item.id
    );
    setTotalChecked(total_checked_filtered);
  }

  function handleChecked() {
    if (checked) {
        setChecked();
        removeOfTotalChecked();
    } 
    else {
      setChecked(item);
      addToTotalChecked(custom);
    }
  }

  const handleCustomValue = () => {
    var custom_value = customRef.current.value;
    custom_value = custom_value == "" ?  null : custom_value;
    var total_checked = totalChecked;
    total_checked.forEach((element) => {
      if(element.id == item.id){
        element.custom = custom_value;
      }
    });
    setTotalChecked(total_checked);
    setCustom(custom_value);
    setShowCustomValue(0);
  }

  function handleShowCustomValue() {
    if (showCustomValue == 0) {
      setShowCustomValue(1);
    } else {
      setShowCustomValue(0);
    }
  }

  return (
    <>
      <div className="value_item">
        <div className="check_box">
          <div
            className={checked && item.id == checked.id ? "check" : "uncheck"}
            onClick={() => handleChecked()}
          ></div>
        </div>
        <div className="label_parent">
          <label className="label" onClick={() => handleChecked()}>
            {item.name}
          </label>
        </div>

        {checked && (
          <button
            className="custom_value_btn"
            onClick={() => handleShowCustomValue()}
          >
            مقدار دقیق
            <span className="custom">{custom}</span>
          </button>
        )}

        {showCustomValue == 1 && (
          <div className="custom_value_parent">
            <div className="custom_value">
              <span className="description">
                وارد کردن مقدار دقیق ویژگی ضروری نبوده و برای زمانی است که ویژگی
                ها به صورت بازه ای هستند. به عنوان مثال صفحه نمایش موبایل اگر به
                صورت یک بازه 4 تا 5 اینچ باشد باید یک مقدار دقیق از بین آن ها
                انتخاب نماییم.
              </span>
              <input
                className="input"
                defaultValue={custom}
                placeholder="مقدار دقیق ویژگی"
                ref={customRef}
              />
              <button className="submit" onClick={() => handleCustomValue()}>
                تأیید
              </button>
              <button className="close" onClick={() => handleShowCustomValue()}>
                انصراف
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
