
import { post } from "../http/http"
import React, { useState , useEffect} from "react";
import { useParams } from "react-router-dom";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import Header from "../Header/Header";
import ErrorLoadData from "../Error/ErrorLoadData";
import PropertyItem from "./PropertyItem";
import ErrorMessgae from "../Error/ErrorMessage";
import PropertyCreate from "./PropertyCreate";
export default function Property(){

    const params = useParams()
    const [load , setLoad] = useState(0)
    const [properties , setProperties ] = useState()
    const [category , setCategory] = useState()
    const [error , setError] = useState(0)
    const [errorMessage , setErrorMessage] = useState(0)
    const [retry , setRetry] = useState(0)
    const [showCreate, setShowCreate] = useState(0);


    useEffect(()=>{
        if(load==0){
            setLoad(1)
            var request ={category_id:params.id}
            post("/api/admin/property/index" , request)
            .then(response=>{
              setProperties(response.properties)
              setCategory(response.category)
              setLoad(0)
              setError(0)
            }).catch(error=>{
               setError(1)
               setLoad(0)
            })
        }

    },[retry])


    function handleRetry(){
        setRetry(retry+1)
    }

      function handleShowCreate() {
        if (showCreate == 0) {
          setShowCreate(1);
        } else {
          setShowCreate(0);
        }
      }

    return (
      <>
        <div className="list_parent">
          <div className="properties">
            <Header title={"لیست ویژگی های دسته بندی"} />

            {category && (
              <div className="category_name">
                <span>ویژگی های دسته بندی </span>
                <span>{category.name}</span>
              </div>
            )}
            <button
              className="create_property_btn"
              onClick={() => handleShowCreate()}
            >
              افزودن ویژگی جدید
            </button>
            <div className="content">
              {properties &&
                properties.map((item) => (
                  <PropertyItem
                    key={item.id}
                    item={item}
                    properties={properties}
                    setProperties={setProperties}
                    load={load}
                    setLoad={setLoad}
                    setErrorMessage={setErrorMessage}
                  />
                ))}
            </div>
            {showCreate == 1 && (
              <PropertyCreate
                setProperties={setProperties}
                setShowCreate={setShowCreate}
                typeCreate={"keys"}
              />
            )}
          </div>
        </div>
        {load == 1 && <SpinnerLoading />}
        {errorMessage == 1 && <ErrorMessgae setError={setErrorMessage} />}
        {error == 1 && <ErrorLoadData func={handleRetry} />}
      </>
    );
}