import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import UrlBase from '../http/UrlHttp';
import axios from 'axios';


export default function CkEditor({data , onChange ,rote_upload_image}) {


  const api_url = UrlBase;
  const upload_route_url = rote_upload_image
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();

          loader.file.then((file) => {
            body.append("uploadImg", file);

            axios
              .post(`${api_url}${upload_route_url}`, body, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })

              .then((response) => {
                // handle the response

                console.log("my res", response.data.url);

                resolve({ default: `${response.data.url}` });
              })

              .catch((error) => {
                // handle errors

                console.log(error);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new uploadAdapter(loader);
    };
  }




  return (
    <>
      <CKEditor
        editor={Editor}
        data={data == null || data == undefined ? "" : data}
        config={{
          extraPlugins: [uploadPlugin], 
          language: 'fa',

          mediaEmbed: {
            extraProviders: [
              {
                  name: 'video',
                  url: UrlBase
              }
          ]
          }


        }}
        
        
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
    </>
  );
}
