import { isEmpty } from "lodash";
import React, { useState , useEffect , useRef } from "react";
import { useMutation } from "react-query";
import { get , post } from "../../http/http";
import { useQueryClientCommentShow } from "../Show/CommentLogic";

export default function Logic(item , queryClient){
  const [load , setLoad] = useState(0)
  const [errorConnection , setErrorConnection] = useState(0)
  const [queryClientCommentShow] = useQueryClientCommentShow()
  const [isAdmin , setIsAdmin] = useState()

  useEffect(()=>{
     if(item.user == null || item.user.is_admin == 0){
       setIsAdmin(0)
     }else{
       setIsAdmin(1)
     }
  },[])
  
 function updateDataOptimize(response){
    // update cache data react query infinite
     var previousData = queryClient.getQueryData(['comments_repoData'])
     previousData.pages.forEach(page => {
      page.forEach(record => {
        if(record.id == item.id){
          record.status = response.status
        }
      });
     });
     queryClient.setQueryData(['comments_repoData',previousData])
     //////update comment show
     var comment = queryClientCommentShow.getQueryData('comment_repodata'+item.id)
     console.log(comment)
     if(comment){
        comment.status = response.status
        queryClientCommentShow.setQueryData(['comment_repodata'+item.id,comment])
     }
 }

 function useVerification(){

   if(load == 0){
    setLoad(1)
    var request = {"id":item.id}
    post("/api/admin/comment/status" , request)
    .then(response=>{
          setLoad(0)
          setErrorConnection(0)
          updateDataOptimize(response)
    })
    .catch(error=>{
         setLoad(0)
         setErrorConnection(1)
    })
    }
  }
    return ([load ,isAdmin ,errorConnection, setErrorConnection ,useVerification])
}
