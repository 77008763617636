import { post } from "../http/http";
import React, { useState, useRef } from "react";
import PropertyEdit from "./PropertyEdit";
import Values from "./Values/Values";
import PropertyDelete from "./PropertyDelete";

export default function PropertyItem({
  item,
  properties,
  setProperties,
  load,
  setLoad,
  setErrorMessage,
}) {
  const [showEditModal , setShowEditModal] = useState(0)
  const [showValues , setShowValues]=useState(0)
  
  function handleEdit(){
    if(showEditModal == 0){
      setShowEditModal(1);
    }else{
      setShowEditModal(0);
    }
  }

  function handleShowValuesProperty(){
    if(showValues == 0){
        setShowValues(1)
    }else{
        setShowValues(0)
    }
  }

  return (
    <>
      <div className="item" key={item.id}>
        <span>{item.name}</span>

        <PropertyDelete
          item={item}
          properties={properties}
          setProperties={setProperties}
          load={load}
          setLoad={setLoad}
          setErrorMessage={setErrorMessage}
          typeDelete={"key"}
        />
        <button className="btn_item" onClick={() => handleEdit()}>
          ویرایش
        </button>

        <button className="btn_item" onClick={() => handleShowValuesProperty()}>
          موارد ویژگی
        </button>

        {showValues == 1 && (
          <Values
            item={item}
            properties={properties}
            setProperties={setProperties}
            load={load}
            setLoad={setLoad}
            setErrorMessage={setErrorMessage}
            setShowEditModal={setShowEditModal}
          />
        )}
        {showEditModal == 1 && (
          <PropertyEdit
            item={item}
            properties={properties}
            setProperties={setProperties}
            load={load}
            setLoad={setLoad}
            setErrorMessage={setErrorMessage}
            setShowEditModal={setShowEditModal}
          />
        )}
      </div>
    </>
  );
}