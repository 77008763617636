import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import ScrollManager from "../ScrollManager/ScrollManager";
import ErrorLoadData from "../Error/ErrorLoadData";
import Input from "../Inputs/Input";
import FileUpload from "../FileUpload/FileUpload";
import Header from "../Header/Header";
import ErrorMessgae from "../Error/ErrorMessage";
import {
  QueryClientProvider,
  useQuery,
} from "react-query";

import useSettingsWebsite, { queryClientSettings } from "./useSettingsWebsite";

export default function SettingWebsite() {
  const[queryClient] = queryClientSettings()
  return (
    <QueryClientProvider client={queryClient}>
      <DataFetch />
    </QueryClientProvider>
  );
}

function DataFetch() {
  const [load, setLoad] = useState(0);
  const [errorValidation, setErrorValidation] = useState();
  const [errorConnection, setErrorConnection] = useState(0);
  const [ImageNameResponse, setImageNameResponse] = useState();
  const titleRef = useRef();
  const metaDescriptionRef = useRef();

  const { isLoading, error, isError, data, isFetching } = useQuery(
    "settings_website_repodata",
    () => post("/api/admin/settings/index")
  )

  const [handleCreateProduct, handleRetryLoadData] = useSettingsWebsite(
    titleRef,
    metaDescriptionRef,
    load,
    setLoad,
    errorValidation,
    setErrorValidation,
    errorConnection,
    setErrorConnection,
    ImageNameResponse,
    setImageNameResponse
  );

  return (
    <div className="form_page">
      <ScrollManager scrollKey="settings-website" />
      <div className="form_inner">
        <Header title={"تنظیمات سایت"} />
        {data && (
          <div>
            <div className="form">
              <FileUpload
                id={"error_validation"}
                url={"/api/admin/settings/upload"}
                keyRequest={"image"}
                keyResponse={"image"}
                updateFile={data.image}
                ImageNameResponse={ImageNameResponse}
                setImageNameResponse={setImageNameResponse}
                placeholder={"انتخاب لوگوی سایت"}
                setErrorValidation={setErrorValidation}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.image
                    ? errorValidation.image
                    : null
                }
              />

              <Input
                id={"error_validation"}
                value={data.title}
                type={"text"}
                label={"عنوان صفحه اصلی سایت"}
                name={"title"}
                placeholder={"عنوان سایت"}
                ref={titleRef}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.title
                    ? errorValidation.title
                    : ""
                }
              />

              <Input
                id={"error_validation"}
                value={data.meta_description}
                type={"text"}
                label={"توضیحات صفحه اصلی سایت"}
                name={"meta_description"}
                placeholder={"توضیحات"}
                ref={metaDescriptionRef}
                errorValidation={errorValidation}
                error={
                  errorValidation && errorValidation.meta_description
                    ? errorValidation.meta_description
                    : ""
                }
              />

              <div className="submit_parent">
                <button
                  className="submit_form"
                  onClick={() => handleCreateProduct()}
                >
                  ثبت محصول
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {load == 1 && <SpinnerLoading />}
      {(isLoading || isFetching) && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {isError && <ErrorLoadData func={handleRetryLoadData} />}
    </div>
  );
}
