import React, { useState , useContext } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { Context } from "../Context/Context";
import LogOutBtn from "../LogOut/LogOutBtn";
import ProfileBtn from "../Profile/ProfileBtn";

export default function Menu({}) {
  const navigate = useNavigate();
  const {admin , menu , setMenu} = useContext(Context)
  function handleCloseMenu(){
    setMenu(0)
  }
  function handleNavigate(path){
    navigate(path)
    setMenu(0)
  }
  return (
    <>
    <div className={menu == 1 ? "background_menu" : "background_manu background_menu_hide"}></div>
    <div className={menu == 1 ? "menu_sidebar_app" : "menu_sidebar_app menu_sidebar_app_hide"}>
        <div className="close_menu">
        <button onClick={()=>handleCloseMenu()}>
          <img src="/back.svg"/>
        </button>
        </div>
       <ProfileBtn/>
      <button className="btn_page" onClick={() => handleNavigate("/category")}>
        دسته بندی ها
      </button>
      <button className="btn_page" onClick={() => handleNavigate("/products")}>
        محصولات
      </button>
      <button className="btn_page" onClick={() => handleNavigate("/biography")}>
         زندگی نامه
      </button>
      <button className="btn_page" onClick={() => handleNavigate("/brands")}>
        برندها
      </button>
      <button className="btn_page" onClick={() => handleNavigate("/delivery")}>
        هزینه ارسال
      </button>
      <button
        className="btn_page"
        onClick={() => handleNavigate("/general-discount")}
      >
        تخفیف عمومی
      </button>

      <button className="btn_page" onClick={() => handleNavigate("/comments")}>
        نظرات کاربران
      </button>
      <button className="btn_page" onClick={() => handleNavigate("/setting-website")}>
        تنظیمات سایت
      </button>
      <button className="btn_page" onClick={() => handleNavigate("/users")}>
        کاربران
      </button>
      <button className="btn_page" onClick={() => handleNavigate("/orders")}>
        سفارشات
      </button>
      <LogOutBtn />
    </div>
    
    </>
  );
}
