import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import UrlBase from "../http/UrlHttp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function OldSlideItem({
  item,
  data,
  imagesNameUploaded,
  setImagesNameUploaded,
}) {
  const [previewImage, setPreviewImage] = useState(1);

  function handleDeleteImage() {
    setImagesNameUploaded((current) =>
      current.filter((element) => {
        return element.image !== item.image;
      })
    );
    setPreviewImage(0);
  }

  function handleUrlChange(e){
    var array = imagesNameUploaded
    array.forEach(element => {
        if(element.image == item.image){
            element.url = e
        }
    });
    setImagesNameUploaded(array);
  }

  return (
    <div>
      {previewImage == 1 && (
        <div className="slide_parent">
          <div className="image_parent">
            <LazyLoadImage
              //    alt={item.name}
              effect="blur"
              src={UrlBase + item.image}
              className="image_product_uploaded"
            />
            <div className="delete_image_btn">
              <img
                src="/trash_svg.svg"
                className="delete_icon_image"
                onClick={() => handleDeleteImage()}
              />
            </div>
          </div>
          <div className="input_url_slide">
            <label>آدرس اسلاید</label>
            <input type="text" placeholder="url" defaultValue={item.url} onChange={(e)=>handleUrlChange(e.target.value)}/>
          </div>
        </div>
      )}
    </div>
  );
}
