import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import { get, post } from "../http/http";
import UrlBase from "../http/UrlHttp";
import { Context } from "../Context/Context";
import {
  QueryClient,
} from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

export function queryClientSettings() {
  return [queryClient];
}


export default function useSettingsWebsite(
  titleRef,
  metaDescriptionRef,
  load,
  setLoad,
  errorValidation,
  setErrorValidation,
  errorConnection,
  setErrorConnection,
  ImageNameResponse,
  setImageNameResponse
) {
  const { successAlert, setSuccessAlert } = useContext(Context);

  function handleCreateProduct() {
    if (load == 0) {
      setLoad(1);
      var _title = titleRef.current.value;
      var _description = metaDescriptionRef.current.value;
      var request = {
        image: ImageNameResponse,
        title: _title,
        meta_description: _description,
      };
      post("/api/admin/settings/create", request)
        .then((response) => {
          if (response) {
            setSuccessAlert(1);
            setLoad(0);
            UpdateCacheData(response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            setErrorValidation(error.response.data.errors);
            setErrorConnection(0);
          } else {
            setErrorConnection(1);
          }
          setLoad(0);
          setSuccessAlert(0);
        });
    }
  }

  function UpdateCacheData(response) {
    queryClient.setQueryData("settings_website_repodata", response);
  }

    function handleRetryLoadData() {
      queryClient.resetQueries("settings_website_repodata");
    }

  return [handleCreateProduct, handleRetryLoadData];
}

