import { useEffect, useState } from "react";
import SizesList from "../../ProductSizes/SizesList";
import Colors from "../ColorsList/Colors";

export default function ProductColorItem({
  item,
  index,
  attributes,
  setAttributes,
  errorValidation,
  handleShowColors,
  colors,
  sizes,
}) {
  
  const [price, setPrice] = useState(0);
  const [number, setNumber] = useState(0);
  const [showColors, setShowColors] = useState(0);
  const [showSizes, setShowSizes] = useState(0);
  const [selectColor , setSelectColor]= useState(item.color)
  const [selectSize, setSelectSize] = useState(item.size);

  function handleDelete(id) {
    let colors_filtered = attributes.filter((item) => item.id !== id);
    setAttributes(colors_filtered);
  }

  useEffect(() => {
     var _price = 0;
     var _number = 0;
     if(item.price > 0){
        _price = item.price;
        setPrice(item.price)
     }
     if(item.number > 0){
        _number = item.number;
        setNumber(item.number);
     }
     setAttributes((attributes) => [
       ...attributes,
       { id: item.id, price_increase: _price, number: _number ,color_id:item.color_id , size_id:item.size_id},
     ]);
  }, []);

  useEffect(()=>{
      var attribute_updated = attributes
      attribute_updated.forEach((element) => {
        if (element.id == item.id) {
          if(selectColor){
            element.color_id = selectColor.id;
          }else{
             element.color_id = null;
          }
          if(selectSize){
            element.size_id = selectSize.id;
          }else{
            element.size_id = null;
          }
        }
      });
      setAttributes(attribute_updated);
  },[selectColor , selectSize])

  function changeProductColor(e ,type , setState){
      var value = 0;
      if (e > 0) {
        value = parseInt(e);
      } else if (e == "") {
        value = 0;
      }
      else if(e == null){
        value = 0;
      } else {
        value = e;
      }
      setState(value);
      const update = attributes;
      update.forEach((element) => {
        if (element.id == item.id) {
          element[type] = value;
        }
      });
      setAttributes(update);
  }

    function handleShowColors() {
      if (showColors == 0) {
        setShowColors(1);
      } else {
        setShowColors(0);
      }
    }

    function handleShowSizes() {
      if (showSizes == 0) {
        setShowSizes(1);
      } else {
        setShowSizes(0);
      }
    }

  return (
    <>
      <div className="item">
        {showColors == 1 && (
          <Colors
            colors={colors}
            attributes={attributes}
            setAttributes={setAttributes}
            selectColor={selectColor}
            setSelectColor={setSelectColor}
            handleShowColors={handleShowColors}
          />
        )}
        {showSizes == 1 && (
          <SizesList
            sizes={sizes}
            handleShowSizes={handleShowSizes}
            selectSize={selectSize}
            setSelectSize={setSelectSize}
          />
        )}
        <button className="delete" onClick={() => handleDelete(item.id)}>
          &#10006;
        </button>

        <div className="show_colors">
          <button onClick={() => handleShowColors()}>انتخاب رنگ</button>
        </div>
        <div className="show_colors">
          <button onClick={() => handleShowSizes()}>انتخاب سایز</button>
        </div>

        <div className="attribute">
          {selectColor && (
            <div className="color_product_parent">
              <div
                className="color_product"
                style={{ background: selectColor.color }}
              ></div>
            </div>
          )}

          {selectSize && (
            <div className="size">
              <span>سایز : </span>
              <span className="amount">{selectSize.size}</span>
            </div>
          )}
        </div>
        <div className="name"> {item.name} </div>
        <div className="number">
          <label>تعداد : </label>
          <input
            type="tel"
            placeholder="تعداد"
            defaultValue={item.number}
            onKeyUp={(e) =>
              changeProductColor(e.target.value, "number", setNumber)
            }
          />
        </div>
        <div className="price">
          <label>افزایش قیمت : </label>
          <input
            type="tel"
            placeholder="افزایش قیمت"
            defaultValue={
              item.price_increase &&
              item.price_increase
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            onKeyUp={(e) =>
              changeProductColor(e.target.value, "price_increase", setPrice)
            }
          />
        </div>
      </div>
      {errorValidation && errorValidation["colors." + index + ".number"] && (
        <div className="error" id="error_validation">
          <span>تعداد محصول باید از نوع عدد باشد.</span>
        </div>
      )}
      {errorValidation &&
        errorValidation["colors." + index + ".price_increase"] && (
          <div className="error" id="error_validation">
            <span>افزایش قیمت باید از نوع عدد باشد.</span>
          </div>
        )}
      {errorValidation &&
       ((errorValidation["colors." + index + ".color_id"])
        ||
        (errorValidation["colors." + index + ".color_id"]))
        && 
          <div className="error" id="error_validation">
            <span>حداقل باید یک ویژگی انتخاب شود .</span>
          </div>
        }
    </>
  );
}