import React, { useState, useEffect } from "react";

export default function BrandItem({ item, handleSelectBrand, categoryBrands }) {
  const [added, setAdded] = useState(0);
  useEffect(() => {
    var find = categoryBrands.find((element) => {
      return element.id === item.id;
    });
    if (find) {
      setAdded(1);
    } else {
      setAdded(0);
    }
  }, [categoryBrands]);

  return (
    <>
      <div className="item">
        <div className="name">{item.persian_name}</div>
        <button className={added == 1 ?"added" :"add"} onClick={() => handleSelectBrand(item)}>
          {added == 1 ? "حذف" : "افزودن"}
        </button>
      </div>
    </>
  );
}