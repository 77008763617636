import { post } from "../../http/http"
import { useState, useEffect ,useRef } from "react";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
export default function Edit({ item, setShowEdit, typeSize, setTypeSize, setErrorMessage }) {
  const type = useRef();
  const [load, setLoad] = useState(0);
  const handleUpdate = () => {
    if (load == 0) {
      setLoad(1);
      var request = { id: item.id, size: type.current.value };
      post("/api/admin/size/update-type-size", request)
        .then((response) => {
          var t_sizev = type.current.value;
          setTypeSize(t_sizev);
          setShowEdit(0);
          setLoad(0);
          setErrorMessage(0)
        })
        .catch((error) => {
          setLoad(0);
          setErrorMessage(1)
        });
    }
  };
    function handleClose() {
      setShowEdit(0);
    }
  return (
    <>
      <div className="parent_modal">
        <di className="modal">
          <div className="input_parent">
            <label htmlFor="input">نوع سایز </label>
            <input type="text" defaultValue={typeSize} ref={type}  placeholder="به عنوان مثال متری"/>
          </div>
          <button className="submit" onClick={() => handleUpdate()}>ثبت تغییرات</button>
          <button className="close" onClick={() => handleClose()}>
            انصراف
          </button>
        </di>
        {load == 1 && <SpinnerLoading />}
      </div>
    </>
  );
}