import { QueryClientProvider  , QueryClient , useQuery} from "react-query";
import ErrorLoadData from "../../Error/ErrorLoadData";
import { post } from "../../http/http";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        keepPreviousData: true,
        staleTime: Infinity,
        cacheTime: Infinity,
      },
    },
  });
 
export default function GeneralDiscount({ setGeneralDiscountLoaded }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Data setGeneralDiscountLoaded={setGeneralDiscountLoaded} />
    </QueryClientProvider>
  );
}

function Data({ setGeneralDiscountLoaded }) {
  function handleRefresh() {
    queryClient.resetQueries("general_discount_repodata_products");
  }
  const { isLoading, error, isError, data, isFetching } = useQuery(
    "general_discount_repodata_products",
    () => post("/api/admin/product/general_discount")
  );

  useEffect(() => {
    if (data) {
      setGeneralDiscountLoaded(1);
    }
  }, [data]);
  return (
    <>
      {isLoading && isFetching && <SpinnerLoading />}
      {isError && <ErrorLoadData func={handleRefresh} />}
    </>
  );
}

export function useGeneralDiscount(){
    return([queryClient])
}