import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import DeleteAlert from "../../Alerts/DeleteAlert";
import UrlBase from "../../http/UrlHttp";
import { get, post } from "../../http/http";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ErrorMessgae from "../../Error/ErrorMessage";
import { useQueryClientDiscountProductsList } from "../../Discount/ProductDiscount/DiscountList/ProductDiscuntList";
import { useGeneralDiscount } from "./GeneralDiscount";

const ProductItem = React.forwardRef(
  ({ item, index, name , queryClient }, ref) => {
    const [showDeleteModal, setShowDeleteModal] = useState(0);
    const [deleted, setDeleted] = useState(0);
    const navigate = useNavigate();
    const [error, setError] = useState(0);
    const [queryClientDiscountProductsList] = useQueryClientDiscountProductsList();
    const [queryGeneralDiscount] = useGeneralDiscount()
    const [percentProductDiscount, setPercentProductDiscount] = useState();
    const [percentGeneralDiscount, setPercentGeneralDiscount] = useState();
    const [priceDiscount, setPriceDiscount] = useState();
    const [productColors , setProductColors] = useState(item.attribute);
    const [price, setPrice] = useState(item.price)
    const [number , setNumber]= useState(0)
    const [typeNumber, setTypeNumber] = useState(0);
    const [selectColor , setSelectColor] = useState()

    function handleDelete() {
      setShowDeleteModal(1);
    }

    useEffect(() => {
      handleTypeNumber();
      const price_product = handlePrice();
      handleDiscountProduct(price_product);
    }, []);

    function handleDiscountProduct(price_product) {
       
      const generalDiscountCached = queryGeneralDiscount.getQueryData();
      const generalDiscount = generalDiscountCached.general_discount;
      const nowDate = generalDiscountCached.nowDate;
      const private_discount = item.discount;
      if (
        private_discount &&
        private_discount != null &&
        private_discount.percent_discount > 0 &&
        private_discount.status == 1  &&
        private_discount.finish_discount > nowDate
      ) {

       handleCalculation(private_discount, setPercentProductDiscount , price_product);

      } else if (
        generalDiscount &&
        generalDiscount != null &&
        generalDiscount.status == 1 &&
        generalDiscount.percent_discount > 0 &&
        generalDiscount.finish_discount > nowDate &&
        generalDiscount.start_discount <= nowDate
      ) {

        handleCalculation(generalDiscount, setPercentGeneralDiscount , price_product);
      }
    }

    function handleCalculation(discount, setPercentDiscount, price_product) {
      var price_final =
        price_product * ((100 - discount.percent_discount) / 100);
      var price_less = price_product - price_final;
      var percent_product =
        ((price_product - price_final.toFixed()) / price_product) * 100;
      if ((discount.maximum_discount == 0) || (discount.maximum_discount == null)) {
        setPriceDiscount(price_final.toFixed());
        setPercentDiscount(percent_product.toFixed());
      } else if (discount.maximum_discount < price_less) {
        price_final = price_product - discount.maximum_discount;
        setPriceDiscount(price_final.toFixed());
        percent_product =
          ((price_product - price_final.toFixed()) / price_product) * 100;
        setPercentDiscount(percent_product.toFixed());
      } else {
        setPriceDiscount(price_final.toFixed());
        setPercentDiscount(percent_product.toFixed());
      }
    }

      function handleTypeNumber() {
        if (productColors) {
          productColors.forEach((element) => {
            if ((element.status == 1) && (element.color || element.size)) {
              setTypeNumber(1);
            }
          });
        }
      }

      function handlePrice(){

       var price_product = item.price;
       if (productColors && productColors.length > 0) {
        let stop = false;
         productColors.forEach((element) => {
           if (stop == false && element.status == 1 && element.number > 0 && (element.color || element.size)) {
             setPrice(price_product + element.price_increase);
             setNumber(element.number)
             stop = true;
             price_product = item.price + element.price_increase;
             if (element.color != null || element.size != null) {
               setSelectColor(element.id);
             }
           }
           if(element.status == 1 && element.color_id == null && element.size_id == null){
             setPrice(item.price)
             setNumber(element.number)
           }
         });
       }
       return price_product;
      }

      function handleSelectColor(ProductColor_id , price_increase){
          setSelectColor(ProductColor_id);
          setPrice(item.price+price_increase);
          handleDiscountProduct(item.price + price_increase); 
      }

    const postBody = (
      <div className={item.deleted_at == null ? "item" : "item item_deleted"}>
        <div className="info_parent">
          <div className="image_parent">
            <LazyLoadImage
              alt={item.name}
              effect="blur"
              src={UrlBase + item.image}
            />
          </div>

          <div className="info">
            <div className="name_parent">
              <span className="name">{item.name}</span>
            </div>

            <div className="category_parent">
              <label> دسته بندی : </label>
              {!isEmpty(item.category) ? (
                <div className="parent">
                  <span>{item.category.name}</span>
                </div>
              ) : (
                <div className="parent">
                  <span>ندارد</span>
                </div>
              )}
            </div>
            {typeNumber == 0 ? (
              <div className="number_parent">
                <label> موجودی : </label>
                <span className="number">
                  {number > 0 ? number + " عدد " : "ناموجود"}{" "}
                </span>
              </div>
            ) : (
              <div className="product_colors_parent">
                <label>رنگ ، سایز و موجودی: </label>
                <div className="colors">
                  {productColors.map((item, index) => (
                    <>
                     
                        <div
                          className={
                            item.id == selectColor
                              ? "item_color selected"
                              : "item_color"
                          }
                          onClick={() =>
                            handleSelectColor(item.id, item.price_increase)
                          }
                        >
                          {item.color &&
                          <div
                            className="color"
                            style={{ background: item.color.color }}
                          ></div>
                          }

                          {item.size && (
                            <div className="size">
                              <span>سایز </span>
                              <span> {item.size.size} </span>
                            </div>
                          )}

                          <div className="number">
                            <span>تعداد : </span>
                            <span>
                              {item.number > 0
                                ? item.number + " عدد "
                                : "ناموجود"}
                            </span>
                          </div>
                        </div>
                     
                    </>
                  ))}
                </div>
              </div>
            )}
            <div className="price_parent">
              <label>قیمت محصول : </label>
              <span className="price">
                {price &&
                  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                تومان{" "}
              </span>
            </div>

            {priceDiscount > 0 && (
              <div className="price_parent">
                <label>قیمت با تخفیف : </label>
                <span className="price">
                  {priceDiscount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  تومان{" "}
                </span>
              </div>
            )}

            <div className="discount_parent">
              {item.deleted_at == null &&
                percentProductDiscount &&
                percentProductDiscount > 0 && (
                  <div>
                    <span className="price">{percentProductDiscount} ٪ </span>
                    <span>تخفیف اختصاصی</span>
                  </div>
                )}
            </div>

            <div className="general_discount">
              {item.deleted_at == null &&
                percentGeneralDiscount &&
                percentGeneralDiscount > 0 && (
                  <div>
                    <span className="price">{percentGeneralDiscount} ٪ </span>
                    <span>تخفیف عمومی</span>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="btn_parent">
          {item.deleted_at == null ? (
            <button className="btn_delete" onClick={() => handleDelete()}>
              حذف
            </button>
          ) : (
            <button
              className={
                item.deleted_at == null
                  ? "btn_delete"
                  : "btn_delete undo_delete"
              }
              onClick={() => handleDelete()}
            >
              لغو حذف
            </button>
          )}
          {item.deleted_at == null && (
            <>
              <button
                className="btn_update"
                onClick={() => navigate("/product/edit/" + item.id)}
              >
                ویرایش
              </button>

              <button
                className="btn_discount"
                onClick={() => navigate("/product-color/" + item.id)}
              >
               تعداد
              </button>
              
              <button
                className="btn_gallery"
                onClick={() => navigate("/product/gallery/" + item.id)}
              >
                گالری
              </button>
              <button
                className="btn_discount"
                onClick={() => navigate("/product/discount/" + item.id)}
              >
                تخفیف
              </button>
              <button
                className="btn_discount"
                onClick={() => navigate("/product/properties/" + item.id)}
              >
                ویژگی ها
              </button>
            </>
          )}
        </div>

        {showDeleteModal == 1 && (
          <DeleteAlert
            item={item}
            typeDelete={"product"}
            url={"/api/admin/product/delete"}
            request={{ id: item.id }}
            title={"حذف  محصول"}
            buttonTxt={item.deleted_at == null ? "حذف  محصول" : "لغو حذف محصول"}
            description={
              item.deleted_at == null
                ? "آیا شما از حذف این محصول اطمینان دارید؟"
                : "آیا شما از لغو حذف این محصول اطمینان دارید؟"
            }
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleted={deleted}
            setDeleted={setDeleted}
            queryClient={queryClient}
            queryClientDiscountProductsList={queryClientDiscountProductsList}
            setError={setError}
          />
        )}

        {error == 1 && <ErrorMessgae setError={setError} />}
      </div>
    );

    const content = ref ? (
      <article ref={ref}>{postBody}</article>
    ) : (
      <article>{postBody}</article>
    );

    return content;
  }
);

export default ProductItem;
