import React, {
  useState,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import ErrorLoadData from "../Error/ErrorLoadData";
import Header from "../Header/Header";
import { post } from "../http/http";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import OrderItem from "./OrderItem";

export default function OrderUser() {
  const [load, setLoad] = useState(0);
  const [error, setError] = useState(0);
  const [data, setData] = useState();
  const [retry, setRetry] = useState(0);
  const params = useParams();
  useEffect(() => {
    LoadData()
  }, [retry]);

    function LoadData() {
    if (load == 0) {
      setLoad(1);
      var request = { user_id: params.id };
      post("/api/admin/orders/orders-user" , request)
        .then((response) => {
          setLoad(0);
          setError(0);
          setData(response);
        })
        .catch((error) => {
          setError(1);
          setLoad(0);
        });
    } 
    }

  function handleRetry(){
    setRetry(retry+1)
  }

  return (
    <>
      <div className="list_parent">
        <Header title={"لیست سفارشات کاربر"} />
        {load == 1 && <SpinnerLoading />}
        {error == 1 && <ErrorLoadData func={handleRetry} />}
        {data && data.length > 0 &&
          data.map((item, index) => (
            <div key={item.id}>
              <OrderItem item={item} />
            </div>
          ))
        }
        {data && data.length == 0 &&
          <div className="empty">
            <span>سفارشی برای این کاربر وجود ندارد.</span>
          </div>
        }
      </div>
    </>
  );
}
