import React, { useState, useEffect, useRef, useContext } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { get, post } from "../../http/http";
import SpinnerLoading from "../../Spinner/SpinnerLoading";
import { useNavigate, useParams } from "react-router-dom";
import ScrollManager from "../../ScrollManager/ScrollManager";
import Header from "../../Header/Header";
import { Context } from "../../Context/Context";
import ErrorMessgae from "../../Error/ErrorMessage";
import ErrorLoadData from "../../Error/ErrorLoadData";
import { CommentLogic } from "./CommentLogic";
import CkEditor from "../../Inputs/CkEditor";

export default function CommentShow() {
  const params = useParams();
  const [keyScroll, setKeyScroll] = useState("comment" + params.id);
  const [queryClient] = CommentLogic();

  return (
    <div className="form_page">
      <ScrollManager scrollKey={keyScroll} />
      <div className="form_inner">
        <Header title={"نظر کاربر"} />
        <QueryClientProvider client={queryClient}>
          <ReactQuery />
        </QueryClientProvider>
      </div>
    </div>
  );
}

function ReactQuery() {
  const [comment, setComment] = useState();
  const params = useParams();
  const { successAlert, setSuccessAlert } = useContext(Context);
  const navigate = useNavigate();
  function createMarkup(body) {
    return { __html: body };
  }

  const request = { id: params.id };
  const { isLoading, error, isError, data, isFetching } = useQuery(
    "comment_repodata" + params.id,
    () => post("/api/admin/comment/show", request)
  );

  const [
    queryClient,
    handleChangeStatus,
    handleRefresh,
    isAdmin,
    sendComment,
    load,
    setLoad,
    errorConnection,
    setErrorConnection,
    ,
    errorValidation,
    setErrorValidation,
  ] = CommentLogic(data, comment, setComment);

  return (
    <div>
      <div className="form">
        {data && (
          <div className="parent_comment">
            <label>{isAdmin == 0 ? "نظر کاربر" : "نظر مدیر سایت"}</label>
            <div
              className={
                isAdmin == 0 ? "comment_user" : "comment_user admin_comment"
              }
            >
              <label className="date_label">تاریخ ارسال نظر : </label>
              <span className="date">
                {new Date(data.created_at).toLocaleDateString("fa-IR")}
              </span>
              <p
                dangerouslySetInnerHTML={createMarkup(data.body)}
                className="body_comment"
              ></p>
              <button
                className={
                  data && data.status == 0
                    ? "no_verify_comment_btn"
                    : "verify_comment_btn"
                }
                onClick={() => handleChangeStatus(data.id)}
              >
                {data && data.status == 0 ? "در انتظار تأیید" : "تأیید شده"}
              </button>
            </div>
          </div>
        )}

        <div className="reply_comments">
          {data && data.child.length > 0 && <label>پاسخ ها</label>}
          {data &&
            data.child.length > 0 &&
            data.child.map((item, index) => (
              <div
                className={
                  item.user && item.user.is_admin == 1
                    ? "comment_user admin_comment"
                    : "comment_user"
                }
                key={index}
              >
                <div className="name_parent">
                  {item.user && item.user.is_admin == 1 ? (
                    <div className="admin_mark">مدیر سایت</div>
                  ) : (
                    <div className="user_mark">کاربر سایت</div>
                  )}
                </div>
                <label className="date_label">تاریخ ارسال نظر : </label>
                <span className="date">
                  {new Date(item.created_at).toLocaleDateString("fa-IR")}
                </span>
                <p
                  dangerouslySetInnerHTML={createMarkup(item.body)}
                  className="body_comment"
                ></p>
                {data.status == 1 && (
                  <button
                    className={
                      item.status == 0
                        ? "no_verify_comment_btn"
                        : "verify_comment_btn"
                    }
                    onClick={() => handleChangeStatus(item.id)}
                  >
                    {item.status == 0 ? "در انتظار تأیید" : "تأیید شده"}
                  </button>
                )}
                <button
                  className="btn_view_reply_comment"
                  onClick={() => navigate("/comment/" + item.id)}
                >
                  پاسخ
                </button>
              </div>
            ))}
        </div>

        <label>
          {isAdmin == 0 ? "پاسخ به نظر کاربر" : "پاسخ به نظر مدیر سایت"}
        </label>
        <div className="ckeditor">
          <CkEditor onChange={setComment} />
        </div>
      </div>
      <div className="submit_parent">
        <button className={"submit_form"} onClick={() => sendComment()}>
          ارسال نظر
        </button>
      </div>

      {load == 1 && <SpinnerLoading />}
      {errorConnection == 1 && <ErrorMessgae setError={setErrorConnection} />}
      {isLoading && <SpinnerLoading />}
      {isFetching && <SpinnerLoading />}
      {isError && <ErrorLoadData func={handleRefresh} />}
    </div>
  );
}
