import { post } from "../http/http";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import SpinnerLoading from "../Spinner/SpinnerLoading";
import ErrorMessgae from "../Error/ErrorMessage";

export default function Submit({totalChecked}) {
  const [load, setLoad] = useState(0);
  const [error, setError] = useState(0);
  const params = useParams()
  const handleSubmit =()=>{
    if (load == 0) {
      setLoad(1);
      var request = { product_id: params.id, property_product: totalChecked };
      post("/api/admin/property-product/submit", request)
        .then((response) => {
          setError(0);
          setLoad(0);
        })
        .catch((error) => {
          setLoad(0);
          setError(1);
        });
    }
  }

  return (
    <>
      <div className="submit_parent">
        <button className="submit" onClick={() => handleSubmit()}>
          ثبت تغییرات
        </button>
      </div>
      {load == 1 &&
        <SpinnerLoading/>
      }
      {error == 1 &&
        <ErrorMessgae setError={setError}/>
      }
    </>
  );
}
