
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { useState , useEffect , useRef } from "react";
import { Context } from "./Components/Context/Context";
import Menu from "./Components/Menu/Menu";
import CategoryIndex from "./Components/Category/CategoryIndex";
import CategoryCreate from "./Components/Category/CategoryCreate";
import CategoryEdit from "./Components/Category/CategoryEdit";
import Products from "./Components/Products/index/Products";
import ProductCreate from "./Components/Products/create/ProductCreate";
import ProductEdit from "./Components/Products/Edit/ProductEdit";
import Gallery from "./Components/Gallery/Gallery";
import Brands from "./Components/Brand/Brands";
import BrandCreate from "./Components/Brand/BrandCreate";
import BrandEdit from "./Components/Brand/BrandEdit";
import Delivery from "./Components/Delivery/Delivery";
import GeneralDiscount from "./Components/Discount/GeneralDiscount/GeneralDiscount";
import ProductDiscount from "./Components/Discount/ProductDiscount/DiscountCreate/ProductDiscount";
import Comments from "./Components/Comment/List/Comments";
import CommentShow from "./Components/Comment/Show/CommentShow";
import Slides from "./Components/Slides";
import Slide from "./Components/Slides/Slider";
import SuccessAlert from "./Components/Alerts/SuccessAlert";
import SettingWebsite from "./Components/SettingWebsite/SettingWebsite";
import Colors from "./Components/Colors/Colors";
import ColorCreate from "./Components/Colors/ColorCreate";
import ColorEdit from "./Components/Colors/ColorEdit";
import ProductColor from "./Components/ProductColor/Edit/ProductColor";
import Users from "./Components/Users/Users";
import Orders from "./Components/Orders/Orders";
import OrderUser from "./Components/Orders/OrderUser";
import LoginRegister from "./Components/LoginRegister/LoginRegister";
import AdminInfo from "./Components/AdminInfo/AdminInfo";
import Profile from "./Components/Profile/Profile";
import Property from "./Components/Property/Property";
import PropertyCreate from "./Components/Property/PropertyCreate";
import PropertiesProduct from "./Components/PropertyProduct/PropertiesProduct";
import CategoryBrands from "./Components/Category/CategoryBrands";
import SizeTypes from "./Components/Size/SizeTypes/SizeTypes";
import Notify from "./Components/Notification/Notify";
import Biography from "./Components/Biography/Biography";

function App() {

  const [successAlert , setSuccessAlert] =useState(0)
  const [isAdmin , setIsAdmin] = useState()
  const [admin, setAdmin] = useState();
  const lastIdProducts = useRef(0)
  const [searchProducts, setSearchProducts] = useState(null);
  const lastIdCategory = useRef(0);
  const [searchCategory, setSearchCategory] = useState(null);
  const lastIdBrand = useRef(0);
  const [searchUser, setSearchUser] = useState(null);
  const lastIdUser = useRef(0);
  const [searchOrder, setSearchOrder] = useState(null);
  const lastIdOrder = useRef(0);
  const [searchComment, setSearchComment] = useState(null);
  const lastIdComment = useRef(0);
  const [searchColor, setSearchColor] = useState(null);
  const lastIdColor = useRef(0);
  const [menu , setMenu] = useState(0)

  return (
    <div className="App">
      <Context.Provider
        value={{
          successAlert,
          setSuccessAlert,
          isAdmin,
          setIsAdmin,
          admin,
          setAdmin,
          lastIdProducts,
          lastIdCategory,
          searchProducts,
          setSearchProducts,
          searchCategory,
          setSearchCategory,
          lastIdBrand,
          lastIdUser,
          searchUser,
          setSearchUser,
          lastIdOrder,
          searchOrder,
          setSearchOrder,
          searchComment,
          setSearchComment,
          lastIdComment,
          lastIdColor,
          searchColor,
          setSearchColor,
          menu,
          setMenu,
        }}
      >
        <AdminInfo />
        {isAdmin == false && <LoginRegister />}
        {isAdmin == true && (
          <>
            <Menu />
            <div className="main_app">
              <SuccessAlert />

              <Routes>
                <Route path="/category" element={<CategoryIndex />} />
                <Route path="/category/create" element={<CategoryCreate />} />
                <Route path="/category/edit/:id" element={<CategoryEdit />} />
                <Route
                  path="/category/brands/:id"
                  element={<CategoryBrands />}
                />
                <Route path="/products" element={<Products />} />
                <Route path="/product/create" element={<ProductCreate />} />
                <Route path="/product/edit/:id" element={<ProductEdit />} />
                <Route path="/product/gallery/:id" element={<Gallery />} />

                <Route path="/brands" element={<Brands />} />
                <Route path="/brand/create" element={<BrandCreate />} />
                <Route path="/brand/edit/:id" element={<BrandEdit />} />

                <Route path="/delivery" element={<Delivery />} />

                <Route path="/general-discount" element={<GeneralDiscount />} />
                <Route
                  path="/product/discount/:id"
                  element={<ProductDiscount />}
                />

                <Route path="/comments" element={<Comments />} />
                <Route path="/comment/:id" element={<CommentShow />} />

                <Route
                  path="/product/properties/:id"
                  element={<PropertiesProduct />}
                />

                <Route path="/slides" element={<Slides />} />
                <Route path="/slides/:id" element={<Slide />} />

                <Route path="/setting-website" element={<SettingWebsite />} />

                <Route path="/colors" element={<Colors />} />
                <Route path="/colors/create" element={<ColorCreate />} />
                <Route path="/colors/edit/:id" element={<ColorEdit />} />

                <Route path="/size" element={<SizeTypes />} />

                <Route path="/product-color/:id" element={<ProductColor />} />

                <Route path="/users" element={<Users />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders-user/:id" element={<OrderUser />} />

                <Route path="/admin-profile" element={<Profile />} />

                <Route path="/property/:id" element={<Property />} />
                <Route
                  path="/property/create/:id"
                  element={<PropertyCreate />}
                />

                <Route path="/biography" element={<Biography />} />

                <Route path="/notify" element={<Notify />} />
              </Routes>
            </div>
          </>
         )}
      </Context.Provider>
    </div>
  );
}

export default App;
