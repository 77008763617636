import { post } from "../http/http";

export default function Notify(){

          navigator.serviceWorker.register('./sw.js');
          async function subscribe() {
            
            let sw = await navigator.serviceWorker.ready;
            console.log("kkjkj");
            let push = await sw.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey:
                "BOe-nXH6N-PPLs-Zy-QwO9oexlhOkMmDGosJmL9FbhpxLJF9DB1WlDH8uhtSOv_rPaBBtIf8p83OhrqZof6OZco",
            });



            var request = { push: JSON.stringify(push) };
            post("/api/admin/savepush" , request)
            .then(response=>{

            }).catch(error=>{

            })

            // let xhr = new XMLHttpRequest();

            // xhr.open("POST", "/api/admin/savepush", true);
            // xhr.setRequestHeader(
            //   "Content-type",
            //   "application/json; charset=UTF-8"
            // );
            // xhr.send(
            //   JSON.stringify({
            //     push: JSON.stringify(push),
            //   })
            // );
          }

        function enablenotif() {
          Notification.requestPermission().then(function (permission) {
            if (Notification.permission === "granted") {
              subscribe();
            }
          });
        }

    return (
      <>
        <button onClick={() => enablenotif()}>Notify me!</button>
      </>
    );
}