import React from "react";
import ReactDOM from "react-dom";
export default function SpinnerLoading() {
  return (
    <div className="load_container">
      <div className="load_data">
        <div className="inner_load_data">
          <div className="loadingio-spinner-ellipsis-3k0o1osq94t">
            <div className="ldio-fg094ugvyz">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
