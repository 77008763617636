import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";

const UserItem = React.forwardRef(
  ({ item, index, mobile, queryClient }, ref) => {
    const navigate = useNavigate()
    const postBody = (
      <div className="user_item">
        <div className="info">
          <div className="mobile_parent">
            <span className="title">شماره همراه: </span>
            <span className="mobile">{item.mobile}</span>
          </div>
        </div>
        <div className="btns">
          <button className="orders_btn" onClick={()=>navigate("/orders-user/"+item.id)}>سفارشات</button>
        </div>
      </div>
    );

    const content = ref ? (
      <article ref={ref}>{postBody}</article>
    ) : (
      <article>{postBody}</article>
    );

    return content;
  }
);

export default UserItem;
